import {
  Box,
  Button,
  DialogContentText,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomConfirmationDialog,
  CustomPagination,
  CustomTextFieldN,
} from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../redux/actions";
import editRequestAPI from "../../../redux/actions/editRequestApi";
import moment from "moment";
import helper from "../../../utils/helper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import customConsole from "../../../config/customConsole";

function EditAccessRequestN({ selectedProjectData, selectedVariantData }) {
  const dispatch = useDispatch();
  const { access_request_status } = useSelector(
    (state) => state?.settingsReducer?.metaData,
    shallowEqual
  );

  //useState
  const [eRDetails, setERDetails] = useState([]);
  const [isRequestSend, setIsRequestSend] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(100);
  const [openApproveAccessDialog, setOpenApproveAccessDialog] = useState(false);
  const [selectedApproveAccessData, setSelectedApproveAccessData] =
    useState(null);
  const [openRejectAccessDialog, setOpenRejectAccessDialog] = useState(false);
  const [selectedRejectAccessData, setSelectedRejectAccessData] =
    useState(null);
  const [reasonToRejectAccess, setReasonToRejectAccess] = useState("");
  const [reasonToApproveAccess, setReasonToApproveAccess] = useState("");

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  console.log("eRDetails", eRDetails);

  //useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          !selectedProjectData?.project_id ||
          !selectedVariantData?.variant_id
        ) {
          return;
        }

        const resp = await dispatch(
          HandleApiActions({
            ...editRequestAPI.get_edit_requests,
            params: {
              is_sent_requests: false,
              project_id: selectedProjectData?.project_id,
              variant_id: selectedVariantData?.variant_id,
              page_number: page,
              page_size: rowsPerPage,
              status: selectedFilter,
            },
          })
        );

        if (resp?.data) {
          console.log("API response data:", resp.data);
          setERDetails(resp.data);
        } else {
          console.log("No data returned from API");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    selectedProjectData?.project_id,
    selectedVariantData?.variant_id,
    selectedFilter,
  ]);
  useEffect(() => {
    setIsRequestSend(!selectedProjectData?.is_user_project_owner);
  }, [selectedProjectData]);

  const handleOnClickFilter = (status) => {
    setSelectedFilter(status);
  };

  const handleApproveAccessClick = (params) => {
    setSelectedApproveAccessData(params);

    setOpenApproveAccessDialog(true);
  };
  const approveRequestAccess = async ({ id, reason }) => {
    let apiDetails = editRequestAPI.approve_edit_requests;

    customConsole({
      ...apiDetails,
      params: {
        er_id: Number(id),
        reason: reason,
      },
      show_toast: true,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          er_id: Number(id),
          reason: reason,
        },
        show_toast: true,
      })
    );

    if (resp.error) {
    } else {
    }
  };

  const rejectRequestAccess = async ({
    id,
    reason,
    selectedProjectId,
    page,
    rowsPerPage,
  }) => {
    let apiDetails = editRequestAPI.reject_edit_requests;

    customConsole({
      ...apiDetails,
      params: {
        er_id: Number(id),
        reason: reason,
      },
      show_toast: true,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          er_id: Number(id),
          reason: reason,
        },
        show_toast: true,
      })
    );

    if (resp.error) {
    } else {
    }
  };

  const handleRejectAccessClick = (params) => {
    setSelectedRejectAccessData(params);

    setOpenRejectAccessDialog(true);

    setReasonToRejectAccess("");
  };
  const handleCancelApproveAccess = () => {
    //setSelectedApproveAccessData(null);

    setOpenApproveAccessDialog(false);
  };

  const handleCancelRejectAccess = () => {
    setSelectedRejectAccessData(null);

    setOpenRejectAccessDialog(false);

    setReasonToRejectAccess("");
  };

  const handleConfirmApproveAccess = () => {
    approveRequestAccess(selectedApproveAccessData);

    dispatch(
      HandleApiActions({
        projectId: selectedProjectData?.project_id,
        variantId: selectedVariantData?.variant_id,
        pageNumber: page,
        pageSize: rowsPerPage,
      })
    );

    //setSelectedApproveAccessData(null);

    setOpenApproveAccessDialog(false);
  };

  const handleConfirmRejectAccess = () => {
    rejectRequestAccess({
      ...selectedRejectAccessData,
      reason: reasonToRejectAccess,
    });

    dispatch(
      HandleApiActions({
        projectId: selectedProjectData?.project_id,
        variantId: selectedVariantData?.variant_id,
        pageNumber: page,
        pageSize: rowsPerPage,
      })
    );

    setSelectedRejectAccessData(null);

    setOpenRejectAccessDialog(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  console.log("checkSelectFilter", selectedFilter === 100);

  return (
    <div>
      {eRDetails?.length > 0 && !isRequestSend ? (
        <Box sx={styles.filterBtnContainer}>
          <FilterButton
            label="Pending"
            filterValue={100}
            selectedFilter={selectedFilter}
            onClick={handleOnClickFilter}
            borderColor="#cfbf4c"
            backgroundColor="#cfbf4c"
            textColor="#cfbf4c"
          />
          <FilterButton
            label="Approved"
            filterValue={200}
            selectedFilter={selectedFilter}
            onClick={handleOnClickFilter}
            borderColor="#37b518"
            backgroundColor="#37b518"
            textColor="#37b518"
          />
          <FilterButton
            label="Rejected"
            filterValue={300}
            selectedFilter={selectedFilter}
            onClick={handleOnClickFilter}
            borderColor="#d93442"
            backgroundColor="#d93442"
            textColor="#d93442"
          />
        </Box>
      ) : null}

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 350,
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                "S.No",
                "Requested By",
                "Module Type",
                "EE Details",
                "Software Details",
                "Reason",
                "Rejected Reason",
                "Requested At",
                "Approved By",
                "Status",
                !isRequestSend ? "Action" : null,
              ]?.map((header, idx) => {
                return (
                  <TableCell
                    key={idx}
                    style={{ padding: "10px 20px", fontWeight: "bold" }}
                  >
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {eRDetails?.length > 0 ? (
              eRDetails.map((data, idx) => (
                <TableRow key={idx}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="subtitle2">
                        <span style={styles.txtBold}>Name: </span>
                        {data?.requested_user?.fullname || "-"}
                      </Typography>
                      <Typography variant="subtitle2">
                        <span style={styles.txtBold}>Email: </span>
                        {data?.requested_user?.email || "-"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="subtitle2">
                        {data?.swm_details === null ? "ECU" : "SWM"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="subtitle2">
                        <span style={styles.txtBold}>Assembly Number: </span>
                        {data?.ee_details?.assembly_number || "-"}
                      </Typography>
                      <Typography variant="subtitle2">
                        <span style={styles.txtBold}>Description: </span>
                        {data?.ee_details?.description || "-"}
                      </Typography>
                      <Typography variant="subtitle2">
                        <span style={styles.txtBold}>Version: </span>
                        {data?.ee_details?.version || "-"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="subtitle2">
                        <span style={styles.txtBold}>Name: </span>
                        {data?.swm_details?.name || "-"}
                      </Typography>
                      <Typography variant="subtitle2">
                        <span style={styles.txtBold}>Description: </span>
                        {data?.swm_details?.description || "-"}
                      </Typography>
                      <Typography variant="subtitle2">
                        <span style={styles.txtBold}>Version: </span>
                        {data?.swm_details?.version || "-"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{data?.request_reason}</TableCell>
                  <TableCell>{data?.update_reason}</TableCell>
                  <TableCell>
                    {moment(data?.created_at).format("DD-MM-YYYY hh:mm:ss A")}
                  </TableCell>
                  <TableCell>
                    {isRequestSend && selectedFilter && (
                      <Box>
                        <Typography variant="subtitle2">
                          <span style={styles.txtBold}>Name: </span>
                          {data?.approved_user?.fullname || "-"}
                        </Typography>
                        <Typography variant="subtitle2">
                          <span style={styles.txtBold}>Email: </span>
                          {data?.approved_user?.email || "-"}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {helper?.getValueByKey(
                      access_request_status,
                      data?.status
                    ) || "-"}
                  </TableCell>

                  <TableCell>
                    {!isRequestSend ? (
                      <Box
                      // sx={{
                      //   display: "flex",
                      //   flexDirection: "column",
                      //   alignItems: "flex-start",
                      // }}
                      >
                        {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
                        <IconButton
                          size="small"
                          color="success"
                          onClick={() =>
                            handleApproveAccessClick({
                              id: data?.er_id,
                              reason: reasonToApproveAccess,
                            })
                          }
                        >
                          <CheckCircleIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() =>
                            handleRejectAccessClick({
                              id: data?.er_id,
                            })
                          }
                        >
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} style={{ textAlign: "center" }}>
                  No requests available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Paper elevation={4} sx={styles.paginationPaper}>
        {/* Pagination with select page size */}
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          hasMore={hasMore}
        />
      </Paper>
      <CustomConfirmationDialog
        open={openApproveAccessDialog}
        onClose={handleCancelApproveAccess}
        dialogTitle={"Approve Access"}
        dialogContent={
          <Stack>
            <DialogContentText>
              Are you sure you want to approve access?
            </DialogContentText>
            <Box>
              <CustomTextFieldN
                size={"small"}
                label={"Reason*"}
                //placeholder={"Reason to approve access*"}
                type={"text"}
                //fldType={"text"}
                value={reasonToApproveAccess}
                setValue={setReasonToApproveAccess}
                //valueError={assemblyNoError}
                setValueError={() => null}
                variant={"standard"}
                txtFldStyle={styles.txtStyle}
              />
            </Box>
          </Stack>
        }
        onConfirm={handleConfirmApproveAccess}
      />
      <CustomConfirmationDialog
        open={openRejectAccessDialog}
        onClose={handleCancelRejectAccess}
        dialogTitle={"Reject Access"}
        //disableConfirmBtn={reasonToRejectAccess?.length < 5}
        dialogContent={
          <Stack spacing={1.2}>
            <DialogContentText>
              Are you sure you want to reject access?
            </DialogContentText>
            <Box>
              <CustomTextFieldN
                size={"small"}
                //label={"Reason*"}
                placeholder={"Reason to reject access*"}
                type={"text"}
                // fldType={"text"}
                value={reasonToRejectAccess}
                setValue={setReasonToRejectAccess}
                //valueError={assemblyNoError}
                setValueError={() => null}
                variant={"standard"}
                txtFldStyle={styles.txtStyle}
              />
            </Box>
          </Stack>
        }
        onConfirm={handleConfirmRejectAccess}
      />
    </div>
  );
}

export default EditAccessRequestN;

const styles = {
  txtBold: {
    fontWeight: "bold",
  },
  filterBtnContainer: {
    mt: 1.4,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 1,
  },
  prTableContainer: {
    mt: 1.4,
    height: "auto",
    width: "100%",
    mb: 8,
    border: "1px solid grey",
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
    border: "1px solid grey",
  },
  txtStyle: {
    width: "100%",
  },
};

const FilterButton = ({
  label,
  filterValue,
  selectedFilter,
  onClick,
  borderColor,
  backgroundColor,
  textColor,
}) => {
  return (
    <Button
      onClick={() => onClick(filterValue)}
      size="medium"
      variant="text"
      sx={{
        ml: label !== "Pending" ? "10px" : 0, // Add margin only for non-first buttons
        border: `1px solid ${borderColor}`,
        color: selectedFilter === filterValue ? "#fff" : textColor,
        backgroundColor:
          selectedFilter === filterValue ? backgroundColor : "transparent",
        "&:hover": {
          backgroundColor: backgroundColor,
          color: "#fff",
        },
      }}
    >
      {label}
    </Button>
  );
};
