import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { CustomButton, SearchSelectTextField } from "../../../components";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import VariantDetailsTab from "./VariantDetailsTab";
import VariantDetails from "./VariantDetails";
import ProjectDetails from "./ProjectDetails";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, projectConfigAPI } from "../../../redux/actions";
import CreateVariant from "./CreateVariant";
import ReFirstScreen from "../ReFirstScreen";
import ReCreateProject from "../ReCreateProject";

function ViewDialogCopy1({ projectId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //useSelector
  const projectDetails = useSelector((state) => state.projectConfigReducer);
  const variantDetails = useSelector((state) => state.variantReducer);

  //1.React useState
  const [openCreateVariant, setOpenCreateVariant] = useState(false);
  const [selectVariant, setSelectVariant] = useState(null);
  const [openReCreateVariant, setOpenReCreateVariant] = useState(false);

  //Data
  const [variantOptionsList, setVariantOptionsList] = useState([]);
  const [selectedVariantData, setSelectedVariantData] = useState([]);
  const [selectedProjectData, setSelectedProjectData] = useState([]);

  //2.useEffect

  console.log("projectDetails", projectDetails);
  console.log("selectedProjectData", selectedProjectData);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.get_project,
        params: {
          project_id: Number(projectId),
        },
        shallowEqual,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.get_variant_details,
        params: {
          project_id: Number(projectId),
          is_complete_details_required: true,
        },
        shallowEqual,
      })
    );
  }, []);

  useEffect(() => {
    if (projectDetails?.projectConfig?.length > 0) {
      const selectedProject = projectDetails?.projectConfig?.find(
        (data) => Number(data?.project_id) === Number(projectId)
      );

      setSelectedProjectData(selectedProject);
    }
  }, [projectDetails]);

  useEffect(() => {
    if (variantDetails?.variantInfo?.length > 0) {
      const allVariants = variantDetails?.variantInfo?.filter((data) =>
        data?.project_id.includes(projectId)
      );

      const newVariantDetails = allVariants
        ?.map((data) => {
          return {
            value: data?.variant_id,
            label: data?.name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      console.log("newVariantDetails", newVariantDetails);
      setVariantOptionsList(
        newVariantDetails?.length > 0 ? newVariantDetails : []
      );
      setSelectVariant(newVariantDetails?.[0]);
    }
  }, [variantDetails]);

  useEffect(() => {
    if (variantDetails?.variantInfo?.length > 0 && selectVariant) {
      const selectedVariantDetails = variantDetails?.variantInfo?.find(
        (data) => data?.variant_id === selectVariant?.value
      );
      console.log("selectedVariantDetails", selectedVariantDetails);
      setSelectedVariantData(selectedVariantDetails);
    }
  }, [variantDetails, selectVariant]);

  return (
    <div>
      <Box sx={{ padding: "12px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <CustomButton
            startIcon={<ArrowBackIcon />}
            size={"medium"}
            variant={"contained"}
            btnName={"Back"}
            handleOnClick={() => navigate(`/dashboard/project-config`)}
          />
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: "10px",
            mb: "10px",
          }}
        >
          <CustomButton
            disabled={
              selectedProjectData?.is_user_project_owner === true ? false : true
            }
            startIcon={<AddIcon />}
            size={"medium"}
            variant={"contained"}
            btnName={"Recreate Project"}
            handleOnClick={() => setOpenReCreateVariant(true)}
          />
        </Box> */}

        <Box
          sx={{
            minHeight: "200px",
            maxHeight: "50%",
            overflowY: "auto",

            padding: "10px",
          }}
        >
          <Grid>
            <Grid xs={12}>
              <ProjectDetails selectedProjectData={selectedProjectData} />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: "10px",
                mb: "10px",
              }}
            >
              <CustomButton
                startIcon={<AddIcon />}
                size={"medium"}
                variant={"contained"}
                btnName={"Add Variant"}
                handleOnClick={() => setOpenCreateVariant(true)}
              />
            </Box>
            <Grid xs={12} sx={{ mt: "10px" }}>
              <SubItem>
                {variantOptionsList?.length > 0 ? (
                  <>
                    <SearchSelectTextField
                      size={"small"}
                      label={"Search Variant"}
                      placeholder={"Search Variant"}
                      noOptionsText={"No variant found"}
                      optionsList={variantOptionsList || []}
                      selectedValue={selectVariant}
                      setSelectedValue={setSelectVariant}
                      txtFldStyle={{
                        width: "215px",
                        backgroundColor: "white",
                      }}
                    />
                    <Divider
                      sx={{
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                      flexItem
                    />
                    <Grid>
                      <Grid xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: "13px",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", color: "black" }}
                          >
                            Variant Configuration
                          </Typography>
                        </Box>
                        <VariantDetails
                          selectedVariantData={selectedVariantData}
                        />
                      </Grid>

                      <Divider
                        sx={{
                          marginTop: "10px",
                          marginRight: "10px",
                        }}
                        flexItem
                      />

                      <Grid xs={5}>
                        <VariantDetailsTab
                          selectedProjectData={selectedProjectData}
                          selectedVariantData={selectedVariantData}
                          projectId={projectId}
                          selectVariant={selectVariant}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Box>
                    <Typography>No Variant Found</Typography>
                  </Box>
                )}
              </SubItem>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {openCreateVariant && (
        <CreateVariant
          selectedProjectData={selectedProjectData}
          openCreateVariant={openCreateVariant}
          setOpenCreateVariant={setOpenCreateVariant}
        />
      )}
      {openReCreateVariant && (
        <ReCreateProject
          open={openReCreateVariant}
          close={setOpenReCreateVariant}
          recreateProject={selectedProjectData}
        />
      )}
    </div>
  );
}

export default ViewDialogCopy1;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#e9f5ec",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: theme.shadows[3], // Add shadow with default level 3, you can adjust as needed
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
    boxShadow: theme.shadows[6], // Different shadow for dark mode (stronger shadow)
  }),
}));

const SubItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
  boxShadow: theme.shadows[5], // Add shadow with default level 3, you can adjust as needed
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
    boxShadow: theme.shadows[6], // Different shadow for dark mode (stronger shadow)
  }),
}));
