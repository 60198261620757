import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import SimulationFiles from "./SimulationFiles";
import Reports from "./Reports";

import { SearchSelectTextFieldN } from "../../components";

function DocumentLibrary() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box sx={styles.topContainer}>
        <Box sx={styles.searchContainer}>
          <SearchSelectTextFieldN
            size={"small"}
            label={"Search Project"}
            placeholder={"Search Project"}
            noOptionsText={"No projects found"}
            optionsList={[]}
            selectedValue={""}
            handleValueChange={(event, value) =>
              //   // Store the selected project in the redux store
              {}
            }
            txtFldStyle={styles.searchSelectTextField}
          />
        </Box>
        <Box sx={styles.searchContainer}>
          <SearchSelectTextFieldN
            size={"small"}
            label={"Search Variant"}
            placeholder={"Search Variant"}
            noOptionsText={"No variants found"}
            optionsList={[]}
            selectedValue={""}
            handleValueChange={(event, value) => {}}
            txtFldStyle={styles.searchSelectTextField}
          />
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Reports" {...a11yProps(0)} />
            <Tab label="Simulation Files" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Reports />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SimulationFiles />
        </CustomTabPanel>
      </Box>
    </div>
  );
}

export default DocumentLibrary;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

//Styles for the Page
const styles = {
  box1: { width: "100%", position: "relative" },
  box2: {
    position: "sticky",
    top: 0,
    borderBottom: 1,
    borderColor: "divider",
    backgroundColor: "white",
    zIndex: 1000,
    borderTop: "1px solid lightgrey",
  },
  searchContainer: {
    textAlign: "start",
    mt: { xs: 2, md: 0 },
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
  topContainer: {
    mt: 1,
    mx: 2,
    flexDirection: { xs: "column", md: "row" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: { xs: 2, md: 4 },
  },
  prjDetailsContainer: {
    px: 2,
    // py: 1,
    mx: 1,
    height: "100%",
  },
  prjDetailsTxt: {
    textAlign: "start",
    alignContent: "center",
  },
  txtBold: {
    fontWeight: "bold",
  },
};
