/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description : Component to show the mind map for zonal.
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
  MarkerType,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import * as d3 from "d3-hierarchy";
import { Box } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, labCar } from "../../redux/actions";
import customConsole from "../../config/customConsole";

// Define constants for node dimensions and margins
const nodeWidth = 140;
const nodeHeight = 140;
const marginX = 25;
const marginY = 75;

// Utility function to get node color based on selection and linking
const getNodeColor = (nodeId, selectedNodeId, linkedSwmIds) => {
  customConsole("nodeId, selectedNodeId, linkedSwmIds");
  customConsole({ nodeId, selectedNodeId, linkedSwmIds });
  if (nodeId === selectedNodeId) return "#00FF1A";
  if (
    selectedNodeId &&
    linkedSwmIds?.includes(Number(selectedNodeId?.replace("swm-", "")))
  )
    return "gold";
  return "#2897DC";
};

// Create hierarchy data
const createHierarchyData = (projectDetails) => {
  return {
    id: `project-${projectDetails.project_id}`,
    name: projectDetails.name,
    children: projectDetails.zones.map((zone) => ({
      id: `zone-${zone.sequence}`,
      name: zone.zone_name,
      children: projectDetails.ee_details
        .filter((ee) => ee.zone_details.sequence === zone.sequence)
        .map((ee) => ({
          id: `ee-${ee.ee_id}`,
          name: ee.description,
          version: ee.version,
          children: projectDetails.swm_details
            .filter((swm) => swm.ee_id === ee.ee_id)
            .map((swm) => ({
              id: `swm-${swm.swm_id}`,
              name: swm.name,
              version: swm.version,
              linkedSwmIds: swm.linked_swm_ids,
            })),
        })),
    })),
  };
};

// Generate nodes and edges using D3 tree layout

// Currently working
// const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
//   const nodes = [];
//   const edges = [];

//   const root = d3.hierarchy(hierarchyData);
//   const treeLayout = d3
//     .tree()
//     .nodeSize([nodeWidth + marginX, nodeHeight + marginY]);
//   treeLayout(root);

//   // Define fixed positions for the zones around the project node
//   const zonePositions = [
//     { x: -270, y: 0 }, // Left
//     { x: 270, y: 0 }, // Right
//     { x: 0, y: -200 }, // Top
//     { x: 0, y: 200 }, // Bottom
//     { x: -270, y: -200 }, // Top-left
//     { x: 270, y: -200 }, // Top-right
//     { x: -270, y: 200 }, // Bottom-left
//     { x: 270, y: 200 }, // Bottom-right
//   ];

//   let zoneIndex = 0;

//   root.descendants().forEach((d) => {
//     let nodeShape, nodeStyle;

//     if (d.data.id.startsWith("project-")) {
//       // Project Node - Circle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "50%", // Circle shape
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };

//       // Add the project node to the list
//       nodes.push({
//         id: d.data.id,
//         data: { label: nodeShape },
//         position: { x: 0, y: 0 }, // Project node is at the center
//         style: nodeStyle,
//       });
//     }

//     if (d.data.id.startsWith("zone-")) {
//       // Zone Node - Rectangle (Surrounding the project)
//       nodeShape = (
//         <div
//           style={{
//             textAlign: "center",
//             position: "relative",
//             width: "140px",
//             height: "140px",
//           }}
//         >
//           <svg width="140" height="140">
//             <polygon
//               points="70,5 140,135 0,135"
//               fill="#459C5B"
//               stroke="#000"
//               strokeWidth="1"
//             />
//           </svg>
//           <div style={{ position: "absolute", top: "50%", width: "100%" }}>
//             <h3>{d.data.name}</h3>
//           </div>
//         </div>
//       );
//       nodeStyle = {
//         border: "none",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         padding: 0,
//         boxShadow: "none",
//         background: "transparent",
//       };

//       // Place the zone around the project node based on the fixed positions
//       const zonePosition = zonePositions[zoneIndex];

//       // Add the zone node to the list
//       nodes.push({
//         id: d.data.id,
//         data: { label: nodeShape },
//         position: { x: zonePosition.x, y: zonePosition.y },
//         style: nodeStyle,
//       });

//       // Create edge from the project to the zone
//       edges.push({
//         id: `${d.parent.data.id}-${d.data.id}`,
//         source: d.parent.data.id,
//         target: d.data.id,
//         type: "step",
//         animated: false,
//         markerEnd: {
//           type: MarkerType.ArrowClosed,
//           color: "#000000",
//         },
//       });

//       // Increment the zone index for the next zone
//       zoneIndex += 1;
//     }
//   });

//   return { nodes, edges };
// };

// Top to Bottom
const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
  const nodes = [];
  const edges = [];

  const root = d3.hierarchy(hierarchyData);
  const treeLayout = d3
    .tree()
    .nodeSize([nodeWidth + marginX, nodeHeight + marginY]);
  treeLayout(root);

  root.descendants().forEach((d) => {
    let nodeShape, nodeStyle;

    // Define shapes and styles for each node type
    if (d.data.id.startsWith("project-")) {
      // Project Node - Circle
      nodeShape = (
        <div>
          <h3>{d.data.name}</h3>
        </div>
      );
      nodeStyle = {
        background: "black",
        color: "white",
        width: nodeWidth,
        height: nodeHeight,
        borderRadius: "50%", // Circle shape
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    } else if (d.data.id.startsWith("zone-")) {
      // Zone Node - Triangle
      nodeShape = (
        <div
          style={{
            textAlign: "center",
            position: "relative",
            width: "140px",
            height: "140px",
          }}
        >
          <svg width="140" height="140">
            <polygon
              points="70,5 140,135 0,135"
              fill="#459C5B"
              stroke="#000"
              strokeWidth="1"
            />
          </svg>
          <div style={{ position: "absolute", top: "50%", width: "100%" }}>
            <h3>{d.data.name}</h3>
          </div>
        </div>
      );
      nodeStyle = {
        border: "none",
        color: "white",
        width: nodeWidth,
        height: nodeHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        boxShadow: "none",
        background: "transparent",
      };
    } else if (d.data.id.startsWith("ee-")) {
      // EE Component Node - Circle
      nodeShape = (
        <div>
          <h3>{d.data.name}</h3>
          <p>
            <strong>Version:</strong> {d.data.version}
          </p>
        </div>
      );
      nodeStyle = {
        background: "black",
        color: "white",
        width: nodeWidth,
        height: nodeHeight,
        borderRadius: "50%", // Circle shape
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    } else if (d.data.id.startsWith("swm-")) {
      // Software Module Node - Rectangle
      nodeShape = (
        <div>
          <h3>{d.data.name}</h3>
          <p>
            <strong>Version:</strong> {d.data.version}
          </p>
        </div>
      );
      nodeStyle = {
        background: getNodeColor(
          d.data.id,
          selectedNodeId,
          d.data.linkedSwmIds
        ),
        color: "white",
        width: nodeWidth,
        height: nodeHeight,
        borderRadius: "0%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    }

    // Add the node to the list
    nodes.push({
      id: d.data.id,
      data: { label: nodeShape },
      position: { x: d.x, y: d.y },
      style: nodeStyle,
    });

    // Create edges
    if (d.parent) {
      edges.push({
        id: `${d.parent.data.id}-${d.data.id}`,
        source: d.parent.data.id,
        target: d.data.id,
        type: "step",
        animated: false,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: "#000000",
        },
      });
    }
  });

  return { nodes, edges };
};

// Bottom to Top
// const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
//   const nodes = [];
//   const edges = [];

//   const root = d3.hierarchy(hierarchyData);
//   const treeLayout = d3
//     .tree()
//     .nodeSize([nodeWidth + marginX, nodeHeight + marginY]);
//   treeLayout(root);

//   // Determine maximum y-coordinate value for inversion
//   const maxY = Math.max(...root.descendants().map((d) => d.y));

//   root.descendants().forEach((d) => {
//     let nodeShape, nodeStyle;

//     // Define shapes and styles for each node type
//     if (d.data.id.startsWith("project-")) {
//       // Project Node - Circle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "50%", // Circle shape
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("zone-")) {
//       // Zone Node - Triangle
//       nodeShape = (
//         <div
//           style={{
//             textAlign: "center",
//             position: "relative",
//             width: "140px",
//             height: "140px",
//           }}
//         >
//           <svg width="140" height="140">
//             <polygon
//               points="70,5 140,135 0,135"
//               fill="#459C5B"
//               stroke="#000"
//               strokeWidth="1"
//             />
//           </svg>
//           <div style={{ position: "absolute", top: "50%", width: "100%" }}>
//             <h3>{d.data.name}</h3>
//           </div>
//         </div>
//       );
//       nodeStyle = {
//         border: "none",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         padding: 0,
//         boxShadow: "none",
//         background: "transparent",
//       };
//     } else if (d.data.id.startsWith("ee-")) {
//       // EE Component Node - Circle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "50%", // Circle shape
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("swm-")) {
//       // Software Module Node - Rectangle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: getNodeColor(
//           d.data.id,
//           selectedNodeId,
//           d.data.linkedSwmIds
//         ),
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "0%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     }

//     // Invert the y position to make it bottom-to-top
//     const invertedY = maxY - d.y;

//     // Add the node to the list
//     nodes.push({
//       id: d.data.id,
//       data: { label: nodeShape },
//       position: { x: d.x, y: invertedY },
//       style: nodeStyle,
//     });

//     // Create reversed edges (child -> parent)
//     if (d.parent) {
//       edges.push({
//         id: `${d.data.id}-${d.parent.data.id}`,
//         source: d.data.id, // Child node as source
//         target: d.parent.data.id, // Parent node as target
//         type: "step",
//         animated: false,
//         markerStart: {
//           type: MarkerType.ArrowClosed,
//           color: "#000000",
//         },
//       });
//     }
//   });

//   return { nodes, edges };
// };

// Bottom to Top Half Zones
// const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
//   const nodes = [];
//   const edges = [];

//   // Step 1: Filter out half of the zones in hierarchyData
//   const filteredHierarchyData = { ...hierarchyData };

//   // Get all zones from the hierarchyData
//   const allZones = filteredHierarchyData.children.filter((child) =>
//     child.id.startsWith("zone-")
//   );

//   // Select the first half of the zones
//   const halfZones = allZones.slice(0, Math.ceil(allZones.length / 2));

//   // Assign the filtered half zones back to the hierarchy data
//   filteredHierarchyData.children = [
//     ...filteredHierarchyData.children.filter(
//       (child) => !child.id.startsWith("zone-")
//     ),
//     ...halfZones,
//   ];

//   const root = d3.hierarchy(filteredHierarchyData);
//   const treeLayout = d3
//     .tree()
//     .nodeSize([nodeWidth + marginX, nodeHeight + marginY]);
//   treeLayout(root);

//   // Determine maximum y-coordinate value for inversion
//   const maxY = Math.max(...root.descendants().map((d) => d.y));

//   root.descendants().forEach((d) => {
//     let nodeShape, nodeStyle;

//     // Define shapes and styles for each node type
//     if (d.data.id.startsWith("project-")) {
//       // Project Node - Circle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "50%", // Circle shape
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("zone-")) {
//       // Zone Node - Triangle
//       nodeShape = (
//         <div
//           style={{
//             textAlign: "center",
//             position: "relative",
//             width: "140px",
//             height: "140px",
//           }}
//         >
//           <svg width="140" height="140">
//             <polygon
//               points="70,5 140,135 0,135"
//               fill="#459C5B"
//               stroke="#000"
//               strokeWidth="1"
//             />
//           </svg>
//           <div style={{ position: "absolute", top: "50%", width: "100%" }}>
//             <h3>{d.data.name}</h3>
//           </div>
//         </div>
//       );
//       nodeStyle = {
//         border: "none",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         padding: 0,
//         boxShadow: "none",
//         background: "transparent",
//       };
//     } else if (d.data.id.startsWith("ee-")) {
//       // EE Component Node - Circle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "50%", // Circle shape
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("swm-")) {
//       // Software Module Node - Rectangle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: getNodeColor(
//           d.data.id,
//           selectedNodeId,
//           d.data.linkedSwmIds
//         ),
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "0%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     }

//     // Invert the y position to make it bottom-to-top
//     const invertedY = maxY - d.y;

//     // Add the node to the list
//     nodes.push({
//       id: d.data.id,
//       data: { label: nodeShape },
//       position: { x: d.x, y: invertedY },
//       style: nodeStyle,
//     });

//     // Create reversed edges (child -> parent)
//     if (d.parent) {
//       edges.push({
//         id: `${d.data.id}-${d.parent.data.id}`,
//         source: d.data.id, // Child node as source
//         target: d.parent.data.id, // Parent node as target
//         type: "step",
//         animated: false,
//         markerStart: {
//           type: MarkerType.ArrowClosed,
//           color: "#000000",
//         },
//       });
//     }
//   });

//   return { nodes, edges };
// };

// // Radial structure
// const RADIUS_INCREMENT = 300; // Distance between each layer (increase to avoid overlap)
// const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
//   const nodes = [];
//   const edges = [];

//   // Create a D3 hierarchy from the provided data
//   const root = d3.hierarchy(hierarchyData);

//   // Define the radial layout with custom radius for each level
//   const radialTreeLayout = d3
//     .tree()
//     .size([2 * Math.PI, root.height * RADIUS_INCREMENT])
//     .separation((a, b) => (a.parent === b.parent ? 2 : 3)); // Adjust separation

//   radialTreeLayout(root);

//   // Loop through each node and set its position
//   root.descendants().forEach((d) => {
//     // Calculate the radius for each level
//     const layerRadius = (d.depth + 1) * RADIUS_INCREMENT;
//     const x = layerRadius * Math.cos(d.x - Math.PI / 2);
//     const y = layerRadius * Math.sin(d.x - Math.PI / 2);

//     let nodeShape, nodeStyle;

//     // Define node styles and shapes based on node types
//     if (d.data.id.startsWith("project-")) {
//       // Central Project Node
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: 140,
//         height: 140,
//         borderRadius: "50%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("zone-")) {
//       // Zone Nodes
//       nodeShape = (
//         <div
//           style={{
//             textAlign: "center",
//             position: "relative",
//             width: "140px",
//             height: "140px",
//           }}
//         >
//           <svg width="140" height="140">
//             <polygon
//               points="70,5 140,135 0,135"
//               fill="#459C5B"
//               stroke="#000"
//               strokeWidth="1"
//             />
//           </svg>
//           <div style={{ position: "absolute", top: "50%", width: "100%" }}>
//             <h3>{d.data.name}</h3>
//           </div>
//         </div>
//       );
//       nodeStyle = {
//         border: "none",
//         color: "white",
//         width: 140,
//         height: 140,
//         background: "transparent",
//       };
//     } else if (d.data.id.startsWith("ee-")) {
//       // EE Component Nodes
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: 140,
//         height: 140,
//         borderRadius: "50%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("swm-")) {
//       // Software Module Nodes
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: getNodeColor(
//           d.data.id,
//           selectedNodeId,
//           d.data.linkedSwmIds
//         ),
//         color: "white",
//         width: 140,
//         height: 140,
//         borderRadius: "0%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     }

//     // Add node with calculated position
//     nodes.push({
//       id: d.data.id,
//       data: { label: nodeShape },
//       position: { x, y },
//       style: nodeStyle,
//     });

//     // Add edges from child to parent nodes
//     if (d.parent) {
//       edges.push({
//         id: `${d.data.id}-${d.parent.data.id}`,
//         source: d.data.id,
//         target: d.parent.data.id,
//         type: "step",
//         markerStart: { type: MarkerType.ArrowClosed, color: "#000" },
//       });
//     }
//   });

//   return { nodes, edges };
// };

// Left to Right
// const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
//   const nodes = [];
//   const edges = [];

//   const root = d3.hierarchy(hierarchyData);

//   // Set tree layout for horizontal orientation with swapped x and y
//   const treeLayout = d3
//     .tree()
//     .nodeSize([nodeHeight + marginY, nodeWidth + marginX]);
//   treeLayout(root);

//   // Calculate maximum x-coordinate for right-to-left flipping
//   const maxX = Math.max(...root.descendants().map((d) => d.y));

//   root.descendants().forEach((d) => {
//     let nodeShape, nodeStyle;

//     // Define shapes and styles for each node type
//     if (d.data.id.startsWith("project-")) {
//       // Project Node - Circle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "50%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("zone-")) {
//       // Zone Node - Triangle
//       nodeShape = (
//         <div
//           style={{
//             textAlign: "center",
//             position: "relative",
//             width: "140px",
//             height: "140px",
//           }}
//         >
//           <svg width="140" height="140">
//             <polygon
//               points="70,5 140,135 0,135"
//               fill="#459C5B"
//               stroke="#000"
//               strokeWidth="1"
//             />
//           </svg>
//           <div style={{ position: "absolute", top: "50%", width: "100%" }}>
//             <h3>{d.data.name}</h3>
//           </div>
//         </div>
//       );
//       nodeStyle = {
//         border: "none",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         padding: 0,
//         boxShadow: "none",
//         background: "transparent",
//       };
//     } else if (d.data.id.startsWith("ee-")) {
//       // EE Component Node - Circle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "50%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("swm-")) {
//       // Software Module Node - Rectangle
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: getNodeColor(
//           d.data.id,
//           selectedNodeId,
//           d.data.linkedSwmIds
//         ),
//         color: "white",
//         width: nodeWidth,
//         height: nodeHeight,
//         borderRadius: "0%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     }

//     // Swap x and y, then invert y for right-to-left
//     const flippedY = maxX - d.y;

//     // Add the node to the list
//     nodes.push({
//       id: d.data.id,
//       data: { label: nodeShape },
//       position: { x: flippedY, y: d.x }, // Swapped x and y for horizontal layout
//       style: nodeStyle,
//     });

//     // Create edges
//     if (d.parent) {
//       edges.push({
//         id: `${d.parent.data.id}-${d.data.id}`,
//         source: d.parent.data.id,
//         target: d.data.id,
//         type: "step",
//         animated: false,
//         markerEnd: {
//           type: MarkerType.ArrowClosed,
//           color: "#000000",
//         },
//       });
//     }
//   });

//   return { nodes, edges };
// };

// const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
//   const nodes = [];
//   const edges = [];

//   const root = d3.hierarchy(hierarchyData);

//   // Define a larger radial tree layout
//   const treeLayout = d3
//     .tree()
//     .size([2 * Math.PI, 600]) // Increase the size for more radial spacing
//     .separation((a, b) => (a.parent === b.parent ? 4 : 6)); // Increase separation

//   treeLayout(root);

//   // Loop through the hierarchical data
//   root.descendants().forEach((d) => {
//     // Calculate x, y position with increased radius
//     const radius = 400; // Increase the radius for more space between nodes
//     const padding = 200; // Increase padding for even more spacing
//     const angle = d.x;
//     const distance = d.y + padding; // Increase the distance between nodes
//     const x = radius + distance * Math.cos(angle - Math.PI / 2);
//     const y = radius + distance * Math.sin(angle - Math.PI / 2);

//     let nodeShape, nodeStyle;

//     // Define the styles and shapes for each node type
//     if (d.data.id.startsWith("project-")) {
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: 100, // Increase width
//         height: 100, // Increase height
//         borderRadius: "50%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("zone-")) {
//       nodeShape = (
//         <div style={{ textAlign: "center", position: "relative" }}>
//           <svg width="140" height="140">
//             <polygon points="70,5 140,135 0,135" fill="#459C5B" />
//           </svg>
//           <div style={{ position: "absolute", top: "50%", width: "100%" }}>
//             <h3>{d.data.name}</h3>
//           </div>
//         </div>
//       );
//       nodeStyle = {
//         border: "none",
//         color: "white",
//         width: 120,
//         height: 120,
//         background: "transparent",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("ee-")) {
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: 100,
//         height: 100,
//         borderRadius: "50%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("swm-")) {
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: getNodeColor(
//           d.data.id,
//           selectedNodeId,
//           d.data.linkedSwmIds
//         ),
//         color: "white",
//         width: 100,
//         height: 100,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     }

//     // Add the node with updated position
//     nodes.push({
//       id: d.data.id,
//       data: { label: nodeShape },
//       position: { x, y },
//       style: nodeStyle,
//     });

//     // Add edges with updated styles
//     if (d.parent) {
//       edges.push({
//         id: `${d.parent.data.id}-${d.data.id}`,
//         source: d.parent.data.id,
//         target: d.data.id,
//         type: "straight",
//         animated: false,
//         style: {
//           stroke: "#000000",
//           strokeWidth: 1.5, // Increase for better visibility
//         },
//         markerEnd: {
//           type: MarkerType.ArrowClosed,
//           color: "#000000",
//         },
//       });
//     }
//   });

//   return { nodes, edges };
// };

// const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
//   const nodes = [];
//   const edges = [];

//   const root = d3.hierarchy(hierarchyData);
//   const radialRadius = 350; // Increase this for more spacing
//   const clusterLayout = d3
//     .cluster()
//     .size([2 * Math.PI, radialRadius + 400 * root.height]); // Increase size for more spread

//   clusterLayout(root);

//   root.each((d) => {
//     const angle = d.x;
//     const radius = d.depth * 350; // Increase distance between layers
//     const x = radius * Math.cos(angle - Math.PI / 2);
//     const y = radius * Math.sin(angle - Math.PI / 2);

//     let nodeShape, nodeStyle;

//     // Define node shape based on type
//     if (d.data.id.startsWith("project-")) {
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: 160,
//         height: 160,
//         borderRadius: "50%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         border: "2px solid #00FF1A",
//       };
//     } else if (d.data.id.startsWith("zone-")) {
//       nodeShape = (
//         <div style={{ position: "relative", width: "140px", height: "140px" }}>
//           <svg width="140" height="140">
//             <polygon
//               points="70,5 140,135 0,135"
//               fill="#459C5B"
//               stroke="#000"
//               strokeWidth="1"
//             />
//           </svg>
//           <div style={{ position: "absolute", top: "50%", width: "100%" }}>
//             <h3>{d.data.name}</h3>
//           </div>
//         </div>
//       );
//       nodeStyle = {
//         color: "white",
//         width: 140,
//         height: 140,
//         background: "transparent",
//       };
//     } else if (d.data.id.startsWith("ee-")) {
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: "black",
//         color: "white",
//         width: 140,
//         height: 140,
//         borderRadius: "50%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     } else if (d.data.id.startsWith("swm-")) {
//       nodeShape = (
//         <div>
//           <h3>{d.data.name}</h3>
//           <p>
//             <strong>Version:</strong> {d.data.version}
//           </p>
//         </div>
//       );
//       nodeStyle = {
//         background: getNodeColor(
//           d.data.id,
//           selectedNodeId,
//           d.data.linkedSwmIds
//         ),
//         color: "white",
//         width: 140,
//         height: 140,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       };
//     }

//     nodes.push({
//       id: d.data.id,
//       data: { label: nodeShape },
//       position: { x, y },
//       style: nodeStyle,
//       sourcePosition: d.depth === 1 ? "bottom" : "top",
//       targetPosition: d.depth === 1 ? "top" : "bottom",
//     });

//     if (d.parent) {
//       edges.push({
//         id: `${d.parent.data.id}-${d.data.id}`,
//         source: d.parent.data.id,
//         target: d.data.id,
//         type: "straight", // Change edge type to straight
//         animated: false,
//         style: {
//           stroke: "#000000",
//           strokeWidth: 1.5,
//         },
//         markerEnd: {
//           type: "arrowclosed",
//           color: "#000000",
//         },
//       });
//     }
//   });

//   return { nodes, edges };
// };

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description : Show the mind map.
 * @param : selectedProjectId
 * @return : The rendered mind map.
 */
function VlcZonalMindMap({ selectedProjectId, selectedVariantId }) {
  const dispatch = useDispatch();

  /* useSelectors */
  const { projectDetailsMindMap } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Project */
  const [mindMapProjectDetails, setMindMapProjectDetails] = useState({});

  /* Nodes */
  const [flowNodes, setFlowNodes] = useState([]);
  const [flowEdges, setFlowEdges] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  /* useEffects */
  // Fetch project details when project ID changes
  useEffect(() => {
    if (selectedProjectId && selectedVariantId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_project_details_mind_map,
          params: { 
            project_id: selectedProjectId,
            variant_id: selectedVariantId,
           },
          show_toast: false,
        })
      );
    }
  }, [selectedProjectId, selectedVariantId]);

  // Update mind map details
  useEffect(() => {
    if (projectDetailsMindMap && projectDetailsMindMap.zones) {
      setMindMapProjectDetails(projectDetailsMindMap);
    }
  }, [projectDetailsMindMap]);

  // Generate nodes and edges when details or selection change
  useEffect(() => {
    if (mindMapProjectDetails && mindMapProjectDetails.zones) {
      const hierarchyData = createHierarchyData(mindMapProjectDetails);
      const { nodes, edges } = createNodesAndEdges(hierarchyData, selectedNode);
      setFlowNodes(nodes);
      setFlowEdges(edges);
    }
  }, [mindMapProjectDetails, selectedNode]);

  const onNodesChange = useCallback(
    (changes) => setFlowNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setFlowEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setFlowEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleNodeClick = useCallback((event, node) => {
    setSelectedNode(node.id);
  }, []);

  const animatedEdges = useMemo(() => {
    return flowEdges.map((edge) => {
      const isSelected =
        edge.source === selectedNode || edge.target === selectedNode;
      return {
        ...edge,
        // animated: isSelected,
        style: {
          strokeWidth: 2, // Increase this value to make the line thicker
          stroke: isSelected ? "#000000" : "#000000", // Optionally change color when selected
        },
      };
    });
  }, [flowEdges, selectedNode]);

  return (
    <Box sx={styles.mainContainer}>
      <ReactFlow
        nodes={flowNodes}
        edges={animatedEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={handleNodeClick}
        fitView
        minZoom={0.0000001}
        snapToGrid={true}
        snapGrid={[16, 16]}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </Box>
  );
}

export default VlcZonalMindMap;

// Styles
const styles = {
  mainContainer: {
    // height: "100vh",
    height: "520px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    border: "1px solid grey",
    borderRadius: 1,
    padding: "20px",
    mt: 1,
    boxShadow: 4,
  },
};
