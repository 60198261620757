import React, { useEffect, useState } from "react";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SelectTextField from "../../components/text-fields/SelectTextField";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import settingsApis from "../../redux/actions/settings";
import moment from "moment";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import { adminSettingsAPI } from "../../redux/actions";
import { CustomTextFieldN } from "../../components";

function UpdateVehicleStatus({
  openVehicleStatus,
  boxes,
  setOpenVehicleStatus,
}) {
  const dispatch = useDispatch();
  const vehicleStatusDetails = useSelector(
    (state) => state?.vehicleStatusReducer
  );

  //2.React useState
  const [selectPS, setSelectPS] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [reasonError, setReasonError] = useState("");
  const [reason, setReason] = useState("");

  //3.React useEffect

  useEffect(() => {
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_status }));
  }, []);
  useEffect(() => {
    let vehicleStatusData = vehicleStatusDetails?.vehicleStatusDetails?.data
      ?.map((el) => {
        return {
          value: el?.name,
          label: el?.name,
          sequence: el?.sequence,
        };
      })
      ?.sort((a, b) => a.sequence - b.sequence);

    setProjectData(vehicleStatusData);
  }, [vehicleStatusDetails]);

  useEffect(() => {
    setSelectPS(boxes?.vehicle_status);
  }, []);

  //4.Functions

  const handleClickEditStatus = () => {
    const reqObj = {
      project_id: boxes?.project_id,
      variant_id: boxes?.variant_id,
      vehicle_status: selectPS,
      reason: reason,
    };
    dispatch(
      HandleApiActions({
        ...projectConfigAPI?.update_vehicle_status,
        params: reqObj,
        show_toast: true,
        shallowEqual,
      })
    )?.then(
      dispatch(
        HandleApiActions({
          ...projectConfigAPI?.get_variant_details,
          params: {
            project_id: boxes?.project_id,

            is_complete_details_required: true,
          },
        })
      )
    );

    console.log("reqObj", reqObj);

    setOpenVehicleStatus(false);
  };
  return (
    <div>
      <CustomUpdateDialog
        onDisabled={
          selectPS === boxes?.vehicle_status || reason.length < 3 ? true : false
        }
        open={openVehicleStatus}
        onClose={() => setOpenVehicleStatus(false)}
        dialogTitle={"Update Vehicle Status"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={{ marginTop: "10px" }}>
              <SelectTextField
                disabled={!selectPS ? true : false}
                required
                size={"small"}
                label={"Vehicle Status"}
                optionsList={projectData}
                selectedValue={selectPS}
                setSelectedValue={setSelectPS}
                variant={"standard"}
                sltFldStyle={{
                  width: "270px",
                }}
              />
            </Box>
            <CustomTextFieldN
              fldType={"text0"}
              size={"small"}
              label={"Reason*"}
              placeholder={"Reason"}
              type={"text"}
              value={reason}
              setValue={setReason}
              valueError={reasonError}
              setValueError={setReasonError}
              variant={"standard"}
            />
          </Stack>
        }
        onConfirm={() => handleClickEditStatus(boxes)}
      />
    </div>
  );
}

export default UpdateVehicleStatus;
