/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-09-2024
 * @description : Shows the variant details
 */
import React from "react";
import { Divider, Grid, Paper, Typography } from "@mui/material";

function VariantDetailsBox({ selectedVariantDetails }) {
  return (
    <Paper elevation={2} sx={styles.detailsContainer}>
      <Typography sx={styles.heading}>Variant Details</Typography>
      <Divider sx={styles.divider} />
      <Grid container>
        <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Name: </span>
            {selectedVariantDetails?.name || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Status: </span>
            {selectedVariantDetails?.vehicle_status || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Delivery Market: </span>
            {selectedVariantDetails?.delivery_market || "-"}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Owner: </span>
            {selectedVariantDetails?.owner_details?.length &&
            selectedVariantDetails?.owner_details[0]?.name &&
            selectedVariantDetails?.owner_details[0]?.email
              ? `${selectedVariantDetails?.owner_details[0]?.name} - ${selectedVariantDetails?.owner_details[0]?.email}`
              : "-"}
          </Typography>
        </Grid> */}
      </Grid>
    </Paper>
  );
}

export default VariantDetailsBox;

// Styles
const styles = {
  detailsContainer: {
    border: "1px solid grey",
    px: 2,
    pt: 0.4,
    pb: 2,
    my: 2,
  },
  nrDetailsTxt: {
    textAlign: "start",
  },
  txtBold: {
    fontWeight: "bold",
  },
  heading: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  divider: {
    mt: 0.4,
    mb: 1,
  },
};
