import DashboardIcon from "../../assets/sidebarIcons/Dashboard.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "../../assets/sidebarIcons/Settings.svg";
import UserManagementIcon from "../../assets/sidebarIcons/UserManagement.svg";
import ProjectConfiguratorIcon from "../../assets/sidebarIcons/Project Configurator.svg";
import LegalRequirementIcon from "../../assets/sidebarIcons/Legal Requirements.svg";
import VirtualLabCarIcon from "../../assets/sidebarIcons/Virtual Lab Car.svg";
import TypeAppovalIcon from "../../assets/sidebarIcons/TypeApproval.svg";
import ProfileIcon from "../../assets/sidebarIcons/Profile.svg";
import APIDocIcon from "../../assets/sidebarIcons/api_doc.svg";
const styles = { wight: "25px", height: "25px" };

export const sideBarData = [
  {
    title: "Dashboard",
    path: "/dashboard/home",
    icon: <img alt="Dashboard" src={DashboardIcon} style={styles} />,
  },
  {
    title: "Project Configurator",
    path: "/dashboard/project-config",
    icon: <img alt="pc" src={ProjectConfiguratorIcon} style={styles} />,
  },
  {
    title: "Virtual Lab-Car",
    path: "/dashboard/labcar",
    icon: <img alt="vlc" src={VirtualLabCarIcon} style={styles} />,
  },

  {
    title: "Type Approvals",
    path: "/dashboard/type-approval",
    icon: <img alt="ta" src={TypeAppovalIcon} style={styles} />,
  },
  {
    title: "Legal Requirements",
    path: "/dashboard/legal-requirements",
    icon: <img alt="lr" src={LegalRequirementIcon} style={styles} />,
  },
  {
    title: "Profile",
    path: "/dashboard/profile",
    icon: <img alt="p" src={ProfileIcon} style={styles} />,
  },
  {
    title: "User Management",
    path: "/dashboard/user-management",
    icon: <img alt="um" src={UserManagementIcon} style={styles} />,
  },
  {
    title: "Settings",
    path: "/dashboard/admin-settings",
    icon: <img alt="as" src={SettingsIcon} style={styles} />,
  },
  {
    title: "Document Library",
    path: "/dashboard/doc_library",
    icon: <img alt="ad" src={APIDocIcon} style={styles} />,
  },
  {
    title: "API Documents",
    path: "/dashboard/docs",
    icon: <img alt="ad" src={APIDocIcon} style={styles} />,
  },
];
