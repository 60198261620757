import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextFieldSP,
  MultiSelectTextField2,
  MultiSelectTextField3,
  SearchSelectTextField,
  SelectTextField,
} from "../../components";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import customConsole from "../../config/customConsole";
import {
  adminSettingsAPI,
  HandleApiActions,
  profileActions,
  projectConfigAPI,
} from "../../redux/actions";
import { GetSingleImages } from "../../redux/actions/actionHandler";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoButton from "../../components/infoButton/InfoButton";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function ReFirstScreen({
  close,
  selectedSortColumn,
  selectedSortOrder,
  recreateProject,
  page,
  rowsPerPage,
}) {
  const projectStatus = {
    100: "ACTIVE_PRE_SOP",
    200: "ACTIVE_POST_SOP",
    300: "DE_ACTIVATED",
    500: "COMPLETED",
  };

  //1.Redux
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectDetailsError = useSelector(
    (state) => state.projectConfigReducer
  );
  console.log("projectDetailsError", projectDetailsError.msg);

  const userInSystem = useSelector(
    (state) => state.userDetailsReducer?.userDetailsList
  );
  const timeZoneDetails = useSelector(
    (state) => state.countryAndPhoneCodeReducer.countryAndPhoneCode
  );
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );
  const architectureTypesDetails = useSelector(
    (state) => state.adminSettingsReducer
  );
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData
  );
  const vehicleClassDetails = useSelector((state) => state.vehicleClassReducer);
  const vehicleStatusDetails = useSelector(
    (state) => state?.vehicleStatusReducer
  );

  // const projectStatusList = Object.keys(projectStatusDetails?.project_status);
  // const projectStatusList = Object.entries(
  //   projectStatusDetails?.project_status
  // ).map(([key, value]) => ({
  //   label: key,
  //   value: value,
  // }));

  customConsole(
    "---vehiclerStatusDetails---",
    projectStatusDetails?.vehicle_status
  );

  ///2.React useState
  const [vfData, setVfData] = useState([]);
  const [mftData, setMftData] = useState([]);
  const [pcData, setPcData] = useState([]);
  const [architectureTypes, setArchitectureTypes] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [vcData, setVcData] = useState([]);
  const [image, setImage] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [DmData, setDMData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [VTData, setVTData] = useState([]);

  const [region, setRegion] = useState("");
  const [TMState, setTMState] = useState([]);
  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectPS, setSelectPS] = useState("");

  const [name, setName] = useState("");
  const [mftDetailsState, setMftDetailsState] = useState([]);
  const [DMState, setDMState] = useState([]);
  const [commercialName, setCommercialName] = useState("");
  const [vehiclePlatform, setVehiclePlatform] = useState("");

  const [selectedItemsVf, setSelectedItemsVf] = useState([]);
  const [selectedItemsPc, setSelectedItemsPc] = useState([]);
  const [selectedItemsAt, setSelectedItemsAt] = useState("");
  const [selectedItemsZone, setSelectedItemsZone] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedCoOwners, setSelectedCoOwners] = useState([]);
  const [year, setYear] = useState(null);
  const [selectSeat, setSelectSeat] = useState("");
  const [selectVT, setSelectVT] = useState("");
  //error useState
  const [nameError, setNameError] = useState(false);
  const [commercialNameError, setCommercialNameError] = useState(false);
  const [manufacturerNameError, setManufacturerNameError] = useState(false);
  const [manufacturerAddressError, setManufacturerAddressError] = useState("");
  const [vehiclePlatformError, setVehiclePlatformError] = useState("");
  const [vehicleStatusError, setVehicleStatusError] = useState("");
  const [seatError, setYearError] = useState(false);

  console.log("YEARRR", year);
  useEffect(() => {
    console.log("recreateProject++++", recreateProject);
    if (recreateProject) {
      setRegion(recreateProject?.region || "");

      setCountry(recreateProject.country || "");
      setSelectPS(recreateProject.status || "");
      setName(recreateProject.name || "");
      setCommercialName(
        recreateProject.name === name ? name : recreateProject.trade_mark || ""
      );
      setVehiclePlatform(recreateProject.vehicle_platform || "");

      setSelectedItemsAt(recreateProject.architecture_name || "");

      setSelectedClass(recreateProject.veh_class || "");
      setSelectVT(recreateProject?.vehicle_type || "");
      setTMState(recreateProject?.target_market || "");
      setSelectSeat(recreateProject?.no_of_seats || "");
      setYear(recreateProject?.model_year);
    }
  }, [recreateProject]);

  useEffect(() => {
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_status }));
  }, []);

  //3.React useEffect
  useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_admin_settings }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
    dispatch(HandleApiActions({ ...projectConfigAPI.get_users_list }));
  }, []);

  useEffect(() => {
    customConsole("TimezoneDetails", timeZoneDetails);
    if (timeZoneDetails && timeZoneDetails.length > 0) {
      const sortedRegions = timeZoneDetails?.map((data) => data?.time_zone);

      setRegions(sortedRegions);
      const countryOptions = timeZoneDetails?.map((data) => data?.country);

      setCountries(countryOptions);
    }
  }, [timeZoneDetails]);

  useEffect(() => {
    const projectStatusData = Object.entries(
      projectStatusDetails?.project_status
    )
      ?.map(([key, value]) => ({
        label: key?.replace(/_/g, " "),
        value: value,
      }))
      ?.slice(0, 3);

    console.log("----projectStatusData----", projectStatusData);
    setProjectData(projectStatusData);
    if (projectStatusDetails?.vehicle_type?.length > 0) {
      const VTDetails = projectStatusDetails?.vehicle_type?.map((data) => {
        return {
          value: data?.key,
          label: data?.value,
        };
      });

      setVTData(VTDetails);
    }
  }, [projectStatusDetails]);
  useEffect(() => {
    console.log("vehicleStatusDetails<---", vehicleStatusDetails);

    const vehicleStatusData =
      vehicleStatusDetails?.vehicleStatusDetails?.data?.map((el) => ({
        value: el?.name,
        label: el?.name,
      }));

    console.log("vehicleStatusData", vehicleStatusData);

    setVehicleData(vehicleStatusData);
  }, [vehicleStatusDetails]);

  useEffect(() => {
    customConsole("vehicleFunctionDetails......");

    customConsole(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
    setVfData(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
  }, [vehicleFunctionDetails]);

  useEffect(() => {
    customConsole("vehicleClassDetails......");

    customConsole(vehicleClassDetails?.vehicleClassDetails?.data);
    setVcData(vehicleClassDetails?.vehicleClassDetails?.data);
  }, [vehicleClassDetails]);

  useEffect(() => {
    customConsole("powerTrainDetails");
    customConsole(powerTrainClassDetails?.powerTrainClassDetails?.data);
    setPcData(powerTrainClassDetails?.powerTrainClassDetails?.data);
  }, [powerTrainClassDetails]);

  useEffect(() => {
    customConsole("architectureTypes");
    customConsole(
      architectureTypesDetails?.adminSettings?.data?.architecture_types || []
    );
    setArchitectureTypes(
      architectureTypesDetails?.adminSettings?.data?.architecture_types || []
    );
    setZoneData(
      architectureTypesDetails?.adminSettings?.data?.zone_details || []
    );
    setMftData(
      architectureTypesDetails?.adminSettings?.data?.vehicle_manufacturers || []
    );
    setDMData(
      architectureTypesDetails?.adminSettings?.data?.product_delivery_markets ||
        []
    );
  }, [architectureTypesDetails]);

  useEffect(() => {
    if (vcData && vcData.length) {
      customConsole("---vcData----");
      customConsole(vcData);
      const imageUrl = async () => {
        const fetchUrlArr = await Promise.all(
          vcData.map(async (item) => {
            customConsole("---Image URL----");
            customConsole(item.image);
            try {
              const response = await GetSingleImages({ image: item.image });
              return { ...item, imageUrl: response };
            } catch (error) {
              customConsole(error);
              return { ...item, imageUrl: "" };
            }
          })
        );
        customConsole("----Fetch Url--");
        customConsole(fetchUrlArr);
        setImage(fetchUrlArr);
      };
      imageUrl();
    }
  }, [vcData]);

  useEffect(() => {
    console.log("----Zone change--");
    console.log(selectedItemsZone);
  }, [selectedItemsZone]);

  useEffect(() => {
    if (Array.isArray(userInSystem)) {
      const filteredUserDetails = userInSystem?.map((data) => ({
        fullname: data?.fullname || "N/A",
        email: data?.email || "N/A",
        user_id: data?.user_id || "N/A",
      }));

      console.log("filteredUserDetails", filteredUserDetails);

      setUserData(filteredUserDetails);
    } else {
      console.warn("userInSystem is not an array:", userInSystem);
      setUserData([]); // or set a default value if needed
    }
  }, [userInSystem]);

  const handleYearChange = (newValue) => {
    const value = newValue;
    setYear(value);
  };
  const handleCreate = async () => {
    const reqObj = {
      name: name,

      ...(commercialName?.length > 0 ? { trade_mark: commercialName } : null),

      vehicle_platform: vehiclePlatform,
      status: selectPS,
      architecture_name: selectedItemsAt,
      mft_details: mftDetailsState,
      ...(selectedItemsZone?.length > 0 &&
      selectedItemsAt?.toLowerCase() === "zonal"
        ? { zones: selectedItemsZone }
        : {}),

      vehicle_class: parseInt(selectedClass),

      pwrtrn_class: selectedItemsPc?.map((str) => parseInt(str)),
      ...(selectedItemsVf?.length > 0
        ? { vehicle_funcs: selectedItemsVf?.map((str) => parseInt(str)) }
        : {}),

      current_time: moment().format(),
      country: country,
      region: region,
      ...(DMState ? { delivery_market: DMState } : null),
      ...(selectedCoOwners?.length > 0
        ? {
            co_owners: selectedCoOwners,
          }
        : null),
      vehicle_type: selectVT,
      no_of_seats: selectSeat,
      target_market: TMState,
      model_year: dayjs(year)?.format("YYYY"),
    };
    console.log("reqObj...", reqObj);
    let resp = await dispatch(
      HandleApiActions({
        ...projectConfigAPI.add_project,
        params: reqObj,
        show_toast: true,
      })
    );

    console.log("--->resp<---", resp);
    if (!resp.error) {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_project,
          // params: {
          //   page_number: page,
          //   page_size: rowsPerPage,
          //   order_by_column: selectedSortColumn,
          //   order_by_sort: selectedSortOrder,
          // },
        })
      );

      close(false);
      navigate(`/dashboard/project-config`);
    }
  };

  customConsole("selectedItemsPc", selectedItemsPc);
  customConsole("selectedItemsZone", selectedItemsZone);

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    customConsole("event", event.target.value);
  };

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }

  useEffect(() => {
    customConsole("---Image---");
    customConsole(image);
  }, [image]);

  useEffect(() => {
    const timeZoneFilter = timeZoneDetails?.filter(
      (item) => item?.country?.toUpperCase() === country?.toUpperCase()
    );
    const timeZoneOptions = timeZoneFilter?.map((data) => data?.time_zone);
    console.log("timeZoneOptions...", timeZoneOptions);
    setRegion(timeZoneOptions?.[0]);
  }, [country, timeZoneDetails, recreateProject]);

  useEffect(() => {
    console.log("vffff", recreateProject?.project_zones);
    console.log(
      "svff",
      architectureTypesDetails?.adminSettings?.data?.zone_details
    );
  });

  useEffect(() => {
    const filteredVf = recreateProject?.veh_funcs
      ?.map((data) => data)
      ?.filter((value) => vfData?.map((data) => data?.id)?.includes(value));
    console.log("filteredVf", filteredVf);
    setSelectedItemsVf(filteredVf);
  }, [recreateProject, vfData]);

  useEffect(() => {
    const filteredpc = recreateProject.pwrtrn_class
      ?.map((data) => data)
      ?.filter((value) => pcData?.map((data) => data?.id)?.includes(value));
    console.log("filteredpc", filteredpc);
    setSelectedItemsPc(filteredpc);
  }, [recreateProject, pcData]);

  useEffect(() => {
    const filteredCoOwners = recreateProject?.co_owners
      ?.map((data) => data)
      ?.filter((value) =>
        userData?.map((user) => user?.user_id)?.includes(value)
      );
    console.log("filteredCoOwners", filteredCoOwners);
    setSelectedCoOwners(filteredCoOwners);
  }, [recreateProject, userData]);

  useEffect(() => {
    console.log("??recreateProject", recreateProject);
    const filteredDeliveryMarkets = recreateProject?.delivery_market
      ?.map((data) => data)
      ?.filter((value) => DmData?.map((data) => data)?.includes(value));
    console.log("filteredDeliveryMarkets", filteredDeliveryMarkets);
    setDMState(filteredDeliveryMarkets);
  }, [recreateProject, DmData]);

  useEffect(() => {
    console.log("??recreateProject", recreateProject);

    // Create an array of objects from `mft_details`
    const filteredMftDetails = recreateProject?.mft_details?.map((data) => ({
      name: data?.name,
      address: data?.address,
    }));

    console.log("filteredMftDetails", filteredMftDetails);
    setMftDetailsState(filteredMftDetails);
  }, [recreateProject, mftData]);

  const filterProjectZones = (projectZones, settingsZones) => {
    return projectZones?.filter((projectZone) =>
      settingsZones?.some(
        (settingsZone) =>
          settingsZone.zone_position === projectZone.position &&
          settingsZone.position_name === projectZone.zone_name
      )
    );
  };

  useEffect(() => {
    const filteredZones = filterProjectZones(
      recreateProject?.project_zones,
      architectureTypesDetails?.adminSettings?.data?.zone_details
    );
    console.log("filteredZones", filteredZones);
    setSelectedItemsZone(filteredZones);
  }, [recreateProject, architectureTypesDetails]);

  const handleNumberYear = (e) => {
    const newValue = e.target.value;

    if (newValue.length <= 1 && !isNaN(newValue)) {
      setSelectSeat(newValue);
      setYearError(false);
    } else {
      setYearError(true);
    }
  };

  return (
    <div>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ fontStyle: "italic", mt: "10px" }}>
            Required fields are marked with asterisk *
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box>
              {/* <TextField
              size="medium"
              variant="standard"
              type="month"
              required
              placeholder="Model Year"
              value={`${year}`}
              onChange={handleYearChange}
            /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{ width: "90%" }}
                    variant="standard"
                    label="Select a Model Year*"
                    openTo="year"
                    views={["year"]}
                    value={dayjs(year)}
                    onChange={handleYearChange}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                ml: "10px",
              }}
            >
              <SelectTextField
                required
                size={"small"}
                label={"Project Status"}
                optionsList={projectData}
                selectedValue={selectPS}
                setSelectedValue={setSelectPS}
                variant={"filled"}
                sltFldStyle={{
                  width: "150px",
                }}
              />
              <InfoButton infoKey={"project_status"} color={"grey"} />
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ display: "flex", mr: "35px" }}>
            <Box
              sx={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              <SearchSelectTextField
                key={"country"}
                optionsList={countries}
                selectedValue={country}
                setSelectedValue={setCountry}
                size={"small"}
                label={"Country*"}
                txtFldStyle={{ width: "200px" }}
              />
            </Box>
            <Box sx={{ marginTop: "10px", marginLeft: "10px" }}>
              <SearchSelectTextField
                size={"small"}
                label={"Time Zone*"}
                placeholder={"Time Zone*"}
                noOptionsText={"No Time Zone"}
                optionsList={regions}
                selectedValue={region}
                setSelectedValue={setRegion}
                txtFldStyle={{
                  width: "180px",
                }}
              />
            </Box>
          </Box>
        </Box>
        {/* 1. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>1</Avatar>
          <Grid container sx={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                Project Name *
              </Typography>
              <CustomTextFieldSP
                size="normal"
                placeholder="Name of the Project"
                required
                value={name}
                setValue={setName}
                txtFldStyle={{ width: "98%" }}
                validationRules={{ minLength: 3 }}
                setError={setNameError}
                error={nameError !== ""}
              />
            </Grid>
          </Grid>
        </Box>

        {/* 2. */}
        {/* <Grid container spacing={2} alignItems="center" mt={2.5}>
        <Grid item md={1}>
          <Avatar>2</Avatar>
        </Grid>
 
        <Grid item>
          <Typography sx={{ fontWeight: "bold" }}>Trademark</Typography>
          <CustomTextFieldSP
            size="small"
            placeholder="Trademark"
            value={commercialName}
            setValue={setCommercialName}
            txtFldStyle={{
              width: "270px",
              mr: "10px",
              mb: "10px",
            }}
            validationRules={{ minLength: 0 }}
            setError={setCommercialNameError}
            error={commercialNameError !== ""}
          />
        </Grid>
        <Grid item>
          <InfoButton infoKey="trademark" color="grey" />
        </Grid>
 
        <Grid item>
          <Typography sx={{ fontWeight: "bold" }}>
            Vehicle Platform *
          </Typography>
          <CustomTextFieldSP
            required
            size="small"
            placeholder="Vehicle Platform"
            value={vehiclePlatform}
            setValue={setVehiclePlatform}
            txtFldStyle={{
              width: "270px",
              mr: "10px",
              mt: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setVehiclePlatformError}
            error={vehiclePlatformError !== ""}
          />
        </Grid>
        <Grid item>
          <InfoButton infoKey="vehicle_platform" color="grey" />
        </Grid>
 
        <Grid item>
          <Typography sx={{ fontWeight: "bold" }}>
            Delivery Market*
          </Typography>
          <Autocomplete
            multiple
            value={DMState}
            options={DmData || []}
            onChange={(event, newValue) => {
              setDMState(newValue);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: "330px", mr: "10px", pb: "10px" }}
                {...params}
                variant="standard"
                label="Select Delivery Market*"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box>
                  <Typography>{option}</Typography>
                </Box>
              </li>
            )}
          />
        </Grid>
 
        <Grid item>
          <InfoButton infoKey="vehicle_status" color="grey" />
        </Grid>
 
        <Grid item>
          <Typography sx={{ fontWeight: "bold" }}>
            Delivery Market*
          </Typography>
          <Autocomplete
            multiple
            value={DMState}
            options={DmData || []}
            onChange={(event, newValue) => {
              setDMState(newValue);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: "330px", mr: "10px", pb: "10px" }}
                {...params}
                variant="standard"
                label="Select Delivery Market*"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box>
                  <Typography>{option}</Typography>
                </Box>
              </li>
            )}
          />
        </Grid>
      </Grid> */}

        {/* 2. */}
        <Grid container spacing={2} alignItems="center" mt={2.5}>
          <Box sx={{ ml: "15px" }}>
            <Avatar>2</Avatar>
          </Box>
          <Grid item md={2}>
            <Typography sx={{ fontWeight: "bold" }}>Trademark</Typography>
            <CustomTextFieldSP
              size="small"
              placeholder="Trademark"
              value={commercialName}
              setValue={setCommercialName}
              txtFldStyle={{
                mr: "10px",
                mb: "10px",
                mt: "13px",
              }}
              validationRules={{ minLength: 0 }}
              setError={setCommercialNameError}
              error={commercialNameError !== ""}
            />
          </Grid>
          <Grid item md={3}>
            <Typography sx={{ fontWeight: "bold" }}>
              Vehicle Platform *
            </Typography>
            <CustomTextFieldSP
              required
              size="small"
              placeholder="Vehicle Platform"
              value={vehiclePlatform}
              setValue={setVehiclePlatform}
              txtFldStyle={{
                mt: "13px",
                mr: "10px",
                mb: "10px",
              }}
              validationRules={{ minLength: 3 }}
              setError={setVehiclePlatformError}
              error={vehiclePlatformError !== ""}
            />
          </Grid>
          <Grid item md={3} sx={{ mb: "10px" }}>
            <Typography sx={{ fontWeight: "bold" }}>Target Market*</Typography>
            <Autocomplete
              sx={{ mt: "11px" }}
              value={TMState}
              options={DmData || []}
              onChange={(event, newValue) => {
                if (newValue) {
                  setTMState(newValue);
                }
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select a Target Market"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Typography>{option}</Typography>
                  </Box>
                </li>
              )}
            />
          </Grid>
          <Grid item md={3} sx={{ pb: "15px" }}>
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>
                Delivery Market
              </Typography>
            </Box>

            <Autocomplete
              multiple
              disableCloseOnSelect
              value={DMState}
              options={DmData || []}
              onChange={(event, newValue) => {
                setDMState(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select Delivery Market"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Typography>{option}</Typography>
                  </Box>
                </li>
              )}
            />
          </Grid>
        </Grid>

        {/* 3. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>3</Avatar>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              Type Of Architecture *
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={selectedItemsAt}
                onChange={(event, newValue) => setSelectedItemsAt(newValue)}
                options={architectureTypes?.map((data) => data.name) || []}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="standard"
                    placeholder="Select an Architecture"
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </FormControl>

            <Box>
              {selectedItemsAt &&
              architectureTypes?.find((item) => item?.name === selectedItemsAt)
                ?.is_zones_available ? (
                <Box mt="20px">
                  <Typography fontWeight={"bold"}>
                    Select the Zones & Positions*
                  </Typography>

                  <Box>
                    <MultiSelectTextField3
                      disabled={!selectedItemsZone}
                      size="medium"
                      label="Select Zones"
                      optionsList={zoneData}
                      selectedValues={selectedItemsZone}
                      setSelectedValues={setSelectedItemsZone}
                      sltFldStyle={{ width: "100%" }}
                    />
                  </Box>
                </Box>
              ) : (
                <Box mb="10px"></Box>
              )}
            </Box>
          </Box>
          <InfoButton infoKey={"type_of_architecture"} color={"grey"} />
        </Box>

        {/* 4. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>4</Avatar>

          <Grid container spacing={2}>
            {/* Vehicle Class */}
            <Grid item md={4}>
              <Box sx={{ ml: 2, flex: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Vehicle Class *
                </Typography>
                <FormControl fullWidth sx={{ mt: "12px", width: "100%" }}>
                  <Select
                    variant="standard"
                    value={selectedClass}
                    onChange={handleClassChange}
                    sx={{ mb: 2 }}
                    label="Select Vehicle Class"
                  >
                    {/* Placeholder */}
                    <MenuItem value="" disabled>
                      Select Vehicle Class
                    </MenuItem>

                    {image?.map((data) => (
                      <MenuItem key={data?.id} value={data?.id}>
                        <Box display="flex">
                          <Box>
                            <img
                              src={data.imageUrl}
                              alt=""
                              style={{
                                width: "56px",
                                height: "56px",
                                marginRight: "8px",
                              }}
                            />
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <Typography fontWeight="bold">
                              {data?.name}
                            </Typography>
                            <Typography>
                              {data?.class}-{data?.sub_class}
                            </Typography>
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Number of Seats */}
            <Grid item md={4}>
              <Typography sx={{ fontWeight: "bold" }}>
                Number of Seats *
              </Typography>
              <TextField
                sx={{ width: "100%", maxHeight: 48 * 4.5 + 8, mt: "13.5px" }}
                size="medium"
                variant="standard"
                type="number"
                required
                placeholder="Number Of Seats"
                value={selectSeat}
                onChange={handleNumberYear}
                error={Boolean(seatError)}
                helperText={seatError ? "Input cannot exceed 1 digit" : ""}
              />
            </Grid>

            {/* Vehicle Type */}
            <Grid item md={4} sx={{ display: "flex" }}>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  Vehicle Type *
                </Typography>
                <SelectTextField
                  required
                  size="small"
                  label="Vehicle Type"
                  optionsList={VTData}
                  selectedValue={selectVT}
                  setSelectedValue={setSelectVT}
                  variant="standard"
                  sltFldStyle={{ pb: "10px", width: "330px" }}
                />
              </Box>
              <InfoButton infoKey={"manufacturer_name"} color={"grey"} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Box sx={{ ml: "30px" }}></Box>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold", ml: "10px" }}>
              {" "}
              Vehicle Function*
            </Typography>
            <MultiSelectTextField2
              mutiple
              size={"small"}
              label={"Select Vehicle Function"}
              optionsList={vfData}
              selectedValues={selectedItemsVf}
              setSelectedValues={setSelectedItemsVf}
              sltFldStyle={{
                textAlign: "start",
                width: "100%",
                ml: "10px",
                mt: "5px",

                backgroundColor: "white",
              }}
            />
          </Box>
          <InfoButton infoKey={"vehicle_function"} color={"grey"} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Box sx={{ ml: "30px" }}></Box>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold", ml: "10px" }}>
              {" "}
              PowerTrain Class*
            </Typography>
            <MultiSelectTextField2
              mutiple
              variant="standard"
              size={"small"}
              label={"Select Powertrain Class*"}
              optionsList={pcData}
              selectedValues={selectedItemsPc}
              setSelectedValues={setSelectedItemsPc}
              sltFldStyle={{
                width: "100%",
                textAlign: "start",
                ml: "10px",

                backgroundColor: "white",
              }}
            />
          </Box>
          <InfoButton infoKey={"power_train_class"} color={"grey"} />
        </Box>

        {/* 5. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>5</Avatar>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              Manufacturer Details *
            </Typography>
            <Autocomplete
              multiple
              value={mftDetailsState}
              options={mftData || []}
              //getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setMftDetailsState(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Manufacturer Details"
                  variant="standard"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                      {option?.name}
                    </Typography>
                    <Box>
                      <Typography>{option?.address}</Typography>
                    </Box>
                  </Box>
                </li>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.name}
                    key={index}
                    {...getTagProps({ index })}
                    sx={{ marginRight: "8px" }}
                  />
                ))
              }
            />
          </Box>
          <InfoButton infoKey={"manufacturer_name"} color={"grey"} />
        </Box>

        {/* 6. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>6</Avatar>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              Select Co-Owners
            </Typography>
            <Autocomplete
              multiple
              options={userData || []}
              disableCloseOnSelect
              value={userData?.filter((user) =>
                selectedCoOwners?.includes(user?.user_id)
              )}
              onChange={(event, newValue) => {
                setSelectedCoOwners(newValue.map((user) => user?.user_id));
              }}
              getOptionLabel={(option) => option?.fullname}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Box>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {option?.fullname}
                      </Typography>
                      <Typography>{option?.email}</Typography>
                    </Box>
                  </li>
                );
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Select the Co-Owners"
                  placeholder="Co Owners"
                />
              )}
            />
          </Box>
          <InfoButton infoKey={"manufacturer_name"} color={"grey"} />
        </Box>
      </Box>
      <Divider
        sx={{
          marginTop: "25px",
          marginRight: "10px",
          border: 1,
        }}
        flexItem
      />
      <Box mt="10px" display="flex" justifyContent="flex-end">
        <CustomButton
          handleOnClick={handleCreate}
          disabled={
            name?.length > 2 &&
            region &&
            country &&
            vehiclePlatform?.length > 2 &&
            selectedItemsVf?.length > 0 &&
            selectedItemsPc?.length > 0 &&
            selectedItemsAt?.length > 0 &&
            mftDetailsState?.length > 0 &&
            selectSeat &&
            selectVT &&
            year &&
            TMState
              ? false
              : true
          }
          size="medium"
          btnName={" Recreate Project"}
          variant="contained"
          startIcon={<AddIcon />}
        />
      </Box>
    </div>
  );
}

export default ReFirstScreen;
