/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description : Component to add, update and replace software modules.
 */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { TbReplace } from "react-icons/tb";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import {
  CustomAddDialog,
  CustomConfirmationDialog,
  CustomLogsDialog,
  CustomReplaceDialog,
  CustomTextFieldN,
  CustomUpdateDialog,
  MultiSelectTextFieldN,
} from "../../components";
import appTheme from "../../assets/AppTheme/appTheme";
import customConsole from "../../config/customConsole";
import { useDispatch } from "react-redux";
import { HandleApiActions, labCar } from "../../redux/actions";
import InfoButton from "../../components/infoButton/InfoButton";
import { Link } from "react-router-dom";
import editRequestAPI from "../../redux/actions/editRequestApi";
import PendingIcon from "@mui/icons-material/Pending";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LockIcon from "@mui/icons-material/Lock";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description : Show the software modules component
 * @param : heading, sWModulesList, handleSWModuleAdd, handleSWModuleUpdate, handleSWModuleReplace, setIsEEComponentSelected, setSelectedEEComponentDetails, selectedProjectDetails,
 * @return : The rendered software module component.
 */
function VlcSWModules({
  heading,
  sWModulesList,
  handleSWModuleAdd,
  handleSWModuleUpdate,
  handleSWModuleReplace,
  setIsEEComponentSelected,
  setSelectedEEComponentDetails,
  selectedProjectDetails,
  selectedEEComponentDetails,
  setIsLinkSoftwareModulesScreen,
  selectedZone,
  getSWModulesList,
  selectedSingleVariantDetails,
}) {
  const dispatch = useDispatch();

  const [activeItemId, setActiveItemId] = useState(null); // State to track which item's actions are visible
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reason, setReason] = useState("");

  /* -------------- Dialogs -------------- */
  const [openSWModuleAddDialog, setOpenSWModuleAddDialog] = useState(false);
  const [openSWModuleUpdateDialog, setOpenSWModuleUpdateDialog] =
    useState(false);
  const [openSWModuleReplaceDialog, setOpenSWModuleReplaceDialog] =
    useState(false);
  const [openSWModuleChangeLogsDialog, setOpenSWModuleChangeLogsDialog] =
    useState(false);
  const [pendingDialogOpen, setPendingDialogOpen] = useState(false);

  /* -------------- Selected sw module update/replace details  -------------- */
  const [selectedSWModuleUpdateDetails, setSelectedSWModuleUpdateDetails] =
    useState(null);
  const [selectedSWModuleReplaceDetails, setSelectedSWModuleReplaceDetails] =
    useState(null);

  /* -------------- Vehicle Functions-------------- */
  const [vfOptionsList, setVfOptionsList] = useState([]);

  /* -------------- SW Modules Change Logs -------------- */
  const [swChangeLogsList, setSWChangeLogsList] = useState([]);
  const [swLogsDialogData, setSWLogsDialogData] = useState({});

  const [requestsDialogOpen, setRequestsDialogOpen] = useState(false);

  const [getRequestsData, setGetRequestData] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedSWM, setSelectedSWM] = useState(null);

  const [actionType1DialogOpen, setActionType1DialogOpen] = useState(false);
  const [actionType2DialogOpen, setActionType2DialogOpen] = useState(false);

  const [reasonError, setReasonError] = useState("");

  const linkModuleType = { SWM: 1, EE: 2 };

  // Dialog states
  const [openLockUnLockSWMDialog, setOpenLockUnLockSWMDialog] = useState(null);
  const [lockUnLockSWMReason, setLockUnLockSWMReason] = useState(null);
  useState(null);
  const [lockUnLockSWMData, setLockUnLockSWMData] = useState(null);

  /* Handle lock/un-lock software module with reason dialog */
  const handleLockUnLockSWM = (params) => {
    setLockUnLockSWMData(params);

    setOpenLockUnLockSWMDialog(true);
  };
  const handleConfirmLockUnLockSWM = async () => {
    let apiDetails = labCar.lock_unlock_software_module;

    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectDetails?.project_id),
          variant_id: Number(selectedSingleVariantDetails?.variant_id),
          ee_id: Number(selectedEEComponentDetails?.ee_id),
          swm_id: lockUnLockSWMData?.swmId,
          is_locked: lockUnLockSWMData?.isLocked,
          ...(lockUnLockSWMReason && { reason: lockUnLockSWMReason }),
        },
        show_toast: true,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id: lockUnLockSWMData?.swmId,
            is_locked: lockUnLockSWMData?.isLocked,
            ...(lockUnLockSWMReason && { reason: lockUnLockSWMReason }),
          },
          show_toast: true,
        })
      );
      if (resp.error) {
      } else {
        getSWModulesList();
        handleCloseLockUnLockSWM();
      }
    }
  };
  const handleCloseLockUnLockSWM = () => {
    setOpenLockUnLockSWMDialog(false);

    // Delay clearing the reason by 300 milliseconds
    setTimeout(() => {
      setLockUnLockSWMData(null);
      setLockUnLockSWMReason("");
    }, 300);
  };

  /* -------------- Handle update/replace functions -------------- */
  const handleSelectedSWModuleUpdateDetails = (sWModule) => {
    setOpenSWModuleUpdateDialog(true);

    if (sWModule) {
      setSelectedSWModuleUpdateDetails(sWModule);
    }
  };

  const handleSelectedSWModuleReplaceDetails = (sWModule) => {
    setOpenSWModuleReplaceDialog(true);

    if (sWModule) {
      setSelectedSWModuleReplaceDetails(sWModule);
    }
  };

  /* Get software module change logs */
  const handleGetSWModuleChangeLogs = async ({ swm_id, pageNo, pageSize }) => {
    let apiDetails = labCar.get_sw_modules_change_logs;

    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      // selectedProjectDetails?.variant_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        swm_id,
        page_number: pageNo,
        page_size: pageSize,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id,
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setSWChangeLogsList([]);
      } else {
        setSWChangeLogsList(resp?.data);
      }
    }
  };

  /* Handle dialogs */
  const handleSWLogsDialogOpen = (data) => {
    setOpenSWModuleChangeLogsDialog(true);

    // handleGetSWModuleChangeLogs({
    //   ee_id: Number(data?.ee_id),
    //   swm_id: Number(data?.swm_id),
    // });

    setSWLogsDialogData(data);
  };
  const handleSWLogsDialogClose = () => {
    setSWLogsDialogData(null);
    setSWChangeLogsList([]);

    setOpenSWModuleChangeLogsDialog(false);
  };

  /* -------------- useEffects -------------- */
  useEffect(() => {
    console.log(
      "selectedProjectDetails?.vehicle_function_details",
      selectedProjectDetails?.vehicle_function_details
    );
    console.log("selectedProjectDetails----: ", selectedProjectDetails);
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.vehicle_function_details
    ) {
      setVfOptionsList(selectedProjectDetails?.vehicle_function_details || []);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    customConsole(
      "selectedSWModuleUpdateDetails: " + selectedSWModuleUpdateDetails
    );
    customConsole(
      "selectedSWModuleReplaceDetails: " + selectedSWModuleReplaceDetails
    );
  }, [selectedSWModuleUpdateDetails, selectedSWModuleReplaceDetails]);

  // const handleMoreClick = (e, itemId) => {
  //   e.stopPropagation(); // Prevent click event from bubbling up
  //   setActiveItemId(activeItemId === itemId ? null : itemId); // Toggle visibility
  // };

  const handleCloseActions = () => {
    setActiveItemId(null); // Close all actions
  };

  // Modify the dialog open handler to set the selected software module
  const handleDialogOpen = (swm) => {
    setSelectedSWM(swm); // Store the selected module
    setDialogOpen(true); // Open the dialog
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setReason(""); // Reset reason
  };

  const handleSubmit = () => {
    const reqObj = {
      project_id: Number(selectedProjectDetails?.project_id),
      variant_id: Number(selectedSingleVariantDetails?.variant_id),
      ee_id: Number(selectedEEComponentDetails?.ee_id),
      swm_id: Number(selectedSWM?.swm_id),
      request_reason: reason,
    };

    dispatch(
      HandleApiActions({
        ...editRequestAPI.create_edit_requests,
        params: reqObj,
        show_toast: true,
      })
    );
    // Reset form fields
    setReason("");
    handleDialogClose();
  };

  const handleActionType3DialogOpen = (swm) => {
    setSelectedSWM(swm);
    setPendingDialogOpen(true);
  };

  const handlePendingDialogClose = () => {
    setPendingDialogOpen(false);
  };

  const pendingActionRequired = async () => {
    // Create the request object
    const reqObj = {
      current_time: new Date().toISOString(),
      project_id: Number(selectedProjectDetails?.project_id),
      variant_id: Number(selectedSingleVariantDetails?.variant_id),
      ee_id: Number(selectedEEComponentDetails?.ee_id),
      swm_id: Number(selectedSWM?.swm_id),
      is_dependency_linking: true,
    };
    try {
      // Dispatch the update action type API action
      const response = await dispatch(
        HandleApiActions({
          ...labCar.update_action_type,
          params: reqObj,
          show_toast: true,
        })
      );
      getSWModulesList();
      // Handle successful edit
      if (!response.error) {
        handlePendingDialogClose();
      }
    } catch (error) {
      console.error("Failed to update action type", error);
    }
  };

  const handleMoreClick = (e, item) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setActiveItemId(item.swm_id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setActiveItemId(null);
  };

  const handleRequestsDialogOpen = (item) => {
    setSelectedSWM(item);
    setRequestsDialogOpen(true);
    getRequests(item);
  };

  const handleRequestsDialogClose = () => {
    setRequestsDialogOpen(false);
  };

  const handleMenuAction = (action, item) => {
    handleCloseMenu(); // Close the menu after action
    if (action === "update") {
      handleSelectedSWModuleUpdateDetails(item);
    } else if (action === "replace") {
      handleSelectedSWModuleReplaceDetails(item);
    } else if (action === "logs") {
      handleSWLogsDialogOpen(item);
    } else if (action === "requests") {
      handleRequestsDialogOpen(item);
    }
  };

  const getRequests = async (item) => {
    let apiDetails = editRequestAPI?.get_edit_requests;
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectDetails?.project_id),
          variant_id: Number(selectedSingleVariantDetails?.variant_id),
          ee_id: Number(selectedEEComponentDetails?.ee_id),
          swm_id: item?.swm_id,
          is_sent_requests: true,
          page_number: 1,
          page_size: 25,
        },
        show_toast: false,
      })
    );
    if (resp.error) {
      setGetRequestData([]);
    } else {
      setGetRequestData(resp.data);
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 100:
        return "Pending";
      case 200:
        return "Rejected";
      case 300:
        return "Approved";
      default:
        return "Unknown Status";
    }
  };

  const handleActionType1DialogOpen = (swm) => {
    setSelectedSWM(swm);
    setActionType1DialogOpen(true);
  };

  const handleActionType2DialogOpen = (swm) => {
    setSelectedSWM(swm);
    setActionType2DialogOpen(true);
  };

  const handleActionType1DialogClose = () => {
    setActionType1DialogOpen(false);
  };

  const handleActionType2DialogClose = () => {
    setActionType2DialogOpen(false);
  };

  const zones = [
    "CENTRAL LEFT",
    "CENTRAL RIGHT",
    "FRONT",
    "REAR",
    "FRONT LEFT",
    "FRONT RIGHT",
    "REAR LEFT",
    "REAR RIGHT",
  ];

  const zoneMapping = {
    "CENTRAL LEFT": 0,
    "CENTRAL RIGHT": 1,
    FRONT: 2,
    REAR: 3,
    "FRONT LEFT": 4,
    "FRONT RIGHT": 5,
    "REAR LEFT": 6,
    "REAR RIGHT": 7,
  };

  // Log the zone_position_name
  console.log("zone_position_name", selectedZone?.zone_position_name);

  // Use the mapping to get the index
  const selectedZoneIndex = zoneMapping[selectedZone?.zone_position_name];
  const selectedZoneName =
    selectedZoneIndex !== undefined ? zones[selectedZoneIndex] : null;

  console.log("selectedZoneIndex", selectedZoneIndex); // Check the index
  console.log("selectedZoneName", selectedZoneName); // Check the resulting name

  return (
    <Box>
      <Box>
        {/* Software module card */}
        <Card sx={styles.card}>
          <CardHeader
            sx={styles.cardHeader}
            title={
              <>
                <Typography
                  textAlign={"start"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {heading}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    textAlign={"start"}
                    fontSize="13px"
                    alignContent={"center"}
                    color="textSecondary"
                  >
                    {"Software Modules"}
                  </Typography>
                  <InfoButton infoKey={"software_module"} color={"grey"} />
                </Box>
              </>
            }
            action={
              <Box
                sx={{
                  pt: 1.5,
                }}
              >
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  startIcon={<AddIcon />}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => setOpenSWModuleAddDialog(true)}
                >
                  Add
                </Button>
              </Box>
            }
          />
          {/* Software modules list */}
          <CardContent sx={styles.cardContent}>
            {sWModulesList && sWModulesList?.length > 0 ? (
              sWModulesList?.map((item, index) => (
                <Box key={index} sx={styles.cardItems}>
                  <Box sx={styles.cardItemsTxtContainer}>
                    <Typography variant="body2">
                      <strong>Name:</strong> {item?.name}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Description:</strong> {item?.description}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Version:</strong> {item?.version}
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      key={item?.swm_id}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* More */}
                      <Tooltip title="More">
                        <IconButton
                          size="small"
                          sx={{ color: "green" }}
                          onClick={(e) => handleMoreClick(e, item)}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      {/* Lock/UnLock Icons */}
                      {item?.is_locked ? (
                        <Tooltip title="Lock">
                          <IconButton
                            size="small"
                            sx={{ color: "green" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLockUnLockSWM({
                                swmId: item?.swm_id,
                                isLocked: false,
                              });
                            }}
                          >
                            <LockIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="UnLock">
                          <IconButton
                            size="small"
                            sx={{ color: "green" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLockUnLockSWM({
                                swmId: item?.swm_id,
                                isLocked: true,
                              });
                            }}
                          >
                            <NoEncryptionGmailerrorredIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {/* Conditional render based on edit permission */}
                      {item.is_edit_enabled ||
                      selectedProjectDetails?.is_user_project_owner ? (
                        <>
                          {item.action_type === 0 ? undefined : (
                            <Tooltip
                              title={
                                item.action_type === 1
                                  ? "Action required in SWM to SWM linking"
                                  : item.action_type === 2
                                  ? "Action required in SWM to Legal requirements"
                                  : item.action_type === 3
                                  ? "Action required in this Software module"
                                  : ""
                              }
                            >
                              <IconButton
                                size="small"
                                sx={{ color: "red" }}
                                onClick={() => {
                                  item.action_type === 1
                                    ? handleActionType1DialogOpen(item)
                                    : item.action_type === 2
                                    ? handleActionType2DialogOpen(item)
                                    : handleActionType3DialogOpen(item);
                                }}
                              >
                                <ErrorOutlineIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <>
                          <Tooltip title="Edit action required">
                            <IconButton
                              size="small"
                              sx={{ color: "blue" }}
                              onClick={() => handleDialogOpen(item)}
                            >
                              <EditNoteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                          {item.action_type === 0 ? undefined : (
                            <Tooltip
                              title={
                                item.action_type === 1
                                  ? "Action required in SWM to SWM linking"
                                  : item.action_type === 2
                                  ? "Action required in SWM to Legal requirements"
                                  : item.action_type === 3
                                  ? "Action required in this Software module"
                                  : ""
                              }
                            >
                              <IconButton
                                size="small"
                                sx={{ color: "red" }}
                                onClick={() => {
                                  item.action_type === 1
                                    ? handleActionType1DialogOpen(item)
                                    : item.action_type === 2
                                    ? handleActionType2DialogOpen(item)
                                    : handleActionType3DialogOpen(item);
                                }}
                              >
                                <ErrorOutlineIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                      {/* Menu with conditional editing options */}
                      <Menu
                        anchorEl={anchorEl}
                        open={
                          Boolean(anchorEl) && activeItemId === item?.swm_id
                        }
                        onClose={handleCloseMenu}
                      >
                        {!item?.is_locked &&
                        (item.is_edit_enabled ||
                          selectedProjectDetails?.is_user_project_owner) ? (
                          <>
                            <MenuItem
                              onClick={() => handleMenuAction("update", item)}
                            >
                              <IconButton size="small" sx={{ color: "green" }}>
                                <EditIcon fontSize="small" />
                              </IconButton>{" "}
                              Update{" "}
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleMenuAction("replace", item)}
                            >
                              <IconButton size="small" sx={{ color: "green" }}>
                                <TbReplace sx={{ fontSize: 20 }} />
                              </IconButton>{" "}
                              Replace{" "}
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            <MenuItem disabled>
                              <IconButton size="small" sx={{ color: "grey" }}>
                                <EditIcon fontSize="small" />
                              </IconButton>{" "}
                              Update{" "}
                            </MenuItem>
                            <MenuItem disabled>
                              <IconButton size="small" sx={{ color: "grey" }}>
                                <TbReplace sx={{ fontSize: 20 }} />
                              </IconButton>{" "}
                              Replace{" "}
                            </MenuItem>
                          </>
                        )}
                        <MenuItem
                          onClick={() => handleMenuAction("logs", item)}
                        >
                          <IconButton size="small" sx={{ color: "green" }}>
                            <HistoryEduIcon fontSize="small" />
                          </IconButton>{" "}
                          Logs{" "}
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleMenuAction("requests", item)}
                        >
                          <IconButton size="small" sx={{ color: "green" }}>
                            <PendingIcon fontSize="small" />
                          </IconButton>{" "}
                          Requests{" "}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body1" sx={styles.noDataAvailableTxt}>
                No data available
              </Typography>
            )}
          </CardContent>
          <CardActions sx={styles.cardActions}>
            {/* <Button
              startIcon={<CloseIcon />}
              color="error"
              size="small"
              variant="contained"
              onClick={() => {
                setIsEEComponentSelected(false);
                setSelectedEEComponentDetails(null);
              }}
              sx={{ ...appTheme.commonBtnStyle }}
            >
              Close
            </Button> */}
          </CardActions>
        </Card>
      </Box>

      <CustomConfirmationDialog
        open={openLockUnLockSWMDialog}
        onClose={handleCloseLockUnLockSWM}
        dialogTitle={`Reason to ${
          lockUnLockSWMData?.isLocked ? "Lock" : "UnLock"
        } SWM`}
        dialogContent={
          <Box sx={{ mt: 1.4 }}>
            <CustomTextFieldN
              size={"small"}
              placeholder={"Reason"}
              type={"text"}
              value={lockUnLockSWMReason}
              setValue={setLockUnLockSWMReason}
              variant={"standard"}
            />
          </Box>
        }
        onConfirm={handleConfirmLockUnLockSWM}
      />

      <VlcSWModuleAddDialog
        openSWModuleAddDialog={openSWModuleAddDialog}
        setOpenSWModuleAddDialog={setOpenSWModuleAddDialog}
        handleSWModuleAdd={handleSWModuleAdd}
        vfOptionsList={vfOptionsList}
        selectedSingleVariantDetails={selectedSingleVariantDetails}
      />

      <VlcSWModuleUpdateDialog
        openSWModuleUpdateDialog={openSWModuleUpdateDialog}
        setOpenSWModuleUpdateDialog={setOpenSWModuleUpdateDialog}
        handleSWModuleUpdate={handleSWModuleUpdate}
        selectedSWModuleUpdateDetails={selectedSWModuleUpdateDetails}
        setSelectedSWModuleUpdateDetails={setSelectedSWModuleUpdateDetails}
        setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
        selectedZone={selectedZone}
        selectedEEComponentDetails={selectedEEComponentDetails}
        selectedSWM={selectedSWM}
        getSWModulesList={getSWModulesList}
        selectedProjectDetails={selectedProjectDetails}
        selectedSingleVariantDetails={selectedSingleVariantDetails}
      />

      <VlcSWModuleReplaceDialog
        openSWModuleReplaceDialog={openSWModuleReplaceDialog}
        setOpenSWModuleReplaceDialog={setOpenSWModuleReplaceDialog}
        handleSWModuleReplace={handleSWModuleReplace}
        selectedSWModuleReplaceDetails={selectedSWModuleReplaceDetails}
        setSelectedSWModuleReplaceDetails={setSelectedSWModuleReplaceDetails}
        vfOptionsList={vfOptionsList}
        setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
        selectedZone={selectedZone}
        selectedEEComponentDetails={selectedEEComponentDetails}
        getSWModulesList={getSWModulesList}
        selectedProjectDetails={selectedProjectDetails}
        selectedSingleVariantDetails={selectedSingleVariantDetails}
      />

      <CustomLogsDialog
        open={openSWModuleChangeLogsDialog}
        handleClose={handleSWLogsDialogClose}
        dialogTitle={"Change Logs"}
        dialogContent={swChangeLogsList}
        data={swLogsDialogData}
        getLogsData={handleGetSWModuleChangeLogs}
        setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
      />

      <Box>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle>Edit Access Required</DialogTitle>
          <DialogContent>
            <Typography mb={2}>
              Request access to update/replace the software module
            </Typography>
            <CustomTextFieldN
              size={"small"}
              label={"Request"}
              placeholder={"Reason for request"}
              type={"text"}
              fldType={"text5"}
              value={reason}
              setValue={setReason}
              valueError={reasonError}
              setValueError={setReasonError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              sx={{ ...appTheme.commonBtnStyle }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              sx={{ ...appTheme.commonBtnStyle }}
              disabled={reason.length < 5}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Requests Dialog */}
        <Dialog
          open={requestsDialogOpen}
          onClose={handleRequestsDialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle>Edit Requests</DialogTitle>
          <DialogContent>
            {getRequestsData.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>S.No</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Reason</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Approver</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Created At</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Updated At</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getRequestsData?.map((request, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{request?.request_reason}</TableCell>
                        <TableCell>{getStatusLabel(request?.status)}</TableCell>
                        <TableCell>
                          {request?.approved_user?.fullname}
                        </TableCell>
                        <TableCell>
                          {new Date(request?.created_at).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {new Date(request?.updated_at).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>No requests available.</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleRequestsDialogClose}
              sx={{ ...appTheme.commonBtnStyle }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Action Pending Dialog */}
        <Dialog
          open={pendingDialogOpen}
          onClose={handlePendingDialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                EE Component has been updated/replaced. Will it affect this
                module?
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handlePendingDialogClose}
                >
                  Yes
                </Button>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={pendingActionRequired}
                >
                  No
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Action Pending Dialog */}
        <Dialog
          open={actionType1DialogOpen}
          onClose={handleActionType1DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Software module dependency linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/labcar?swm_id=${selectedSWM?.swm_id}&ee_id=${selectedEEComponentDetails?.ee_id}&selectedZone=${selectedZone?.zone_position_name}&linkModuleType=${linkModuleType?.SWM}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={() => {
                      setIsLinkSoftwareModulesScreen(true);
                    }}
                  >
                    Link Now
                  </Button>
                </Link>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType1DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Action Pending Dialog */}
        <Dialog
          open={actionType2DialogOpen}
          onClose={handleActionType2DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Software module intersection linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/type-approval?project_id=${selectedProjectDetails?.project_id}&ee_id=${selectedEEComponentDetails?.ee_id}&selectedZone=${selectedZone?.zone_position_name}&swm_id=${selectedSWM?.swm_id}&linkModuleType=${linkModuleType?.SWM}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={handleActionType2DialogClose}
                  >
                    Link Now
                  </Button>
                </Link>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType2DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

export default VlcSWModules;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : Software module add dialog.
 * @param : openSWModuleAddDialog, setOpenSWModuleAddDialog, handleSWModuleAdd, vfOptionsList,
 * @return : The rendered software module add dialog.
 */
function VlcSWModuleAddDialog({
  openSWModuleAddDialog,
  setOpenSWModuleAddDialog,
  handleSWModuleAdd,
  vfOptionsList,
}) {
  /* Add SW Modules Fields */
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [selectedVfIds, setSelectedVFIds] = useState([]);

  /* -------------- UseEffects -------------- */

  /* -------------- Add SW Modules -------------- */

  const clearAddSWModuleFields = () => {
    setName("");
    setNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSummaryError("");
    setSelectedVFIds([]);
  };

  const isAddSWModuleValid = () => {
    return (
      name?.trim() !== "" &&
      !nameError &&
      version?.trim() !== "" &&
      !versionError &&
      description?.trim() !== "" &&
      !descriptionError &&
      selectedVfIds?.length > 0 &&
      (summary?.trim()?.length === 0 || !summaryError)
    );
  };

  const handleCloseSWModuleAdd = () => {
    setOpenSWModuleAddDialog(false);

    clearAddSWModuleFields();
  };

  const handleAddSWModule = () => {
    handleSWModuleAdd({
      version: version,
      name: name,
      summary: summary,
      description: description,
      vf_ids: selectedVfIds?.map(Number) || [],
    }).then((res) => {
      if (res) {
        handleCloseSWModuleAdd();
      }
    });
  };

  useEffect(() => {
    customConsole({ selectedVfIds, vfOptionsList });
  }, [selectedVfIds, vfOptionsList]);

  return (
    <CustomAddDialog
      open={openSWModuleAddDialog}
      onClose={handleCloseSWModuleAdd}
      dialogTitle={"Add SW Module"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Name*"}
            placeholder={"Name*"}
            type={"text"}
            fldType={"text"}
            value={name}
            setValue={setName}
            valueError={nameError}
            setValueError={setNameError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={styles.customTextFieldN}
          />
          <MultiSelectTextFieldN
            required
            mutiple
            size={"small"}
            label={"Select Vehicle Functions*"}
            optionsList={vfOptionsList}
            selectedValues={selectedVfIds}
            setSelectedValues={setSelectedVFIds}
            sltFldStyle={styles.customTextFieldN}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddSWModule({});
        // clearAddSWModuleFields();
      }}
      onDisabled={!isAddSWModuleValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : Software module update dialog.
 * @param : openSWModuleUpdateDialog, setOpenSWModuleUpdateDialog, handleSWModuleUpdate, selectedSWModuleUpdateDetails, setSelectedSWModuleUpdateDetails,
 * @return : The rendered software module update dialog.
 */
function VlcSWModuleUpdateDialog({
  openSWModuleUpdateDialog,
  setOpenSWModuleUpdateDialog,
  handleSWModuleUpdate,
  selectedSWModuleUpdateDetails,
  setSelectedSWModuleUpdateDetails,
  setIsLinkSoftwareModulesScreen,
  selectedZone,
  selectedEEComponentDetails,
  getSWModulesList,
  selectedProjectDetails,
  selectedSingleVariantDetails,
}) {
  /* Update SW Modules Fields */
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false); // State to track confirmation dialog
  const linkModuleType = { SWM: 1, EE: 2 };
  const [discp, setDiscp] = useState("");
  const [discpError, setDiscpError] = useState("");
  const [actionType1DialogOpen, setActionType1DialogOpen] = useState(false);
  const [selectedSWM, setSelectedSWM] = useState(null);
  const [ticketsDialogOpen, setTicketsDialogOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (openSWModuleUpdateDialog == true && selectedSWModuleUpdateDetails) {
      setVersion(selectedSWModuleUpdateDetails?.version || "");
    }
  }, [openSWModuleUpdateDialog, selectedSWModuleUpdateDetails]);

  /* -------------- Update SW Module -------------- */

  const clearUpdateSWModuleFields = () => {
    setVersion("");
    setVersionError("");
    setDiscp("");
    setDiscpError("");
  };

  const isUpdateSWModuleValid = () => {
    return (
      !versionError &&
      version?.trim() !== selectedSWModuleUpdateDetails?.version &&
      discp?.trim().length > 0
    );
  };

  const handleCloseSWModuleUpdate = () => {
    setOpenSWModuleUpdateDialog(false);

    clearUpdateSWModuleFields();

    setSelectedSWModuleUpdateDetails(null);
    setShowConfirmation(false); // Reset confirmation on close
  };

  const handleUpdateClick = () => {
    setShowConfirmation(true); // Show confirmation dialog when update is clicked
  };

  const handleUpdateSWModule = ({}) => {
    if (
      selectedSWModuleUpdateDetails &&
      selectedSWModuleUpdateDetails?.swm_id
    ) {
      handleSWModuleUpdate({
        swm_id: Number(selectedSWModuleUpdateDetails?.swm_id),
        version: version,
      });

      handleCloseSWModuleUpdate();
    }
  };

  const confirmUpdateAndRedirect = async (selectedSWModuleUpdateDetails) => {
    const reqObj = {
      current_time: new Date().toISOString(),
      project_id: Number(selectedSWModuleUpdateDetails?.project_id),
      variant_id: Number(selectedSingleVariantDetails?.variant_id),
      ee_id: Number(selectedSWModuleUpdateDetails?.ee_id),
      swm_id: Number(selectedSWModuleUpdateDetails?.swm_id),
      version: version,
      will_it_impact: true,
      reason: discp,
    };
    try {
      // Dispatch the update action type API action
      const response = await dispatch(
        HandleApiActions({
          ...labCar.update_sw_module,
          params: reqObj,
          show_toast: true,
        })
      );
      getSWModulesList();
      if (!response.error) {
        handleCloseSWModuleUpdate();
        handleActionType1DialogOpen(selectedSWModuleUpdateDetails);
      }
    } catch (error) {
      console.error("Failed to update", error);
    }
  };

  const updateChangesAndClose = async () => {
    const reqObj = {
      current_time: new Date().toISOString(),
      project_id: Number(selectedSWModuleUpdateDetails?.project_id),
      variant_id: Number(selectedSingleVariantDetails?.variant_id),
      ee_id: Number(selectedSWModuleUpdateDetails?.ee_id),
      swm_id: Number(selectedSWModuleUpdateDetails?.swm_id),
      version: version,
      will_it_impact: false,
      reason: discp,
    };
    try {
      const response = await dispatch(
        HandleApiActions({
          ...labCar.update_sw_module,
          params: reqObj,
          show_toast: true,
        })
      );
      getSWModulesList();
      if (!response.error) {
        handleCloseSWModuleUpdate();
      }
      if (response?.data?.snow_resp) {
        setTicketsDialogOpen(true);
      }
    } catch (error) {
      console.error("Failed to update", error);
    }
  };

  const handleActionType1DialogOpen = () => {
    setSelectedSWM(selectedSWModuleUpdateDetails);
    setActionType1DialogOpen(true);
  };
  const handleActionType1DialogClose = () => {
    setActionType1DialogOpen(false);
  };

  const handleTicketsDialogClose = () => {
    setTicketsDialogOpen(false);
  };

  return (
    <Box>
      <CustomUpdateDialog
        open={openSWModuleUpdateDialog}
        onClose={handleCloseSWModuleUpdate}
        dialogTitle={"Update SW Module"}
        dialogContent={
          <Stack spacing={2} mb={2}>
            <CustomTextFieldN
              size={"small"}
              label={"Version*"}
              placeholder={"Version*"}
              type={"text"}
              fldType={"textR"}
              value={version}
              setValue={setVersion}
              valueError={versionError}
              setValueError={setVersionError}
              variant={"standard"}
              txtFldStyle={styles.customTextFieldN}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Reason*"}
              placeholder={"Reason to update*"}
              type={"text"}
              fldType={"textO"}
              value={discp}
              setValue={setDiscp}
              valueError={discpError}
              setValueError={setDiscpError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            {showConfirmation && (
              <Stack spacing={2}>
                <Typography variant="body1">
                  Will it impact the existing dependencies?
                </Typography>
                <Stack direction="row" spacing={2} justifyContent={"center"}>
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={() =>
                      confirmUpdateAndRedirect(selectedSWModuleUpdateDetails)
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={updateChangesAndClose}
                  >
                    No
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        }
        onConfirm={handleUpdateClick}
        onDisabled={!isUpdateSWModuleValid()}
      />

      <Box>
        {/* Action Pending Dialog */}
        <Dialog
          open={actionType1DialogOpen}
          onClose={handleActionType1DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Software module dependency linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/labcar?swm_id=${selectedSWM?.swm_id}&ee_id=${selectedEEComponentDetails?.ee_id}&selectedZone=${selectedZone?.zone_position_name}&linkModuleType=${linkModuleType?.SWM}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={() => setIsLinkSoftwareModulesScreen(true)}
                  >
                    Link Now
                  </Button>
                </Link>

                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType1DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Handle Ticket Page Dialog */}
        <Dialog
          open={ticketsDialogOpen}
          onClose={handleTicketsDialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Tickets Recreated</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Successfully created the tickets for the intersections!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/type-approval?project_id=${
                    selectedProjectDetails?.project_id
                  }&setTabValue=${2}&varint_id=${
                    selectedSingleVariantDetails?.variant_id
                  }`}
                >
                  <Button sx={{ ...appTheme.commonBtnStyle }}>View</Button>
                </Link>

                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleTicketsDialogClose}
                >
                  Close
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : Software module replace dialog.
 * @param : openSWModuleReplaceDialog, setOpenSWModuleReplaceDialog, handleSWModuleReplace, selectedSWModuleReplaceDetails, setSelectedSWModuleReplaceDetails, vfOptionsList,
 * @return : The rendered software module replace dialog.
 */
function VlcSWModuleReplaceDialog({
  openSWModuleReplaceDialog,
  setOpenSWModuleReplaceDialog,
  handleSWModuleReplace,
  selectedSWModuleReplaceDetails,
  setSelectedSWModuleReplaceDetails,
  vfOptionsList,
  setIsLinkSoftwareModulesScreen,
  selectedZone,
  selectedEEComponentDetails,
  getSWModulesList,
  selectedProjectDetails,
  selectedSingleVariantDetails,
}) {
  /* Replace SW Modules Fields */
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [selectedVfIds, setSelectedVfIds] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false); // State to track confirmation dialog
  const linkModuleType = { SWM: 1, EE: 2 };
  const [discp, setDiscp] = useState("");
  const [discpError, setDiscpError] = useState("");
  const [selectedSWM, setSelectedSWM] = useState(null);

  const [actionType1DialogOpen, setActionType1DialogOpen] = useState(false);
  const [ticketsDialogOpen, setTicketsDialogOpen] = useState(false);

  const dispatch = useDispatch();

  /* -------------- UseEffects -------------- */

  useEffect(() => {
    if (openSWModuleReplaceDialog == true && selectedSWModuleReplaceDetails) {
      setName(selectedSWModuleReplaceDetails?.name || "");
      setVersion(selectedSWModuleReplaceDetails?.version || "");
      setDescription(selectedSWModuleReplaceDetails?.description || "");
      setSummary(selectedSWModuleReplaceDetails?.summary || "");
      setSelectedVfIds(
        (selectedSWModuleReplaceDetails?.vf_ids || [])
          ?.map((vfId) => Number(vfId))
          ?.filter((vfId) => !isNaN(vfId))
      );
      setDiscp(selectedSWModuleReplaceDetails?.reason || "");
    }
  }, [openSWModuleReplaceDialog, selectedSWModuleReplaceDetails]);

  /* -------------- Replace SW Module -------------- */

  const clearReplaceSWModuleFields = () => {
    setName("");
    setNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSummaryError("");
    setSelectedVfIds([]);
    setDiscp("");
    setDiscpError("");
  };

  const isReplaceSWModuleValid = () => {
    return (
      !nameError &&
      !versionError &&
      !descriptionError &&
      selectedVfIds?.length > 0 &&
      discp?.trim().length > 0 &&
      (name?.trim() !== selectedSWModuleReplaceDetails?.name ||
        version?.trim() !== selectedSWModuleReplaceDetails?.version ||
        description?.trim() !== selectedSWModuleReplaceDetails?.description ||
        summary?.trim() !== selectedSWModuleReplaceDetails?.summary ||
        selectedVfIds?.length !== selectedSWModuleReplaceDetails?.vf_ids.length)
    );
  };

  const handleCloseSWModuleReplace = () => {
    setOpenSWModuleReplaceDialog(false);

    clearReplaceSWModuleFields();

    setSelectedSWModuleReplaceDetails(null);
    setShowConfirmation(false); // Reset confirmation on close
  };

  const handleReplaceClick = () => {
    setShowConfirmation(true); // Show confirmation dialog when update is clicked
  };

  const confirmReplaceAndRedirect = async (selectedSWModuleReplaceDetails) => {
    if (
      selectedSWModuleReplaceDetails &&
      selectedSWModuleReplaceDetails?.swm_id
    ) {
      const reqObj = {
        current_time: new Date().toISOString(),
        project_id: Number(selectedSWModuleReplaceDetails?.project_id),
        variant_id: Number(selectedSingleVariantDetails?.variant_id),
        ee_id: Number(selectedSWModuleReplaceDetails?.ee_id),
        swm_id: Number(selectedSWModuleReplaceDetails?.swm_id),
        version: version,
        name: name,
        ...(summary && { summary }),
        description: description,
        vf_ids: selectedVfIds,
        will_it_impact: true,
        reason: discp,
      };
      try {
        const response = await dispatch(
          HandleApiActions({
            ...labCar.replace_sw_module,
            params: reqObj,
            show_toast: true,
          })
        );
        getSWModulesList();
        if (!response.error) {
          handleCloseSWModuleReplace();
          handleActionType1Dialogpen(selectedSWModuleReplaceDetails);
        }
      } catch (error) {
        console.error("Failed to replace", error);
      }
    }
  };

  const replaceChangesAndClose = async () => {
    if (
      selectedSWModuleReplaceDetails &&
      selectedSWModuleReplaceDetails?.swm_id
    ) {
      const reqObj = {
        current_time: new Date().toISOString(),
        project_id: Number(selectedSWModuleReplaceDetails?.project_id),
        variant_id: Number(selectedSingleVariantDetails?.variant_id),
        ee_id: Number(selectedSWModuleReplaceDetails?.ee_id),
        swm_id: Number(selectedSWModuleReplaceDetails?.swm_id),
        version: version,
        name: name,
        ...(summary && { summary }),
        description: description,
        vf_ids: selectedVfIds,
        will_it_impact: false,
        reason: discp,
      };
      try {
        const response = await dispatch(
          HandleApiActions({
            ...labCar.replace_sw_module,
            params: reqObj,
            show_toast: true,
          })
        );
        getSWModulesList();
        if (!response.error) {
          handleCloseSWModuleReplace();
        }
        if (response?.data?.snow_resp) {
          setTicketsDialogOpen(true);
        }
      } catch (error) {
        console.error("Failed to replace", error);
      }
    }
  };

  const handleReplaceSWModule = ({}) => {
    if (
      selectedSWModuleReplaceDetails &&
      selectedSWModuleReplaceDetails?.swm_id
    ) {
      handleSWModuleReplace({
        swm_id: Number(selectedSWModuleReplaceDetails?.swm_id),
        version: version,
        name: name,
        summary: summary,
        description: description,
        vf_ids: selectedVfIds?.map(Number) || [],
      });

      handleCloseSWModuleReplace();
    }
  };

  const handleActionType1Dialogpen = (selectedSWModuleReplaceDetails) => {
    setSelectedSWM(selectedSWModuleReplaceDetails);
    setActionType1DialogOpen(true);
  };
  const handleActionType1DialogClose = () => {
    setActionType1DialogOpen(false);
  };

  const handleTicketsDialogClose = () => {
    setTicketsDialogOpen(false);
  };

  return (
    <Box>
      <CustomReplaceDialog
        open={openSWModuleReplaceDialog}
        onClose={handleCloseSWModuleReplace}
        dialogTitle={"Replace SW Module"}
        dialogContent={
          <Stack spacing={2} mb={2}>
            <CustomTextFieldN
              size={"small"}
              label={"Name*"}
              placeholder={"Name*"}
              type={"text"}
              fldType={"text"}
              value={name}
              setValue={setName}
              valueError={nameError}
              setValueError={setNameError}
              variant={"standard"}
              txtFldStyle={styles.customTextFieldN}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Version*"}
              placeholder={"Version*"}
              type={"text"}
              fldType={"textR"}
              value={version}
              setValue={setVersion}
              valueError={versionError}
              setValueError={setVersionError}
              variant={"standard"}
              txtFldStyle={styles.customTextFieldN}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Description*"}
              placeholder={"Description*"}
              type={"text"}
              fldType={"text"}
              value={description}
              setValue={setDescription}
              valueError={descriptionError}
              setValueError={setDescriptionError}
              variant={"standard"}
              txtFldStyle={styles.customTextFieldN}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Summary"}
              placeholder={"Summary"}
              type={"text"}
              // fldType={"text"}
              value={summary}
              setValue={setSummary}
              // valueError={summaryError}
              // setValueError={setSummaryError}
              variant={"standard"}
              txtFldStyle={styles.customTextFieldN}
            />
            <MultiSelectTextFieldN
              required
              mutiple
              size={"small"}
              label={"Select Vehicle Functions"}
              optionsList={vfOptionsList}
              selectedValues={selectedVfIds}
              setSelectedValues={setSelectedVfIds}
              sltFldStyle={styles.customTextFieldN}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Reason*"}
              placeholder={"Reason to update*"}
              type={"text"}
              fldType={"textO"}
              value={discp}
              setValue={setDiscp}
              valueError={discpError}
              setValueError={setDiscpError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            {showConfirmation && (
              <Stack spacing={2}>
                <Typography variant="body1">
                  Will it impact the existing dependencies?
                </Typography>
                <Stack direction="row" spacing={2} justifyContent={"center"}>
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={() =>
                      confirmReplaceAndRedirect(selectedSWModuleReplaceDetails)
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={replaceChangesAndClose}
                  >
                    No
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        }
        onConfirm={handleReplaceClick}
        onDisabled={!isReplaceSWModuleValid()}
      />
      <Box>
        {/* Action Pending Dialog */}
        <Dialog
          open={actionType1DialogOpen}
          onClose={handleActionType1DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Software module dependency linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/labcar?swm_id=${selectedSWM?.swm_id}&ee_id=${selectedEEComponentDetails?.ee_id}&selectedZone=${selectedZone?.zone_position_name}&linkModuleType=${linkModuleType?.SWM}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={() => setIsLinkSoftwareModulesScreen(true)}
                  >
                    Link Now
                  </Button>
                </Link>

                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType1DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Handle Ticket Page Dialog */}
        <Dialog
          open={ticketsDialogOpen}
          onClose={handleTicketsDialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Tickets Recreated</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Successfully created the tickets for the intersections!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/type-approval?project_id=${
                    selectedProjectDetails?.project_id
                  }&setTabValue=${2}&varint_id=${
                    selectedSingleVariantDetails?.variant_id
                  }`}
                >
                  <Button sx={{ ...appTheme.commonBtnStyle }}>View</Button>
                </Link>

                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleTicketsDialogClose}
                >
                  Close
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

// Styles
const styles = {
  card: {
    mt: 1.3,
    mx: 2,
    width: "370px",
    boxShadow: 12,
  },
  cardHeader: {
    py: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  cardContent: {
    height: "387px",
    overflowY: "auto",
  },
  cardItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
    border: "1px solid lightgrey",
    // cursor: "pointer",
    "&:hover": {
      // backgroundColor: "lightgrey",
    },
  },
  cardItemsTxtContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  noDataAvailableTxt: {
    textAlign: "center",
    mt: 2,
  },
  customTextFieldN: {
    width: "270px",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  edRpIconsContainer: {
    display: "flex",
    flexDirection: "column",
  },
};
