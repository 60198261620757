import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { CustomButton } from "../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
function Reports() {
  const formDetails = [
    { label: "Name Of the Form", value: "Form Name" },
    { label: "Project Name", value: "Project A" },
    { label: "Variant Name", value: "Variant X" },
    { label: "Ticket ID", value: "TKT-12345" },
  ];

  return (
    <div>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <CustomButton
            startIcon={<FilterAltIcon />}
            size={"medium"}
            variant={"contained"}
            btnName={"Filter"}
            // disabled={
            //   !(selectedProjectId && vehicleLRIntersectionsDetails?.length)
            // }
            //handleOnClick={handleLinkVehicleComponentsToLegalRequirements}
            //btnStyle={styles.customButton}
          />
          <Box sx={{ ml: "10px" }}>
            <CustomButton
              startIcon={<SwapVertIcon />}
              size={"small"}
              variant={"medium"}
              btnName={"Sort By"}
              // disabled={
              //   !(selectedProjectId && vehicleLRIntersectionsDetails?.length)
              // }
              //handleOnClick={handleLinkVehicleComponentsToLegalRequirements}
              //btnStyle={{ marginLeft: "10px" }}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box
              sx={{
                ml: "15px",
                mt: "10px",
                width: "250px",
                height: "300px",
                boxShadow: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#ffffff",
                borderRadius: "4px",
                border: "1px solid #dcdcdc",
                backgroundImage:
                  "linear-gradient(transparent 70%, #f0f0f0 50%)",
                backgroundSize: "100% 20px",
                backgroundPosition: "0 0",
              }}
            >
              <IconButton sx={{ position: "absolute" }}>
                <VisibilityIcon />
              </IconButton>
            </Box>

            <Box sx={{ padding: "10px", ml: "10px" }}>
              {formDetails.map((detail, index) => (
                <Typography key={index} fontWeight="bold">
                  {detail.label}
                  <strong>:</strong> {detail.value}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box
              sx={{
                ml: "15px",
                mt: "10px",
                width: "250px",
                height: "300px",
                boxShadow: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#ffffff",
                borderRadius: "4px",
                border: "1px solid #dcdcdc",
                backgroundImage:
                  "linear-gradient(transparent 70%, #f0f0f0 50%)",
                backgroundSize: "100% 20px",
                backgroundPosition: "0 0",
              }}
            >
              <IconButton sx={{ position: "absolute" }}>
                <VisibilityIcon />
              </IconButton>
            </Box>

            <Box sx={{ padding: "10px", ml: "10px" }}>
              {formDetails.map((detail, index) => (
                <Typography key={index} fontWeight="bold">
                  {detail.label}
                  <strong>:</strong> {detail.value}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box
              sx={{
                ml: "15px",
                mt: "10px",
                width: "250px",
                height: "300px",
                boxShadow: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#ffffff",
                borderRadius: "4px",
                border: "1px solid #dcdcdc",
                backgroundImage:
                  "linear-gradient(transparent 70%, #f0f0f0 50%)",
                backgroundSize: "100% 20px",
                backgroundPosition: "0 0",
              }}
            >
              <IconButton sx={{ position: "absolute" }}>
                <VisibilityIcon />
              </IconButton>
            </Box>

            <Box sx={{ padding: "10px", ml: "10px" }}>
              {formDetails.map((detail, index) => (
                <Typography key={index} fontWeight="bold">
                  {detail.label}
                  <strong>:</strong> {detail.value}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box
              sx={{
                ml: "15px",
                mt: "10px",
                width: "250px",
                height: "300px",
                boxShadow: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#ffffff",
                borderRadius: "4px",
                border: "1px solid #dcdcdc",
                backgroundImage:
                  "linear-gradient(transparent 70%, #f0f0f0 50%)",
                backgroundSize: "100% 20px",
                backgroundPosition: "0 0",
              }}
            >
              <IconButton sx={{ position: "absolute" }}>
                <VisibilityIcon />
              </IconButton>
            </Box>

            <Box sx={{ padding: "10px", ml: "10px" }}>
              {formDetails.map((detail, index) => (
                <Typography key={index} fontWeight="bold">
                  {detail.label}
                  <strong>:</strong> {detail.value}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Reports;
