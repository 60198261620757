/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-09-2024
 * @description : Provides list of module to select
 */
import React from "react";
import SearchSelectTextField from "../text-fields/SearchSelectTextField";
import { Box, Grid, Typography } from "@mui/material";

function SelectModules({
  selectedProjectDetails,
  selectedPaBtn,
  moduleTypes,
  variantOptionsList,
  selectedVariant,
  setSelectedVariant,
  vehicleOptionsList,
  selectedVehicle,
  setSelectedVehicle,
  zoneOptionsList,
  selectedZone,
  setSelectedZone,
  eeOptionsList,
  selectedEE,
  setSelectedEE,
  swmOptionsList,
  selectedSWM,
  setSelectedSWM,
}) {
  return (
    <Grid container>
      {(selectedPaBtn === moduleTypes?.VARIANT ||
        selectedPaBtn === moduleTypes?.VEHICLE ||
        selectedPaBtn === moduleTypes?.ZONE ||
        selectedPaBtn === moduleTypes?.EE ||
        selectedPaBtn === moduleTypes?.SWM) && (
        <Grid item xs={12} md={6} lg={4} mt={2} px={1}>
          <Box display="flex" flexDirection="column" textAlign="start">
            <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
              <span style={styles.txtBold}>Select Variant: </span>
            </Typography>
            <SearchSelectTextField
              size={"small"}
              // label={"Search Variant"}
              // placeholder={"Search Variant"}
              noOptionsText={"No variants found"}
              optionsList={variantOptionsList}
              selectedValue={selectedVariant}
              setSelectedValue={setSelectedVariant}
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
        </Grid>
      )}
      {selectedPaBtn === moduleTypes?.VEHICLE && (
        <Grid item xs={12} md={6} lg={4} mt={2} px={1}>
          <Box display="flex" flexDirection="column" textAlign="start">
            <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
              <span style={styles.txtBold}>Select Vehicle: </span>
            </Typography>
            <SearchSelectTextField
              size={"small"}
              // label={"Search Vehicle"}
              // placeholder={"Search Vehicle"}
              noOptionsText={"No vehicles found"}
              optionsList={vehicleOptionsList}
              selectedValue={selectedVehicle}
              setSelectedValue={setSelectedVehicle}
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
        </Grid>
      )}
      {(selectedPaBtn === moduleTypes?.ZONE ||
        selectedPaBtn === moduleTypes?.EE ||
        selectedPaBtn === moduleTypes?.SWM) &&
        selectedProjectDetails?.is_zonal && (
          <Grid item xs={12} md={6} lg={4} mt={2} px={1}>
            <Box display="flex" flexDirection="column" textAlign="start">
              <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
                <span style={styles.txtBold}>Select Zone: </span>
              </Typography>
              <SearchSelectTextField
                size={"small"}
                // label={"Search Zone"}
                // placeholder={"Search Zone"}
                noOptionsText={"No zones found"}
                optionsList={zoneOptionsList}
                selectedValue={selectedZone}
                setSelectedValue={setSelectedZone}
                txtFldStyle={styles.searchSelectTextField}
              />
            </Box>
          </Grid>
        )}
      {(selectedPaBtn === moduleTypes?.EE ||
        selectedPaBtn === moduleTypes?.SWM) && (
        <Grid item xs={12} md={6} lg={4} mt={2} px={1}>
          <Box display="flex" flexDirection="column" textAlign="start">
            <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
              <span style={styles.txtBold}>Select EE: </span>
            </Typography>
            <SearchSelectTextField
              size={"small"}
              // label={"Search EE"}
              // placeholder={"Search EE"}
              noOptionsText={"No EE found"}
              optionsList={eeOptionsList}
              selectedValue={selectedEE}
              setSelectedValue={setSelectedEE}
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
        </Grid>
      )}
      {selectedPaBtn === moduleTypes?.SWM && (
        <Grid item xs={12} md={6} lg={4} mt={2} px={1}>
          <Box display="flex" flexDirection="column" textAlign="start">
            <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
              <span style={styles.txtBold}>Select SWM: </span>
            </Typography>
            <SearchSelectTextField
              size={"small"}
              // label={"Search SWM"}
              // placeholder={"Search SWM"}
              noOptionsText={"No SWM found"}
              optionsList={swmOptionsList}
              selectedValue={selectedSWM}
              setSelectedValue={setSelectedSWM}
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default SelectModules;

// Styles
const styles = {
  detailsContainer: {
    minWidth: "100%",
    border: "1px solid black",
    px: 2,
    pt: 0.4,
    pb: 2,
    my: 2,
  },
  nrDetailsTxt: {
    textAlign: "start",
  },
  txtBold: {
    fontWeight: "bold",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  divider: {
    mt: 0.4,
    mb: 1,
  },
  searchSelectTextField: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: "15px",
    },
  },
};
