/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Reusable text field component.
 */

import { Box, TextField } from "@mui/material";
import React from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Reusable text field component.
 * @param : size, label, placeholder, value, setValue, isMultiline = false, rows = 1, txtFldStyle
 * @return The rendered text field component.
 */
const CustomTextFieldN = ({
  size,
  label,
  type,
  fldType,
  placeholder,
  required,
  value,
  setValue,
  variant,
  valueError,
  setValueError,
  inputProps,
  disabled,
  isMultiline = false,
  minRows = 1,
  maxRows = 3,
  txtFldStyle,
}) => {
  // Handles change events for the text field.
  const handleChange = (e) => {
    const regexMin3 = /^(?=.*[a-zA-Z0-9._-])[a-zA-Z0-9._-]{3,}$/;
    const regexMin4 = /^(?=.*[a-zA-Z0-9._-])[a-zA-Z0-9._-]{4,}$/;

    let newValue = e.target.value;

    // Remove leading spaces
    newValue = newValue.replace(/^\s+/, "");
    // Remove more than one trailing space
    newValue = newValue.replace(/\s+$/, " ");

    switch (fldType) {
      case "text1":
        if (newValue?.trim()?.length < 1) {
          setValueError("Minimum 1 characters required");
        } else {
          setValueError("");
        }
        break;
      case "text":
        if (newValue?.trim()?.length < 3) {
          setValueError("Minimum 3 characters required");
        } else {
          setValueError("");
        }
        break;
      case "textR":
        newValue = newValue.replace(/\s+/g, "");
        if (newValue?.trim()?.length < 3) {
          setValueError("Minimum 3 characters required");
        } else if (!regexMin3.test(newValue)) {
          setValueError("Invalid format");
        } else {
          setValueError("");
        }
        break;
      case "textO":
        if (newValue?.trim()?.length > 0 && newValue?.trim()?.length < 3) {
          setValueError("Minimum 3 characters required");
        } else {
          setValueError("");
        }
        break;
      case "text4":
        if (newValue?.trim()?.length < 4) {
          setValueError("Minimum 4 characters required");
        } else {
          setValueError("");
        }
        break;
      case "text5":
        if (newValue?.trim()?.length < 5) {
          setValueError("Minimum 5 characters required");
        } else {
          setValueError("");
        }
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newValue)) {
          setValueError("Invalid email address");
        } else {
          setValueError("");
        }
        break;
      default:
        break;
    }

    setValue(newValue);
  };

  return (
    <Box>
      <TextField
        fullWidth
        size={size}
        label={label}
        type={type}
        error={Boolean(valueError)}
        helperText={valueError}
        variant={variant}
        placeholder={placeholder}
        value={value}
        InputProps={inputProps}
        onChange={handleChange}
        multiline={isMultiline}
        minRows={minRows}
        maxRows={maxRows}
        sx={txtFldStyle}
        disabled={disabled}
      />
    </Box>
  );
};

export default CustomTextFieldN;

// Styling for the CustomTextField component
const styles = {
  txtLbs: {
    textAlign: "start",
    mt: 1,
    mb: 0.5,
    pl: 0.5,
  },
};
