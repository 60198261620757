/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @description  User Management API details to perform a particular action
 * @date 06-07-2024
 */
import actionType from "../actionTypes";
const userManagementApis = {
  // Country Codes
  get_country_codes: {
    api_name: "/UCM/user/countries",
    https_method: "GET",
    action_type: actionType.GET_COUNTRY_CODES,
    description: "",
  },
  add_user: {
    api_name: "/UCM/user/add/account",
    https_method: "POST",
    action_type: actionType.ADD_USER,
    description: "",
  },
  get_users_list_pa: {
    api_name: "/UCM/user/all",
    https_method: "GET",
    action_type: actionType.GET_USERS_LIST_PA,
    description: "To get all the users and their details",
  },
  get_users_list: {
    api_name: "/UCM/user/all",
    https_method: "GET",
    action_type: actionType.GET_USERS_LIST,
    description: "To get all the users and their details",
  },
  update_user: {
    api_name: "/UCM/user/admin/profile/update",
    https_method: "PUT",
    action_type: actionType.UPDATE_USER,
    description: "To get all the users and their details",
  },
  change_user_status: {
    api_name: "/UCM/user/status",
    https_method: "PUT",
    action_type: actionType.CHANGE_USER_STATUS,
    description: "",
  },
  delete_user: {
    api_name: "/UCM/user/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_USER,
    description: "",
  },
  change_user_role: {
    api_name: "/UCM/user/change/role",
    https_method: "PUT",
    action_type: actionType.CHANGE_USER_ROLE,
    description: "",
  },
  // Roles
  add_role: {
    api_name: "/UCM/roles/add",
    https_method: "POST",
    action_type: actionType.ADD_ROLE,
    description: "",
  },
  get_roles_list: {
    api_name: "/UCM/roles/all",
    https_method: "GET",
    action_type: actionType.GET_ROLES_LIST,
    description: "",
  },
  get_roles_list_main: {
    api_name: "/UCM/roles/all",
    https_method: "GET",
    action_type: actionType.GET_ROLES_LIST_MAIN,
    description: "",
  },
  update_role: {
    api_name: "/UCM/roles/update",
    https_method: "PUT",
    action_type: actionType.UPDATE_ROLE,
    description: "",
  },
  remove_chapters: {
    api_name: "/UCM/roles/remove/legal-chapters",
    https_method: "PUT",
    action_type: actionType.REMOVE_CHAPTERS,
    description: "",
  },
  change_role_status: {
    api_name: "/UCM/roles/status",
    https_method: "PUT",
    action_type: actionType.CHANGE_ROLE_STATUS,
    description: "",
  },
  delete_role: {
    api_name: "/UCM/roles/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_ROLE,
    description: "",
  },
  add_page_access: {
    api_name: "/UCM/roles/add/access/pages",
    https_method: "PUT",
    action_type: actionType.ADD_PAGE_ACCESS,
    description: "",
  },
  remove_page_access: {
    api_name: "/UCM/roles/remove/access/pages",
    https_method: "PUT",
    action_type: actionType.REMOVE_PAGE_ACCESS,
    description: "",
  },
  add_feature_access: {
    api_name: "/UCM/roles/add/access/features",
    https_method: "PUT",
    action_type: actionType.ADD_FEATURE_ACCESS,
    description: "",
  },
  remove_feature_access: {
    api_name: "/UCM/roles/remove/access/features",
    https_method: "PUT",
    action_type: actionType.REMOVE_FEATURE_ACCESS,
    description: "",
  },
  // Features
  get_features_list: {
    api_name: "/UCM/features/all",
    https_method: "GET",
    action_type: actionType.GET_FEATURES_LIST,
    description: "",
  },
  // Grant Access
  grant_project_access: {
    api_name: "/PCM/project/access/grant",
    https_method: "PUT",
    action_type: "",
    description: "Grant the access for the user of modules in the project!",
  },
  // Project Access
  get_request_access_list: {
    api_name: "/PCM/project/access/requests",
    https_method: "GET",
    action_type: "",
    description: "Get requested access list",
  },
  request_access: {
    api_name: "/PCM/project/access/request",
    https_method: "POST",
    action_type: "",
    description:
      "To request co-owner/editor/viewer access to the project/zone/ee/swm",
  },
  approve_access: {
    api_name: "/PCM/project/access/request/approve",
    https_method: "PUT",
    action_type: "",
    description:
      "Approve co-owner/editor/viewer access to the project/zone/ee/swm",
  },
  reject_access: {
    api_name: "/PCM/project/access/request/reject",
    https_method: "PUT",
    action_type: "",
    description:
      "Reject co-owner/editor/viewer access to the project/zone/ee/swm",
  },
};

export default userManagementApis;
