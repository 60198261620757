/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 14-10-2024
 * @description : Shows different module to provide access
 */
import { Button, ButtonGroup, Paper } from "@mui/material";
import React from "react";
import { customAppColors } from "../../assets/app-theme/appColors";

function TicketsType({
  eeLabel = "EE",
  swmLabel = "SWM",
  vehicleLabel = "VEHICLE",
  selectedTTBtn,
  setSelectedTTBtn,
  ticketsType,
}) {
  return (
    <Paper elevation={0} sx={styles.container}>
      <ButtonGroup>
        {[
          { value: ticketsType?.EE, label: eeLabel },
          { value: ticketsType?.SWM, label: swmLabel },
          { value: ticketsType?.VEHICLE, label: vehicleLabel },
        //   ...(vehicleLabel
        //     ? [{ value: ticketsType?.VEHICLE, label: vehicleLabel }]
        //     : []),
        ].map((el) => (
          <Button
            size="small"
            sx={{
              color: "black",
              borderRadius: 0,
              borderColor: "#333",
              backgroundColor:
                // selectedLMBtn === el?.value ? "#26890D" : "transparent",
                selectedTTBtn === el?.value
                  ? customAppColors?.green
                  : "transparent",
              "&:hover": {
                borderColor: "#333",
                backgroundColor:
                  // selectedLMBtn == el?.value ? "#37b518" : "#f0f0f0",
                  customAppColors?.lightGreen,
              },
            }}
            onClick={() => setSelectedTTBtn(el?.value)}
          >
            {el?.label}
          </Button>
        ))}
      </ButtonGroup>
    </Paper>
  );
}

export default TicketsType;

// Styles
const styles = {
  container: {
    // display: "flex",
    // width: "290px",
  },
};