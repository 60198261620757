/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable select dropdown component for choosing from a list of options.
 */
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable select dropdown component for choosing from a list of options.
 * @param : size, label, variant, required, disabled, optionsList, selectedValue, sltFldStyle
 * @return The rendered select dropdown component.
 */
function SelectTextFieldNC({
  size,
  label,
  variant,
  required,
  disabled,
  optionsList,
  selectedValue,
  sltFldStyle,
  handleOnChange,
}) {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 220,
        overflowX: "auto", // Allow horizontal scrolling
        // whiteSpace: "nowrap", // Prevent text from wrapping to the next line
        overflowX: "hidden", // Hide horizontal overflow
        scrollbarWidth: "thin", // Firefox
        msOverflowStyle: "none", // IE and Edge
        "&::-webkit-scrollbar": {
          height: "8px", // Adjust scrollbar height
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Scrollbar thumb color
          borderRadius: "4px", // Scrollbar thumb rounded corners
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Scrollbar track color
        },
      },
    },
  };

  return (
    <FormControl
      variant={variant}
      fullWidth
      size={size}
      required={required}
      sx={sltFldStyle}
    >
      <InputLabel shrink={selectedValue == 0 ? true : !!selectedValue}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        value={selectedValue}
        onChange={handleOnChange}
        MenuProps={menuProps}
      >
        {optionsList?.map((option) => (
          <MenuItem
            key={option?.value}
            value={option?.value}
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              lineHeight: 1.5,
            }}
          >
            {option?.label}
            {option?.label1 && (
              <>
                <br />
                {option?.label1}
              </>
            )}
            {option?.label2 && (
              <>
                <br />
                {option?.label2}
              </>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectTextFieldNC;
