import { TabContext, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Paper,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import AddIcon from "@mui/icons-material/Add";
import EditVehicleFunction from "../EditVehicleFunction";
import EditAccessRequest from "../edit_request/EditAccessRequest";
import appTheme from "../../../assets/AppTheme/appTheme";
import InfoButton from "../../../components/infoButton/InfoButton";
import { shallowEqual, useDispatch } from "react-redux";
import { HandleApiActions, projectConfigAPI } from "../../../redux/actions";
import EditVariantVehicleFunction from "./EditVariantVehicleFunction";
import moment from "moment";
import { CustomLogsDialog } from "../../../components";
import customConsole from "../../../config/customConsole";
import EditAccessRequestN from "../edit_request/EditAccessRequestN";

function VariantDetailsTab({ selectedVariantData, selectedProjectData }) {
  const dispatch = useDispatch();

  //1.React useState
  const [value, setValue] = React.useState("1");
  const [openVfDialog, setOpenVfDialog] = useState(false);
  const [editVF, setEditVF] = useState([]);
  const [openVariantChangeLogsDialog, setOpenVariantChangeLogsDialog] =
    useState(false);
  const [variantLogsDialogData, setVariantLogsDialogData] = useState({});
  const [variantChangeLogsList, setVariantChangeLogsList] = useState([]);

  //2.useEffect
  useEffect(() => {
    if (selectedVariantData?.vehicle_function_details?.length > 0) {
      const vehicleFunctionDetails = Array.isArray(
        selectedVariantData.vehicle_function_details
      )
        ? selectedVariantData?.vehicle_function_details?.map((vf) => vf?.id)
        : [];

      setEditVF(vehicleFunctionDetails);
    }
  }, [selectedVariantData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickEdit = () => {
    const reqObj = {
      project_id: parseInt(selectedVariantData?.project_id),
      variant_id: parseInt(selectedVariantData?.variant_id),
      ...(openVfDialog === true
        ? { vehicle_functions: editVF?.map((str) => parseInt(str)) }
        : {}),
    };

    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_vehicle_function,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_variant_details,
          params: {
            project_id: parseInt(selectedVariantData?.project_id),
            variant_id: parseInt(selectedVariantData?.variant_id),
            is_complete_details_required: true,
          },
        })
      );
    });

    setOpenVfDialog(false);
  };

  const handleVariantDialogOpen = (data) => {
    setOpenVariantChangeLogsDialog(true);
    setVariantLogsDialogData(data);
  };

  const handleVariantDialogClose = () => {
    setVariantLogsDialogData(null);
    //setProjectChangeLogsList([]);

    setOpenVariantChangeLogsDialog(false);
  };

  const handleGetVariantChangeLogs = async ({ pageNo, pageSize }) => {
    let apiDetails = projectConfigAPI.get_variant_change_logs;

    if (
      selectedProjectData &&
      selectedProjectData?.project_id &&
      selectedVariantData &&
      selectedVariantData?.variant_id
    ) {
      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectData?.project_id),
          variant_id: Number(selectedVariantData?.variant_id),
          page_number: pageNo,
          page_size: pageSize,
        },
        show_toast: false,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectData?.project_id),
            variant_id: Number(selectedVariantData?.variant_id),
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setVariantChangeLogsList([]);
      } else {
        setVariantChangeLogsList(resp?.data);
      }
    }
  };
  return (
    <div>
      <Paper elevation={3}>
        <Box sx={styles.tabsBox}>
          <TabContext value={value} variant="scrollable">
            <Box sx={styles.tabInBox}>
              <StyledTabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
              >
                {selectedVariantData?.zone_details &&
                  selectedVariantData.zone_details.some(
                    (zone) => zone.zone !== null && zone.zone !== undefined
                  ) && (
                    <StyledTab
                      label={
                        <Badge
                          badgeContent={
                            selectedVariantData?.zone_details.length || "0"
                          }
                          color="primary"
                        >
                          Zones
                        </Badge>
                      }
                      value="2"
                    />
                  )}
                <StyledTab
                  label={
                    <Badge
                      badgeContent={
                        selectedVariantData?.vehicle_function_details?.filter(
                          (item) =>
                            item.id && item.name && Object.keys(item).length > 0
                        ).length || 0
                      }
                      color="primary"
                      sx={{ alignItems: "center" }}
                    >
                      Vehicle Function
                      <InfoButton infoKey={"vehicle_function"} color={"grey"} />
                    </Badge>
                  }
                  value="1"
                />
                <StyledTab
                  label={
                    <Badge
                      badgeContent={
                        selectedVariantData?.vehicle_components?.filter(
                          (item) =>
                            item.id &&
                            item.component &&
                            Object.keys(item)?.length > 0
                        ).length || 0
                      }
                      color="primary"
                      sx={{ alignItems: "center" }}
                    >
                      Vehicle Components
                      <InfoButton
                        infoKey={"power_train_class"}
                        color={"grey"}
                      />
                    </Badge>
                  }
                  value="3"
                />
                <StyledTab
                  label={
                    <Badge
                      badgeContent={
                        selectedVariantData?.owner_details?.filter(
                          (item) => Object.keys(item).length > 0
                        ).length || 0
                      }
                      color="primary"
                    >
                      Project Owner
                    </Badge>
                  }
                  value="4"
                />
                <StyledTab
                  label={
                    <Badge
                      badgeContent={
                        selectedVariantData?.co_owner_details?.filter(
                          (item) => Object.keys(item).length > 0
                        ).length || 0
                      }
                      color="primary"
                    >
                      Co-Owners
                    </Badge>
                  }
                  value="5"
                />
                <StyledTab
                  label={
                    <Badge
                      badgeContent={
                        selectedVariantData?.edit_user_details?.filter(
                          (item) => Object.keys(item).length > 0
                        ).length || 0
                      }
                      color="primary"
                    >
                      Editors
                    </Badge>
                  }
                  value="6"
                />
                <StyledTab
                  label={
                    <Badge
                      badgeContent={
                        selectedVariantData?.view_user_details?.filter(
                          (item) => Object.keys(item).length > 0
                        ).length || 0
                      }
                      color="primary"
                    >
                      Viewers
                    </Badge>
                  }
                  value="7"
                />
                <StyledTab label={"Requests"} value="8" />
              </StyledTabs>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Project Logs">
                  <IconButton
                    color="info"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleVariantDialogOpen(selectedVariantData);
                    }}
                  >
                    <HistoryEduIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <TabPanel value="2">
              {selectedVariantData?.zone_details &&
              selectedVariantData?.zone_details &&
              selectedVariantData?.zone_details.length ? (
                <Box sx={styles.tab2Box}>
                  {selectedVariantData?.zone_details.map((data) => (
                    <Paper elevation={12} sx={styles.tab2Paper} key={data.id}>
                      <Typography sx={styles.tab2Typography}>
                        &nbsp;{data?.zone?.position}&nbsp;&nbsp;-&nbsp;&nbsp;
                        {data?.zone?.zone_name || "Unknown Zone"}{" "}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              ) : (
                <Typography>No Zones</Typography>
              )}
            </TabPanel>
            <TabPanel value="1">
              <Box sx={styles.tab1BoxButton}>
                <Button
                  size="small"
                  startIcon={<AddIcon />}
                  sx={{
                    ...appTheme.commonBtnStyle,
                  }}
                  variant="outlined"
                  onClick={() => setOpenVfDialog(true)}
                >
                  Add
                </Button>
              </Box>
              {openVfDialog && (
                <EditVariantVehicleFunction
                  selectedProjectData={selectedProjectData}
                  selectedVariantData={selectedVariantData}
                  openVfDialog={openVfDialog}
                  setOpenVfDialog={setOpenVfDialog}
                  editVF={editVF}
                  setEditVF={setEditVF}
                  handleClickEdit={handleClickEdit}
                />
              )}
              {selectedVariantData?.vehicle_function_details &&
              selectedVariantData?.vehicle_function_details?.length ? (
                <Box sx={styles.tab1Box}>
                  {selectedVariantData?.vehicle_function_details?.map(
                    (data, idx) => {
                      return (
                        <Paper key={idx} elevation={12} sx={styles.tab1Paper}>
                          <Typography sx={styles.tab1Typo}>
                            {data?.name} - ({data?.abbreviation})
                          </Typography>
                          {data?.other_fields?.description && (
                            <Typography sx={styles.tab1Typo1}>
                              {data?.other_fields?.description}
                            </Typography>
                          )}
                        </Paper>
                      );
                    }
                  )}{" "}
                </Box>
              ) : (
                <Typography sx={styles.tab1NotFound}>
                  Details not found!
                </Typography>
              )}
              {/* </Box> */}
            </TabPanel>

            <TabPanel value="3">
              <Box sx={styles.tab3Box}>
                {selectedVariantData?.vehicle_components?.length ? (
                  selectedVariantData?.vehicle_components?.map((data, idx) => (
                    <>
                      {data?.component ? (
                        <Paper key={idx} elevation={12} sx={styles.tab3Paper}>
                          <Typography sx={styles.tab3Typo}>
                            {data?.component}
                          </Typography>
                        </Paper>
                      ) : (
                        <Typography sx={styles.tab1NotFound}>
                          Details not found!
                        </Typography>
                      )}
                    </>
                  ))
                ) : (
                  <Typography sx={styles.tab1NotFound}>
                    Details not found!
                  </Typography>
                )}
              </Box>
            </TabPanel>

            <TabPanel value="4">
              {" "}
              <Box sx={styles.tab4Box}>
                <Paper elevation={12} sx={styles.tab4Paper}>
                  {[
                    {
                      key: "Name",
                      value:
                        (selectedVariantData?.owner_details &&
                          selectedVariantData?.owner_details[0]?.name) ||
                        "-",
                    },
                    {
                      key: "Email",
                      value:
                        (selectedVariantData?.owner_details &&
                          selectedVariantData?.owner_details[0]?.email) ||
                        "-",
                    },
                  ].map((data) => {
                    return (
                      <Box sx={styles.tab4Box}>
                        <Typography sx={styles.tab4Typo}>{data.key}</Typography>
                        <strong>:</strong>
                        <Typography sx={styles.tab4Typo1}>
                          {data.value}
                        </Typography>
                      </Box>
                    );
                  })}
                </Paper>
              </Box>
            </TabPanel>
            <TabPanel value="5">
              {selectedVariantData?.co_owner_details &&
              selectedVariantData.co_owner_details.length &&
              selectedVariantData.co_owner_details[0]?.role_id ? (
                <Box sx={styles.tab5Box}>
                  {selectedVariantData.co_owner_details.map(
                    (details, index) => {
                      return (
                        <Paper key={index} elevation={12} sx={styles.tab5Paper}>
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box key={idx} sx={styles.tab5Box1}>
                                <Typography sx={styles.tab5Tpyo}>
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={styles.tab5Typo1}>
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    }
                  )}
                </Box>
              ) : (
                <Typography sx={styles.tab1NotFound}>
                  Details not found!
                </Typography>
              )}
            </TabPanel>
            <TabPanel value="6">
              {selectedVariantData?.edit_user_details &&
              selectedVariantData.edit_user_details.length &&
              selectedVariantData.edit_user_details[0]?.role_id ? (
                <Box sx={styles.tab6Box}>
                  {selectedVariantData.edit_user_details.map(
                    (details, index) => {
                      return (
                        <Paper key={index} elevation={12} sx={styles.tab6Paper}>
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box key={idx} sx={styles.tab6DataBox}>
                                <Typography sx={styles.tab6Typo}>
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={styles.tab6Typo1}>
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    }
                  )}
                </Box>
              ) : (
                <Typography sx={styles.tab1NotFound}>
                  Details not found!
                </Typography>
              )}
            </TabPanel>
            <TabPanel value="7">
              {" "}
              {selectedVariantData?.view_user_details &&
              selectedVariantData.view_user_details.length &&
              selectedVariantData.view_user_details[0]?.role_id ? (
                <Box sx={styles.tab7Box}>
                  {selectedVariantData.view_user_details.map(
                    (details, index) => {
                      return (
                        <Paper key={index} elevation={12} sx={styles.tab7Paper}>
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box key={idx} sx={styles.tab7DataBox}>
                                <Typography sx={styles.tab7Typo}>
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={styles.tab7Typo1}>
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    }
                  )}
                </Box>
              ) : (
                <Typography sx={styles.tab1NotFound}>
                  Details not found!
                </Typography>
              )}
            </TabPanel>
            <TabPanel value="8">
              {/* <EditAccessRequest
                selectedProjectData={selectedProjectData}
                selectedVariantData={selectedVariantData}
              /> */}
              <EditAccessRequestN
                selectedProjectData={selectedProjectData}
                selectedVariantData={selectedVariantData}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
      {openVariantChangeLogsDialog && (
        <CustomLogsDialog
          open={openVariantChangeLogsDialog}
          handleClose={handleVariantDialogClose}
          dialogTitle={"Change Logs"}
          dialogContent={variantChangeLogsList}
          data={variantLogsDialogData}
          getLogsData={handleGetVariantChangeLogs}
        />
      )}
    </div>
  );
}

export default VariantDetailsTab;

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 60,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "1rem",
    marginRight: theme.spacing(1),

    color: "green",
    "&.Mui-selected": {
      color: "black",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const styles = {
  tabsBox: { width: "100%", mt: "20px", borderRadius: "10px" },
  tabInBox: {
    borderBottom: 1,
    borderColor: "divider",
    display: "flex",
    justifyContent: "space-between",
  },
  tab2Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab2Paper: { height: "30px", padding: "5px" },
  tab2Typography: { fontWeight: "bold", fontSize: "14px" },
  tab1BoxButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tab1Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    marginTop: "10px",
  },
  tab1Paper: { padding: "10px", borderRadius: "10px" },
  tab1Typo: { fontSize: "14px", fontWeight: "bold" },
  tab1Typo1: { fontSize: "12px", fontWeight: "bold" },
  tab1NotFound: { fontWeight: "bold", fontSize: "12px", color: "red" },
  tab3Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab3Paper: { padding: "10px", borderRadius: "10px" },
  tab3Typo: { fontSize: "14px", fontWeight: "bold" },
  tab3Typo1: { fontSize: "12px", fontWeight: "bold" },
  tab4Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab4Paper: { padding: "10px", borderRadius: "10px" },
  tab4Box: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab4Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab4Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab5Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab5Paper: { padding: "10px", borderRadius: "10px" },
  tab5Box1: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab5Tpyo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab5Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab6Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab6Paper: { padding: "10px", borderRadius: "10px" },
  tab6DataBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab6Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab6Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab7Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab7Paper: { padding: "10px", borderRadius: "10px" },
  tab7DataBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab7Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab7Typo1: { fontSize: "14px", fontWeight: "bold" },
};
