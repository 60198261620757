/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 10-10-2024
 * @description : To Link SWM To SWM.
 */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SelectTextFieldN } from "../../../components";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, labCar } from "../../../redux/actions";
import customConsole from "../../../config/customConsole";
import softwareIntersectionAPI from "../../../redux/actions/softwareIntersectionActions";
import LinkedSWMTable from "./LinkedSWMTable";
import appTheme from "../../../assets/AppTheme/appTheme";
import { Link, useLocation } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function LinkSWM({
  selectedProjectId,
  selectedProjectIsZonal,
  selectedProjectZonesList,
  setIsLinkSoftwareModulesScreen,
  selectedSingleVariantDetails,
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const zoneFromurl = queryParams.get("selectedZone");
  const eeFromurl = queryParams.get("ee_id");
  const swmFromurl = queryParams.get("swm_id");
  /* Dispatch */
  const dispatch = useDispatch();

  /* Get data from the redux store  */

  /* Zone Main */
  const [zoneMainOptionsList, setZoneMainOptionsList] = useState([]);
  const [selectedZoneMain, setSelectedZoneMain] = useState(null);

  /* Zone Linked */
  const [zoneLinkedOptionsList, setZoneLinkedOptionsList] = useState([]);
  const [selectedZoneLinked, setSelectedZoneLinked] = useState(null);

  /* EE Components Main */
  const [eeComponentsMainList, setEEComponentsMainList] = useState([]);
  const [eeMainOptionsList, setEEMainOptionsList] = useState([]);
  const [selectedEEMainId, setSelectedEEMainId] = useState(null);

  /* EE Components Linked */
  const [eeComponentsLinkedList, setEEComponentsLinkedList] = useState([]);
  const [eeLinkedOptionsList, setEELinkedOptionsList] = useState([]);
  const [selectedEELinkedId, setSelectedEELinkedId] = useState(null);

  /* EE - SW Module Main */
  const [swModulesMainList, setSWModulesMainList] = useState([]);

  /* EE - SW Module Linked */
  const [swModulesLinkedList, setSWModulesLinkedList] = useState([]);

  /* EE - SW Module Main Selected */
  const [selectedMainSWModuleDetails, setSelectedMainSWModuleDetails] =
    useState(null);

  /* SWM Intersections */
  const [selectedSWMLinkedList, setSelectedSWMLinkedList] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const linkModuleType = { SWM: 1, EE: 2 };

  const [selectedSWM, setSelectedSWM] = useState(null);

  const [actionType2DialogOpen, setActionType2DialogOpen] = useState(false);
  const [actionType3DialogOpen, setActionType3DialogOpen] = useState(false);

  /* Dispatch Functions */
  /* Get EE Main Components List Function */
  const getEEMainComponentsList = async ({
    projectId,
    zonePosition,
    variantId,
  }) => {
    let apiDetails = labCar.get_ee_components_new;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        zone_position: zonePosition,
        variant_id: variantId,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          zone_position: zonePosition,
          variant_id: variantId,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setEEComponentsMainList([]);
    } else {
      setEEComponentsMainList(resp.data);
    }
  };

  /* Get EE Linked Components List Function */
  const getEELinkedComponentsList = async ({
    projectId,
    zonePosition,
    variantId,
  }) => {
    let apiDetails = labCar.get_ee_components_new;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        zone_position: zonePosition,
        variant_id: variantId,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          zone_position: zonePosition,
          variant_id: variantId,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setEEComponentsLinkedList([]);
    } else {
      setEEComponentsLinkedList(resp.data);
    }
  };

  /* Get EE Main Linked Components List Function */
  const getEEMainLinkedComponentsList = async ({ projectId, variantId }) => {
    let apiDetails = labCar.get_ee_components_new;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        variant_id: variantId,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          variant_id: variantId,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setEEComponentsMainList([]);
      setEEComponentsLinkedList([]);
    } else {
      setEEComponentsMainList(resp.data);
      setEEComponentsLinkedList(resp.data);
    }
  };

  /* Get Main Software Modules List Function */
  const getMainSWModulesList = async ({ projectId, eeId, variantId }) => {
    let apiDetails = labCar.get_sw_modules_new;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        ee_id: eeId,
        variant_id: variantId,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          variant_id: variantId,
          ee_id: eeId,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setSWModulesMainList([]);
    } else {
      setSWModulesMainList(resp.data);
    }
  };

  /* Get Linked Software Modules List Function */
  const getLinkedSWModulesList = async ({ projectId, eeId, variantId }) => {
    let apiDetails = labCar.get_sw_modules_new;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        ee_id: eeId,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          variant_id: variantId,
          ee_id: eeId,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setSWModulesLinkedList([]);
    } else {
      setSWModulesLinkedList(resp.data);
    }
  };

  /* Get SWM Intersections List Function */
  const getSWModulesIntersectionsList = async ({
    projectId,
    eeId,
    swmId,
    variantId,
  }) => {
    if (projectId && eeId) {
      let apiDetails = softwareIntersectionAPI.get_software_intersections;

      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(projectId),
          variant_id: variantId,
          ee_id: Number(eeId),
          ...(swmId && { swm_id: Number(swmId) }),
        },
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(projectId),
            variant_id: variantId,
            ee_id: Number(eeId),
            ...(swmId && { swm_id: Number(swmId) }),
          },
          show_toast: false,
        })
      );

      if (resp.error) {
        setSelectedSWMLinkedList([]);
      } else {
        setSelectedSWMLinkedList(resp.data);
      }
    }
  };

  /* Handle EE Component Link Function */
  const handleSWModuleLink = (selectedLinkedSWModuleDetails) => {
    if (
      selectedProjectId &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      selectedMainSWModuleDetails &&
      selectedMainSWModuleDetails?.ee_id &&
      selectedMainSWModuleDetails?.swm_id &&
      selectedLinkedSWModuleDetails &&
      selectedLinkedSWModuleDetails?.ee_id &&
      selectedLinkedSWModuleDetails?.swm_id
    ) {
      let currentTime = new Date().toISOString();

      dispatch(
        HandleApiActions({
          ...labCar.link_sw_module,
          params: {
            project_id: Number(selectedProjectId),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            parent: {
              ee_id: Number(selectedMainSWModuleDetails?.ee_id),
              swm_id: Number(selectedMainSWModuleDetails?.swm_id),
            },
            child: {
              ee_id: Number(selectedLinkedSWModuleDetails?.ee_id),
              swm_id: Number(selectedLinkedSWModuleDetails?.swm_id),
            },
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        /* Get Main Software Module Details */
        if (selectedProjectId && selectedEEMainId) {
          getMainSWModulesList({
            projectId: selectedProjectId,
            eeId: selectedEEMainId,
            variantId: selectedSingleVariantDetails?.variant_id,
          });
        }
        /* Get Linked Software Module Details, when the selected project and ee linked is changed  */
        if (
          selectedProjectId &&
          selectedEELinkedId &&
          selectedSingleVariantDetails?.variant_id &&
          selectedSingleVariantDetails
        ) {
          getLinkedSWModulesList({
            projectId: selectedProjectId,
            eeId: selectedEELinkedId,
            variantId: selectedSingleVariantDetails?.variant_id,
          });
        }

        getSWModulesIntersectionsList({
          variantId: selectedSingleVariantDetails?.variant_id,
          projectId: Number(selectedProjectId),
          eeId: Number(selectedMainSWModuleDetails?.ee_id),
          swmId: Number(selectedMainSWModuleDetails?.swm_id),
        });
      });
    }
  };

  /* Handle EE Component Un-Link Function */
  const handleSWModuleUnLink = (selectedLinkedSWModuleDetails) => {
    if (
      selectedProjectId &&
      selectedMainSWModuleDetails &&
      selectedMainSWModuleDetails?.ee_id &&
      selectedMainSWModuleDetails?.swm_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      selectedLinkedSWModuleDetails &&
      selectedLinkedSWModuleDetails?.swm_id
    ) {
      let currentTime = new Date().toISOString();

      dispatch(
        HandleApiActions({
          ...labCar.unlink_sw_module,
          params: {
            project_id: Number(selectedProjectId),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id: Number(selectedMainSWModuleDetails?.ee_id),
            swm_id: Number(selectedMainSWModuleDetails?.swm_id),
            removing_swm_id: Number(selectedLinkedSWModuleDetails?.swm_id),
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        /* Get Main Software Module Details */
        if (selectedProjectId && selectedEEMainId) {
          getMainSWModulesList({
            projectId: selectedProjectId,
            eeId: selectedEEMainId,
            variantId: selectedSingleVariantDetails?.variant_id,
          });
        }
        /* Get Linked Software Module Details, when the selected project and ee linked is changed  */
        if (
          selectedProjectId &&
          selectedEELinkedId &&
          selectedSingleVariantDetails &&
          selectedSingleVariantDetails?.variant_id
        ) {
          getLinkedSWModulesList({
            projectId: selectedProjectId,
            eeId: selectedEELinkedId,
            variantId: selectedSingleVariantDetails?.variant_id,
          });
        }

        getSWModulesIntersectionsList({
          projectId: Number(selectedProjectId),
          eeId: Number(selectedMainSWModuleDetails?.ee_id),
          variantId: selectedSingleVariantDetails?.variant_id,
          swmId: Number(selectedMainSWModuleDetails?.swm_id),
        });
      });
    }
  };

  useEffect(() => {
    // If zonal, set the zone main options list & zone lined options list
    if (selectedProjectIsZonal) {
      /* Zone Main */
      let filteredZoneMainOptionsList = selectedProjectZonesList
        ?.map((el) => {
          return {
            value: el?.position,
            label: el?.zone_name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setZoneMainOptionsList(filteredZoneMainOptionsList);

      /* Select zone from URL if available */
      const zoneFromUrlValue = filteredZoneMainOptionsList?.find(
        (zone) => zone.label === zoneFromurl
      );
      console.log("zoneFromUrlValue", zoneFromUrlValue);
      if (zoneFromUrlValue) {
        setSelectedZoneMain(zoneFromUrlValue?.value);
      } /* Select first zone main by default */ else if (
        selectedProjectIsZonal &&
        filteredZoneMainOptionsList?.[0]?.value
      ) {
        setSelectedZoneMain(filteredZoneMainOptionsList?.[0]?.value);
      }

      /* Zone Linked */
      let filteredZoneLinkedOptionsList = selectedProjectZonesList
        ?.map((el) => {
          return {
            value: el?.position,
            label: el?.zone_name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setZoneLinkedOptionsList(filteredZoneLinkedOptionsList);

      /* Select first zone linked by default */
      if (selectedProjectIsZonal && filteredZoneLinkedOptionsList?.[0]?.value) {
        setSelectedZoneLinked(filteredZoneLinkedOptionsList?.[0]?.value);
      }
    }
  }, [selectedProjectZonesList]);

  useEffect(() => {
    /* Select first zone main by default */
    // if (selectedProjectIsZonal && zoneMainOptionsList?.[0]?.value) {
    //   setSelectedZoneMain(zoneMainOptionsList?.[0]?.value);
    // }
  }, [zoneMainOptionsList]);

  useEffect(() => {
    /* Select first zone linked by default */
    // if (selectedProjectIsZonal && zoneLinkedOptionsList?.[0]?.value) {
    //   setSelectedZoneLinked(zoneLinkedOptionsList?.[0]?.value);
    // }
  }, [zoneLinkedOptionsList]);

  useEffect(() => {
    // Get EE Components Main & Linked List, When the selected project is changed
    if (
      !selectedProjectIsZonal &&
      selectedProjectId &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id
    ) {
      getEEMainLinkedComponentsList({
        projectId: selectedProjectId,
        variantId: selectedSingleVariantDetails?.variant_id,
      });
    }
  }, [selectedProjectId]);

  useEffect(() => {
    /* Set EE/SWM Main state to default, When the selected zone main is changed */
    setEEComponentsMainList(null);
    setSelectedEEMainId(null);
    setSWModulesMainList([]);
    setSelectedMainSWModuleDetails(null);

    // Get EE Components Main List
    if (
      selectedProjectIsZonal &&
      selectedProjectId &&
      selectedZoneMain &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id
    ) {
      getEEMainComponentsList({
        projectId: selectedProjectId,
        variantId: selectedSingleVariantDetails?.variant_id,
        zonePosition: selectedZoneMain,
      });
    }
  }, [selectedZoneMain]);

  useEffect(() => {
    /* Set EE/SWM Linked state to default, When the selected zone linked is changed */
    setEEComponentsLinkedList([]);
    setSelectedEELinkedId(null);
    setSWModulesLinkedList([]);

    // Get EE Components Linked List
    if (
      selectedProjectIsZonal &&
      selectedProjectId &&
      selectedZoneLinked &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      selectedSingleVariantDetails
    ) {
      getEELinkedComponentsList({
        projectId: selectedProjectId,
        zonePosition: selectedZoneLinked,
        variantId: selectedSingleVariantDetails?.variant_id,
      });
    }
  }, [selectedZoneLinked]);

  useEffect(() => {
    // Set the EE Main Options List
    let filteredEEMainOptionsList = eeComponentsMainList
      ?.map((el) => {
        return {
          value: el?.ee_id,
          label: el?.description,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setEEMainOptionsList(filteredEEMainOptionsList || []);

    if (eeComponentsMainList?.length > 0) {
      // Check if eeFromurl exists and find the matching component
      const matchingComponent = eeFromurl
        ? eeComponentsMainList?.find((item) => item?.ee_id === eeFromurl)
        : null;

      if (matchingComponent) {
        // Set the selected EE details to the matching component
        setSelectedEEMainId(matchingComponent?.ee_id);
        console.log("matchingComponent", matchingComponent);
      } /* Select first ee component main by default */ else if (
        filteredEEMainOptionsList?.[0]?.value
      ) {
        setSelectedEEMainId(filteredEEMainOptionsList?.[0]?.value);
        console.log(
          "filteredEEMainOptionsList",
          filteredEEMainOptionsList?.[0]?.value
        );
      }
    }
  }, [eeComponentsMainList, eeFromurl]);

  useEffect(() => {
    /* Select first ee component main by default */
    // if (eeMainOptionsList?.[0]?.value) {
    //   setSelectedEEMainId(eeMainOptionsList?.[0]?.value);
    // }
  }, [eeMainOptionsList]);

  useEffect(() => {
    // Set the EE Linked Options List
    let filteredEELinkedOptionsList = eeComponentsLinkedList
      ?.map((el) => {
        return {
          value: el?.ee_id,
          label: el?.description,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setEELinkedOptionsList(filteredEELinkedOptionsList);

    /* Select first ee component linked by default */
    if (filteredEELinkedOptionsList?.[0]?.value) {
      setSelectedEELinkedId(filteredEELinkedOptionsList?.[0]?.value);
    }
  }, [eeComponentsLinkedList]);

  useEffect(() => {
    /* Select first ee component linked by default */
    // if (eeLinkedOptionsList?.[0]?.value) {
    //   setSelectedEELinkedId(eeLinkedOptionsList?.[0]?.value);
    // }
  }, [eeLinkedOptionsList]);

  useEffect(() => {
    /* Set Selected Main Software Module Details to null, when the selected project and ee main is changed  */
    setSelectedMainSWModuleDetails(null);

    /* Get Main Software Module Details, when the selected project and ee main is changed  */
    if (
      selectedProjectId &&
      selectedEEMainId &&
      selectedSingleVariantDetails?.variant_id &&
      selectedSingleVariantDetails
    ) {
      getMainSWModulesList({
        projectId: selectedProjectId,
        eeId: selectedEEMainId,
        variantId: selectedSingleVariantDetails?.variant_id,
      });
    }
  }, [selectedProjectId, selectedEEMainId, selectedSingleVariantDetails]);

  useEffect(() => {
    /* Get Linked Software Module Details, when the selected project and ee linked is changed  */
    if (
      selectedProjectId &&
      selectedEELinkedId &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id
    ) {
      getLinkedSWModulesList({
        projectId: selectedProjectId,
        eeId: selectedEELinkedId,
        variantId: selectedSingleVariantDetails?.variant_id,
      });
    }
  }, [selectedProjectId, selectedEELinkedId, selectedSingleVariantDetails]);

  useEffect(() => {
    if (swModulesMainList?.length > 0) {
      const matchingSwm = swmFromurl
        ? swModulesMainList.find((item) => item?.swm_id === swmFromurl)
        : null;

      if (matchingSwm) {
        setSelectedMainSWModuleDetails(matchingSwm);
      } /* Select first software module main by default */ else if (
        !selectedMainSWModuleDetails &&
        swModulesMainList?.[0]
      ) {
        setSelectedMainSWModuleDetails(swModulesMainList?.[0]);
      }
    }
  }, [swModulesMainList, swmFromurl]);

  useEffect(() => {
    /* Get SWM Intersections List  */
    getSWModulesIntersectionsList({
      variantId: selectedSingleVariantDetails?.variant_id,
      projectId: Number(selectedProjectId),
      eeId: Number(selectedEEMainId),
      swmId: Number(selectedMainSWModuleDetails?.swm_id),
    });
  }, [
    selectedProjectId,
    selectedEEMainId,
    selectedMainSWModuleDetails,
    selectedSingleVariantDetails,
  ]);

  const confirmUpdateAndRedirect = async (selectedMainSWModuleDetails) => {
    // Create the request object
    const reqObj = {
      current_time: new Date().toISOString(),
      project_id: Number(selectedProjectId),
      variant_id: Number(selectedSingleVariantDetails?.variant_id),
      ee_id: Number(selectedEEMainId),
      swm_id: Number(selectedMainSWModuleDetails?.swm_id),
      is_dependency_linking: true,
    };
    try {
      // Dispatch the update action type API action
      const response = await dispatch(
        HandleApiActions({
          ...labCar.update_action_type,
          params: reqObj,
          show_toast: true,
        })
      );
      getMainSWModulesList({
        projectId: selectedProjectId,
        eeId: selectedEEMainId,
        variantId: selectedSingleVariantDetails?.variant_id,
      });
      // Handle successful edit
      if (!response.error) {
        handleDialogClose();
        handleActionType2DialogOpen(selectedMainSWModuleDetails);
      }
    } catch (error) {
      console.error("Failed to update action type", error);
    }
  };

  console.log("selectedSingleVariantDetails",selectedSingleVariantDetails?.variant_id)

  const updateChangesAndClose = () => {
    handleDialogClose();
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleActionType2DialogOpen = (swm) => {
    setSelectedSWM(swm);
    setActionType2DialogOpen(true);
  };

  const handleActionType3DialogOpen = (swm) => {
    setSelectedSWM(swm);
    setActionType3DialogOpen(true);
  };

  const handleActionType2DialogClose = () => {
    setActionType2DialogOpen(false);
  };

  const handleActionType3DialogClose = () => {
    setActionType3DialogOpen(false);
  };

  const handleActionType3DialogCloseAndBack = () => {
    setIsLinkSoftwareModulesScreen(false);
  };

  return (
    <Box>
      {/* Software modules linking */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} sx={styles.grid}>
          {/* Select fields */}
          <Box sx={{ mb: 3 }}>
            {selectedProjectIsZonal && (
              <SelectTextFieldN
                size={"small"}
                label={"Select Zone"}
                variant={"standard"}
                optionsList={zoneMainOptionsList}
                selectedValue={selectedZoneMain}
                handleOnChange={(e) => setSelectedZoneMain(e.target.value)}
                sltFldStyle={styles.selectTxtStyle}
              />
            )}
            <SelectTextFieldN
              size={"small"}
              label={"Select EE Component"}
              variant={"standard"}
              optionsList={eeMainOptionsList}
              selectedValue={selectedEEMainId}
              disabled={selectedProjectIsZonal ? !selectedZoneMain : false}
              handleOnChange={(e) => setSelectedEEMainId(e.target.value)}
              sltFldStyle={{
                ...styles.selectTxtStyle,
                ml: 2,
              }}
            />
          </Box>
          {/* Software modules card */}
          <Card sx={styles.card}>
            <CardHeader
              sx={styles.cardHeader}
              title={
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {"SOFTWARE MODULES"}
                </Typography>
              }
            />
            <CardContent sx={styles.cardContent}>
              {swModulesMainList && swModulesMainList?.length > 0 ? (
                swModulesMainList?.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => setSelectedMainSWModuleDetails(item)}
                    sx={{
                      ...styles.cardItems,
                      border:
                        selectedMainSWModuleDetails &&
                        selectedMainSWModuleDetails?.swm_id == item?.swm_id
                          ? "2px solid blue"
                          : "1px solid lightgrey",
                      cursor: "pointer",
                      "&:hover": {
                        // backgroundColor: "lightgrey",
                      },
                    }}
                  >
                    <Box sx={styles.cardItemsTxtContainer}>
                      <Typography variant="body2">
                        <strong>Name:</strong> {item?.name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Description:</strong> {item?.description}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Version:</strong> {item?.version}
                      </Typography>
                    </Box>
                    <Box sx={styles.edRpIconsContainer}>
                      <Tooltip
                        title={
                          item.action_type === 1
                            ? "Action Pending in SWM to SWM linking"
                            : ""
                        }
                      >
                        <IconButton
                          size="small"
                          sx={{ color: "red" }}
                          onClick={
                            item.action_type === 1
                              ? handleDialogOpen
                              : undefined
                          }
                        >
                          {item.action_type === 1 && (
                            <ErrorOutlineIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title={
                          item.action_type === 2
                            ? "Action Pending in SWM to Legal requirements"
                            : ""
                        }
                      >
                        <IconButton
                          size="small"
                          sx={{ color: "red" }}
                          onClick={
                            item.action_type === 2
                              ? () => handleActionType2DialogOpen(item)
                              : undefined
                          }
                        >
                          {item.action_type === 2 && (
                            <ErrorOutlineIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>

                      {/* <Tooltip title={item.action_type === 3 ? "Action Pending in VLC Software module" : ""}>
    <IconButton
      size="small"
      sx={{ color: "red" }}
      onClick={item.action_type === 3 ? () => handleActionType3DialogOpen(item) : undefined}
    >
      {item.action_type === 3 && <ErrorOutlineIcon fontSize="small" />}
    </IconButton>
  </Tooltip> */}
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography variant="body1" sx={styles.noDataAvailableTxt}>
                  No data available
                </Typography>
              )}
            </CardContent>
            <CardActions sx={styles.cardActions}></CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={styles.grid}>
          {/* Select fields */}
          <Box sx={{ mb: 3 }}>
            {selectedProjectIsZonal && (
              <SelectTextFieldN
                size={"small"}
                label={"Select Zone"}
                variant={"standard"}
                optionsList={zoneLinkedOptionsList}
                selectedValue={selectedZoneLinked}
                handleOnChange={(e) => setSelectedZoneLinked(e.target.value)}
                sltFldStyle={styles.selectTxtStyle}
              />
            )}
            <SelectTextFieldN
              size={"small"}
              label={"Select EE Component"}
              variant={"standard"}
              optionsList={eeLinkedOptionsList}
              selectedValue={selectedEELinkedId}
              disabled={selectedProjectIsZonal ? !selectedZoneLinked : false}
              handleOnChange={(e) => setSelectedEELinkedId(e.target.value)}
              sltFldStyle={{
                ...styles.selectTxtStyle,
                ml: 2,
              }}
            />
          </Box>
          {/* Software modules card */}
          <Card sx={styles.card}>
            <CardHeader
              sx={styles.cardHeader}
              title={
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {"LINKED TO"}
                </Typography>
              }
            />
            <CardContent sx={styles.cardContent}>
              {swModulesLinkedList && swModulesLinkedList?.length > 0 ? (
                swModulesLinkedList
                  ?.filter(
                    (el) => el?.swm_id != selectedMainSWModuleDetails?.swm_id
                  )
                  ?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        ...styles.cardItems,
                        border: item?.linked_swm_ids?.includes(
                          selectedMainSWModuleDetails?.swm_id
                        )
                          ? "2px solid green"
                          : "1px solid lightgrey",
                        // cursor: "pointer",
                        "&:hover": {
                          // backgroundColor: "lightgrey",
                        },
                      }}
                    >
                      <Box sx={styles.cardItemsTxtContainer}>
                        <Typography variant="body2">
                          <strong>Name:</strong> {item?.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {item?.description}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Version:</strong> {item?.version}
                        </Typography>
                      </Box>
                      <Box>
                        {selectedMainSWModuleDetails && (
                          <Tooltip
                            title={
                              item?.linked_swm_ids?.includes(
                                selectedMainSWModuleDetails?.swm_id
                              )
                                ? "Unlink"
                                : "Link"
                            }
                          >
                            <IconButton
                              size="small"
                              sx={{ color: "green" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  item?.linked_swm_ids?.includes(
                                    selectedMainSWModuleDetails?.swm_id
                                  )
                                ) {
                                  handleSWModuleUnLink(item);
                                } else {
                                  handleSWModuleLink(item);
                                }
                              }}
                            >
                              {item?.linked_swm_ids?.includes(
                                selectedMainSWModuleDetails?.swm_id
                              ) ? (
                                <LinkOffIcon sx={styles.linkOffIcon} />
                              ) : (
                                <LinkIcon sx={styles.linkIcon} />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  ))
              ) : (
                <Typography variant="body1" sx={styles.noDataAvailableTxt}>
                  No data available
                </Typography>
              )}
            </CardContent>
            <CardActions sx={styles.cardActions}></CardActions>
          </Card>
        </Grid>
      </Grid>

      {selectedMainSWModuleDetails && selectedSWMLinkedList?.length > 0 && (
        <Box sx={{ mt: "10px" }}>
          <LinkedSWMTable
            selectedSWMLinkedList={selectedSWMLinkedList}
            selectedProjectIsZonal={selectedProjectIsZonal}
          />
        </Box>
      )}
      <Box>
        {/* Dialog for Action Required */}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
        >
          <DialogTitle align="left">Action Required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Are you sure the dependency linking of Software module is
                completed?
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={() =>
                    confirmUpdateAndRedirect(selectedMainSWModuleDetails)
                  } // Ensure this function is executed before navigating
                >
                  Yes
                </Button>

                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={updateChangesAndClose}
                >
                  No
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Action Pending Dialog */}
        <Dialog
          open={actionType2DialogOpen}
          onClose={handleActionType2DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action Pending</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Software module intersection linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/type-approval?project_id=${selectedProjectId}&variant_id=${selectedSingleVariantDetails?.variant_id}&swm_id=${selectedMainSWModuleDetails?.swm_id}&ee_id=${selectedEEMainId}&selectedZone=${selectedZoneMain}&linkModuleType=${linkModuleType?.SWM}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={handleActionType2DialogClose}
                  >
                    Link Now
                  </Button>
                </Link>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType2DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Action Pending Dialog */}
        <Dialog
          open={actionType3DialogOpen}
          onClose={handleActionType3DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action Pending</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Software module update/replace action is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/labcar?ee_id=${selectedEEMainId}&selectedZone=${selectedZoneMain}&swm_id=${selectedMainSWModuleDetails?.swm_id}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={handleActionType3DialogCloseAndBack}
                  >
                    Take action
                  </Button>
                </Link>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType3DialogClose}
                >
                  Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

export default LinkSWM;

// Styles
const styles = {
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  selectTxtStyle: {
    width: "190px",
    mt: 1,
  },
  card: {
    mx: 2,
    boxShadow: 12,
    // width: "420px",
    width: "100%",
    maxWidth: "420px",
  },
  cardHeader: {
    pb: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  cardContent: {
    height: "340px",
    overflowY: "auto",
  },
  cardItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
  },
  cardItemsTxtContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  noDataAvailableTxt: {
    textAlign: "center",
    mt: 2,
  },
  linkOffIcon: {
    color: "#FF5757",
  },
  linkIcon: {
    color: "green",
  },
};
