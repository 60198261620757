import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  adminSettingsAPI,
  HandleApiActions,
  projectConfigAPI,
} from "../../../redux/actions";
import UpdateVehicleStatus from "../UpdateVehicleStatus";

function VariantDetails({ selectedVariantData }) {
  const dispatch = useDispatch();
  //Redux
  const vehicleStatusDetails = useSelector(
    (state) => state.vehicleStatusReducer
  );

  //1.useState
  const [openVehicleStatus, setOpenVehicleStatus] = useState(false);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_vehicle_status,
        shallowEqual: false,
      })
    );
  }, []);
  const findVehicleStatus = useMemo(() => {
    if (vehicleStatusDetails?.vehicleStatusDetails?.data?.length > 0) {
      return vehicleStatusDetails?.vehicleStatusDetails?.data?.find(
        (vs) =>
          vs?.name?.toUpperCase() ===
          selectedVariantData?.vehicle_status?.toUpperCase()
      );
    }
    return null;
  }, [vehicleStatusDetails, selectedVariantData]);

  return (
    <div>
      <Grid container spacing={2} sx={{ mt: "10px" }}>
        {/* First grid with 3 items */}
        <Grid item xs={12} md={4} sm={12}>
          {[
            {
              title: "Name",
              value: selectedVariantData?.name,
            },
            {
              title: "Vehicle Status",
              value: selectedVariantData?.vehicle_status,
            },
          ]?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold", color: "black" }}>
                  {item?.title}
                </Typography>
                <strong>:</strong>

                <Box>
                  {item?.title === "Vehicle Status" ? (
                    <Box sx={styles.dataStatus}>
                      {findVehicleStatus?.is_edit_allowed === true ? (
                        <Chip
                          sx={{
                            boxShadow: "3",
                            fontWeight: "bold",
                            borderRadius: "0px 15px 0px 15px",
                            backgroundColor: "#c8e8fa",
                            color: "black",
                          }}
                          label={selectedVariantData?.vehicle_status}
                          size="medium"
                        />
                      ) : (
                        <Chip
                          sx={{
                            boxShadow: "3",
                            fontWeight: "bold",
                            borderRadius: "0px 15px 0px 15px",
                            backgroundColor: "#ed6653",
                            color: "white",
                          }}
                          label={selectedVariantData?.vehicle_status}
                          size="medium"
                        />
                      )}
                      <IconButton
                        sx={{ color: "blue" }}
                        size="small"
                        onClick={() => setOpenVehicleStatus(true)}
                      >
                        <EditIcon sx={styles.editIcon} />
                      </IconButton>
                    </Box>
                  ) : (
                    <Typography sx={{ color: "black" }}>
                      {item?.value}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Grid>

        {/* Second grid with 3 items */}
        <Grid item xs={12} md={4} sm={12}>
          {[
            {
              title: "Delivery Market",
              value: selectedVariantData?.delivery_market,
            },
            {
              title: "Power Train Class",
              value: `${selectedVariantData?.powertrain_class_details?.[0]?.name} - ${selectedVariantData?.powertrain_class_details?.[0]?.abbreviation}`,
            },
          ].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold", color: "black" }}>
                  {item?.title}
                </Typography>
                <strong>:</strong>
                <Typography sx={{ color: "black" }}>{item?.value}</Typography>
              </Box>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} md={4} sm={13}>
          {[
            {
              title: "Manufacturer Name",
              value: selectedVariantData?.mft_details?.name,
            },
            {
              title: "Manufacturer Address",
              value: selectedVariantData?.mft_details?.address,
            },
          ].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold", color: "black" }}>
                  {item?.title}
                </Typography>
                <strong>:</strong>
                <Typography sx={{ color: "black" }}>{item?.value}</Typography>
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
      {openVehicleStatus && (
        <UpdateVehicleStatus
          openVehicleStatus={openVehicleStatus}
          setOpenVehicleStatus={setOpenVehicleStatus}
          boxes={selectedVariantData}
        />
      )}
    </div>
  );
}

export default VariantDetails;

const styles = {};
