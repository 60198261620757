/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 25-07-2024
 * @description Lab Car API details to perform a action
 */

import actionType from "../actionTypes";

const labCar = {
  get_project_details_mind_map_pa: {
    api_name: "/PCM/project/mind-map",
    https_method: "GET",
    action_type: actionType.GET_PROJECT_DETAILS_MIND_MAP_PA,
    description: "",
  },
  get_project_details_pa: {
    api_name: "/PCM/project/details",
    https_method: "GET",
    action_type: actionType.GET_PROJECT_DETAILS_PA,
    description: "",
  },
  get_variant_details: {
    api_name: "/PCM/project/variants",
    https_method: "GET",
    action_type: "",
    description: "",
  },
  get_ee_components_pa: {
    api_name: "/PCM/ee_components/details",
    https_method: "GET",
    action_type: actionType.GET_EE_COMPONENTS_PA,
    description: "",
  },
  get_sw_modules_pa: {
    api_name: "/PCM/swm/details",
    https_method: "GET",
    action_type: actionType.GET_SW_MODULES_PA,
    description: "",
  },
  get_project_details_mind_map: {
    api_name: "/PCM/project/mind-map",
    https_method: "GET",
    action_type: actionType.GET_PROJECT_DETAILS_MIND_MAP,
    description: "",
  },
  get_project_details: {
    api_name: "/PCM/project/details",
    https_method: "GET",
    action_type: actionType.GET_PROJECT_DETAILS,
    description: "",
  },
  get_ee_components: {
    api_name: "/PCM/ee_components/details",
    https_method: "GET",
    action_type: actionType.GET_EE_COMPONENTS,
    description: "",
  },
  get_ee_components_count: {
    api_name: "/PCM/ee_components/details",
    https_method: "GET",
    description: "",
  },
  add_ee_component: {
    api_name: "/PCM/ee_components/add",
    https_method: "POST",
    action_type: actionType.Add_EE_COMPONENT,
    description: "",
  },
  update_ee_component: {
    api_name: "/PCM/ee_components/details",
    https_method: "PUT",
    action_type: actionType.UPDATE_EE_COMPONENT,
    description: "",
  },
  replace_ee_component: {
    api_name: "/PCM/ee_components/replace",
    https_method: "POST",
    action_type: actionType.REPLACE_EE_COMPONENT,
    description: "",
  },
  get_sw_modules: {
    api_name: "/PCM/swm/details",
    https_method: "GET",
    action_type: actionType.GET_SW_MODULES,
    description: "",
  },
  get_main_sw_modules: {
    api_name: "/PCM/swm/details",
    https_method: "GET",
    action_type: actionType.GET_MAIN_SW_MODULES,
    description: "",
  },
  get_linked_sw_modules: {
    api_name: "/PCM/swm/details",
    https_method: "GET",
    action_type: actionType.GET_LINKED_SW_MODULES,
    description: "",
  },
  add_sw_module: {
    api_name: "/PCM/swm/add",
    https_method: "POST",
    action_type: actionType.Add_SW_MODULE,
    description: "",
  },
  update_sw_module: {
    api_name: "/PCM/swm/details",
    https_method: "PUT",
    action_type: actionType.Update_SW_MODULE,
    description: "",
  },
  replace_sw_module: {
    api_name: "/PCM/swm/replace",
    https_method: "POST",
    action_type: actionType.REPLACE_SW_MODULE,
  },
  link_sw_module: {
    api_name: "/PCM/swm/link",
    https_method: "PUT",
    action_type: actionType.LINK_SW_MODULE,
    description: "",
  },
  unlink_sw_module: {
    api_name: "/PCM/swm/un-link",
    https_method: "PUT",
    action_type: actionType.UNLINK_SW_MODULE,
    description: "",
  },
  get_ee_components_main: {
    api_name: "/PCM/ee_components/details",
    https_method: "GET",
    action_type: actionType.GET_EE_COMPONENTS_MAIN,
    description: "",
  },
  get_ee_components_linked: {
    api_name: "/PCM/ee_components/details",
    https_method: "GET",
    action_type: actionType.GET_EE_COMPONENTS_LINKED,
    description: "",
  },
  get_ee_components_main_linked: {
    api_name: "/PCM/ee_components/details",
    https_method: "GET",
    action_type: actionType.GET_EE_COMPONENTS_MAIN_LINKED,
    description: "",
  },
  // Change logs
  get_ee_components_change_logs: {
    api_name: "/PCM/ee_components/change-logs",
    https_method: "GET",
    action_type: actionType.GET_EE_COMPONENT_CHANGE_LOGS,
    description: "",
  },
  get_sw_modules_change_logs: {
    api_name: "/PCM/swm/change-logs",
    https_method: "GET",
    action_type: actionType.GET_SW_MODULE_CHANGE_LOGS,
    description: "",
  },
  // New
  get_ee_components_new: {
    api_name: "/PCM/ee_components/details",
    https_method: "GET",
    action_type: "",
    description: "",
  },
  get_sw_modules_new: {
    api_name: "/PCM/swm/details",
    https_method: "GET",
    action_type: "",
    description: "",
  },
  link_ee_component: {
    api_name: "/PCM/ee_components/link",
    https_method: "PUT",
    action_type: "",
    description: "",
  },
  unlink_ee_component: {
    api_name: "/PCM/ee_components/un-link",
    https_method: "PUT",
    action_type: "",
    description: "",
  },
  get_ee_intersections: {
    api_name: "/PCM/ee_components/intersections",
    https_method: "GET",
    action_type: "",
    description: "",
  },
  //Action Type
  update_action_type: {
    api_name: "/PCM/project/module/action_type",
    https_method: "PUT",
    action_type: actionType.update_action_type,
    description: "",
  },
  update_swm_action_type: {
    api_name: "/PCM/swm/action_type",
    https_method: "PUT",
    action_type: actionType.UPDATE_SWM_ACTION_TYPE,
    description: "",
  },
  // Lock/UnLock EE Component
  lock_unlock_ee_component: {
    api_name: "/PCM/ee_components/lock-unlock",
    https_method: "PUT",
    action_type: "",
    description: "",
  },
  // Lock/UnLock Software Module
  lock_unlock_software_module: {
    api_name: "/PCM/swm/lock-unlock",
    https_method: "PUT",
    action_type: "",
    description: "",
  },
};

export default labCar;
