/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 11-12-2024
 * @description : Component for simulation files
 * @param : none
 * @return : The rendered VLC page.
 */

import { Box, Paper, Typography } from "@mui/material";
import CustomFiltersN from "../../components/tables/CustomFiltersN";
import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomDataGridTableN,
  CustomPagination,
  CustomSorting,
  SelectTextFieldNC,
} from "../../components";

function SimulationFiles() {
  // Simulation files
  const [tableRowsData, setTableRowsData] = useState([
    {
      id: 171,
      ticket_id: "INC003451",
      status: "NEW",
      changed_by: {
        name: "Hari Prabhu",
        email: "hariprabhu@au79consulting.com",
        date_time: "10-12-2024 11:12:24 AM",
      },
      simulation_file: { file_id: "", file_name: "" },
      module_details: {
        id: 1,
      },
      legal_details: {
        standard: "UNECER987",
        chapter: "Safety & Security",
        revision: 3,
        req_number: "1.3.5",
      },
      vehicle_functions: { id: 1 },
      attachments: "",
    },
    {
      id: 172,
      ticket_id: "INC003452",
      status: "NEW",
      changed_by: {
        name: "Abdul",
        email: "abdul@au79consulting.com",
        date_time: "10-12-2024 11:12:24 AM",
      },
      simulation_file: { file_id: "", file_name: "" },
      module_details: {
        id: 2,
      },
      legal_details: {
        standard: "UNECER987",
        chapter: "Safety & Security",
        revision: 3,
        req_number: "1.3.5",
      },
      vehicle_functions: { id: 2 },
      attachments: "",
    },
    {
      id: 173,
      ticket_id: "INC003453",
      status: "NEW",
      changed_by: {
        name: "John Joe",
        email: "john@au79consulting.com",
        date_time: "10-12-2024 11:12:24 AM",
      },
      simulation_file: { file_id: "", file_name: "" },
      module_details: {
        id: 3,
      },
      legal_details: {
        standard: "UNECER987",
        chapter: "Safety & Security",
        revision: 3,
        req_number: "1.3.5",
      },
      vehicle_functions: { id: 3 },
      attachments: "",
    },
  ]);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Sorting
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("created_at");
  const [selectedSortOrder, setSelectedSortOrder] = useState("ASC");

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("role_name");
  const [searchFieldValue, setSearchFieldValue] = useState("");

  const moduleDetailsOptionsList = [
    {
      value: 1,
      label: "Speed Conversion System",
      label1: "NDS68956687",
    },
    {
      value: 2,
      label: "Engine Control System",
      label1: "NDS68956657",
    },
    {
      value: 3,
      label: "Rear Auto Breaking System",
      label1: "NDS689566986",
    },
  ];

  // RenderValue must be a function
  const renderValue = (selected) => {
    if (!selected) return "Select an option"; // Placeholder text
    const selectedOption = moduleDetailsOptionsList?.find(
      (option) => option.value === selected
    );
    if (!selectedOption) return "Invalid selection"; // Fallback for invalid selections
    return (
      <>
        {selectedOption.label}
        <br />
        {selectedOption.assembly_number}
      </>
    );
  };

  const COLUMNS = [
    {
      field: "id",
      headerName: "Intersection Id",
      width: 120,
    },
    {
      field: "ticket_id",
      headerName: "Ticket Id",
      width: 120,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "changed_by",
      headerName: "Changed By",
      width: 270,
      renderCell: (params) => (
        <Box>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Name: </span>
            {params?.row?.changed_by?.name || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Email: </span>
            {params?.row?.changed_by?.email || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Date & Time: </span>
            {params?.row?.changed_by?.date_time || "-"}
          </Typography>
        </Box>
      ),
    },
    {
      field: "simulation_file",
      headerName: "Simulation File",
      width: 170,
      renderCell: (params) => (
        <Box sx={{ mt: 1.4, ml: 2 }}>
          <CustomButton
            size="small"
            variant="contained"
            btnName="Generate Test"
            handleOnClick={() => {}}
            // disabled={}
          />
        </Box>
      ),
    },
    {
      field: "module_details",
      headerName: "Module Details",
      width: 270,
      renderCell: (params) => (
        <Box>
          <Box>
            <Typography variant="subtitle1">
              <span style={styles.txtBold}>Vehicle Components</span>
            </Typography>
          </Box>
          <Box>
            <SelectTextFieldNC
              size={"small"}
              label={"Module Details"}
              variant={"standard"}
              optionsList={moduleDetailsOptionsList}
              selectedValue={params?.row?.module_details?.id}
              handleOnChange={(e) => {}}
              sltFldStyle={styles.selectTxtStyle}
              renderValue={renderValue}
            />
          </Box>
        </Box>
      ),
    },
    {
      field: "legal_details",
      headerName: "Legal Details",
      width: 270,
      renderCell: (params) => (
        <Box>
          <Box>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Standard: </span>
              {params?.row?.legal_details?.standard || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Chapter: </span>
              {params?.row?.legal_details?.chapter || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Revision: </span>
              {params?.row?.legal_details?.revision || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Req. Number: </span>
              {params?.row?.legal_details?.req_number || "-"}
            </Typography>
          </Box>
          <Box sx={{ my: 1, ml: 2.5 }}>
            <CustomButton
              size="small"
              variant="contained"
              btnName="More"
              handleOnClick={() => {}}
              // disabled={}
            />
          </Box>
        </Box>
      ),
    },
    {
      field: "vehicle_functions",
      headerName: "Vehicle Functions",
      width: 220,
      renderCell: (params) => (
        <Box>
          <SelectTextFieldNC
            size={"small"}
            label={"Vehicle Components"}
            variant={"standard"}
            optionsList={[
              { value: 1, label: "AEB - Automatic emergency breaking" },
              { value: 2, label: "CSF - Corrective Steering Function" },
              ,
              { value: 3, label: "ELKS - Emergency Lane-keeping System" },
            ]}
            selectedValue={params?.row?.vehicle_functions?.id}
            handleOnChange={(e) => {}}
            sltFldStyle={styles.selectTxtStyle}
          />
        </Box>
      ),
    },
    {
      field: "attachments",
      headerName: "Attachments",
      width: 170,
      renderCell: (params) => (
        <Box sx={{ mt: 1.4, ml: 2 }}>
          <CustomButton
            size="small"
            variant="contained"
            btnName="View"
            handleOnClick={() => {}}
            // disabled={}
          />
        </Box>
      ),
    },
  ];

  // Open sort menu
  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  // Handle sort close
  const handleSortClose = () => {
    setAnchorElSort(null);
    setOpenSort(false);
  };

  // Handle sort change
  const handleSortChange = (column, order) => {
    setSelectedSortColumn(column);
    setSelectedSortOrder(order);

    setPage(1); // Reset to the first page when page size changes

    handleSortClose();
  };

  // Reset the sort values
  const handleSortResetAll = () => {
    handleSortChange("created_at", "ASC");
  };

  // Apply sorting
  const handleSortApplyNow = (selectedSortColumn, selectedSortOrder) => {
    handleSortChange(selectedSortColumn, selectedSortOrder);
  };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);

    setPage(1); // Reset to the first page when page size changes

    handleFilterClose();
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("role_name", "");
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  // Handles the click event for table rows for editing
  const handleTableRowClick = ({ params }) => {};

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  return (
    <Box sx={styles.mainContainer}>
      {/* Filter/Sorting buttons */}
      <Box sx={styles.btnsContainer}>
        <CustomFiltersN
          anchorEl={anchorElFilter}
          open={openFilter}
          handleClose={handleFilterClose}
          mainOptionsList={[]}
          selectedMainOption={selectedSearchField}
          selectedMainOptionValue={searchFieldValue}
          handleFilterClick={handleFilterClick}
          onReset={handleFilterResetAll}
          onApplyNow={handleFilterApplyNow}
        />

        <CustomSorting
          anchorEl={anchorElSort}
          open={openSort}
          handleClose={handleSortClose}
          sortOptionsList={[]}
          selectedSortColumn={selectedSortColumn}
          selectedSortOrder={selectedSortOrder}
          handleSortClick={handleSortClick}
          onReset={handleSortResetAll}
          onApplyNow={handleSortApplyNow}
        />
      </Box>
      {/* Simulation files table */}
      <Paper elevation={6} sx={styles.tableContainer}>
        <CustomDataGridTableN
          tableRowsData={tableRowsData}
          columns={COLUMNS}
          handleTableRowClick={handleTableRowClick}
          isMultiLine={true}
        />
        <Paper elevation={4} sx={styles.paginationPaper}>
          {/* Pagination with select page size */}
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            hasMore={hasMore}
          />
        </Paper>
      </Paper>
    </Box>
  );
}

export default SimulationFiles;

// Styles
const styles = {
  mainContainer: {
    mt: 1,
    mx: 2,
    pb: "70px",
    // border: "1px solid red",
  },
  btnsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 1,
  },
  tableContainer: {
    my: 2,
    height: 470,
    width: "100%",
    mb: 8,
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
  txtBold: {
    fontWeight: "bold",
  },
  selectTxtStyle: {
    width: "190px",
    mt: 1,
  },
};
