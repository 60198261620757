/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description : Component to show the mind map for zonal.
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
  MarkerType,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import * as d3 from "d3-hierarchy";
import { Box } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, labCar } from "../../redux/actions";
import customConsole from "../../config/customConsole";

// Constants for node dimensions and margins
const nodeWidth = 140;
const nodeHeight = 140;
const marginX = 50;
const marginY = 50;

// Utility function to get node color based on selection and linking
const getNodeColor = (nodeId, selectedNodeId, linkedSwmIds) => {
  customConsole("nodeId, selectedNodeId, linkedSwmIds");
  customConsole({ nodeId, selectedNodeId, linkedSwmIds });
  if (nodeId === selectedNodeId) return "#00FF1A";
  if (
    selectedNodeId &&
    linkedSwmIds?.includes(Number(selectedNodeId?.replace("swm-", "")))
  )
    return "gold";
  return "#2897DC";
};

// Create hierarchy data without zones
const createHierarchyData = (projectDetails) => ({
  id: `project-${projectDetails.project_id}`,
  name: projectDetails.name,
  children: projectDetails.ee_details.map((ee) => ({
    id: `ee-${ee.ee_id}`,
    name: ee.description,
    version: ee.version,
    children: projectDetails.swm_details
      .filter((swm) => swm.ee_id === ee.ee_id)
      .map((swm) => ({
        id: `swm-${swm.swm_id}`,
        name: swm.name,
        version: swm.version,
        linkedSwmIds: swm.linked_swm_ids,
      })),
  })),
});

// Generate nodes and edges using D3 tree layout
const createNodesAndEdges = (hierarchyData, selectedNodeId) => {
  const nodes = [];
  const edges = [];

  const root = d3.hierarchy(hierarchyData);
  const treeLayout = d3
    .tree()
    .nodeSize([nodeWidth + marginX, nodeHeight + marginY]);
  treeLayout(root);

  root.descendants().forEach((d) => {
    let nodeShape, nodeStyle;

    // Define shapes and styles based on node type
    if (d.data.id.startsWith("project-")) {
      // Project Node - Circle
      nodeShape = (
        <div>
          <h3>{d.data.name}</h3>
        </div>
      );
      nodeStyle = {
        background: "black",
        color: "white",
        width: nodeWidth,
        height: nodeHeight,
        borderRadius: "50%", // Circle shape
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    } else if (d.data.id.startsWith("ee-")) {
      // EE Component Node - Circle
      nodeShape = (
        <div>
          <h3>{d.data.name}</h3>
          <p>
            <strong>Version:</strong> {d.data.version}
          </p>
        </div>
      );
      nodeStyle = {
        background: "black",
        color: "white",
        width: nodeWidth,
        height: nodeHeight,
        borderRadius: "50%", // Circle shape
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    } else if (d.data.id.startsWith("swm-")) {
      // Software Module Node - Rectangle
      nodeShape = (
        <div>
          <h3>{d.data.name}</h3>
          <p>
            <strong>Version:</strong> {d.data.version}
          </p>
        </div>
      );
      nodeStyle = {
        background: getNodeColor(
          d.data.id,
          selectedNodeId,
          d.data.linkedSwmIds
        ),
        color: "white",
        width: nodeWidth,
        height: nodeHeight,
        borderRadius: "0%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    }

    // Add nodes
    nodes.push({
      id: d.data.id,
      data: { label: nodeShape },
      position: { x: d.x, y: d.y },
      style: nodeStyle,
    });

    // Create edges
    if (d.parent) {
      edges.push({
        id: `${d.parent.data.id}-${d.data.id}`,
        source: d.parent.data.id,
        target: d.data.id,
        type: "step",
        animated: false,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: "#000000",
        },
      });
    }
  });

  return { nodes, edges };
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description : Show the mind map.
 * @param : selectedProjectId
 * @return : The rendered mind map.
 */
function VlcZonalMindMap({ selectedProjectId, selectedVariantId }) {
  const dispatch = useDispatch();

  /* useSelectors */
  const { projectDetailsMindMap } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Project */
  const [mindMapProjectDetails, setMindMapProjectDetails] = useState({});

  /* Nodes */
  const [flowNodes, setFlowNodes] = useState([]);
  const [flowEdges, setFlowEdges] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  /* useEffects */
  // Fetch project details when project ID changes
  useEffect(() => {
    if (selectedProjectId && selectedVariantId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_project_details_mind_map,
          params: {
            project_id: selectedProjectId,
            variant_id: selectedVariantId,
          },
          show_toast: false,
        })
      );
    }
  }, [selectedProjectId, selectedVariantId]);

  // Update mind map details
  useEffect(() => {
    if (projectDetailsMindMap && projectDetailsMindMap.ee_details) {
      setMindMapProjectDetails(projectDetailsMindMap);
    }
  }, [projectDetailsMindMap]);

  // Generate nodes and edges when details or selection change
  useEffect(() => {
    if (mindMapProjectDetails.ee_details) {
      const hierarchyData = createHierarchyData(mindMapProjectDetails);
      const { nodes, edges } = createNodesAndEdges(hierarchyData, selectedNode);
      setFlowNodes(nodes);
      setFlowEdges(edges);
    }
  }, [mindMapProjectDetails, selectedNode]);

  const onNodesChange = useCallback(
    (changes) => setFlowNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setFlowEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setFlowEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleNodeClick = useCallback((event, node) => {
    setSelectedNode(node.id);
  }, []);

  const animatedEdges = useMemo(() => {
    return flowEdges.map((edge) => {
      const isSelected =
        edge.source === selectedNode || edge.target === selectedNode;
      return {
        ...edge,
        // animated: isSelected,
        style: {
          strokeWidth: 2, // Increase this value to make the line thicker
          stroke: isSelected ? "#000000" : "#000000", // Optionally change color when selected
        },
      };
    });
  }, [flowEdges, selectedNode]);

  return (
    <Box sx={styles.mainContainer}>
      <ReactFlow
        nodes={flowNodes}
        edges={animatedEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={handleNodeClick}
        fitView
      >
        <Background />
        <Controls />
      </ReactFlow>
    </Box>
  );
}

export default VlcZonalMindMap;

// Styles
const styles = {
  mainContainer: {
    // height: "100vh",
    height: "520px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    border: "1px solid grey",
    borderRadius: 1,
    padding: "20px",
    mt: 1,
    boxShadow: 4,
  },
};
