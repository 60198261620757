/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This file is define different action types to refer/denote what type of action user is trying perform
 */
const actionType = {
  SPINNER_LOADING: "Action for the Spinner Loading",
  SPINNER_LOADED: "Action for the Spinner Loading completed",

  // Authentication Actions
  LOGIN: "POST: logIn",
  FORGOT_PASSWORD: "POST: forgot password",
  RESET_PASSWORD: "POST: reset password",
  SIGN_OUT: "logoutOut",

  // User Management
  GET_COUNTRY_CODES: "Get: get country codes",

  ADD_USER: "Post: add user",
  GET_USERS_LIST: "Get: get users list",
  UPDATE_USER: "Get: update user",
  CHANGE_USER_STATUS: "Put: change user status",
  DELETE_USER: "Put: delete user",
  CHANGE_USER_ROLE: "Put: change user role",
  ADD_ROLE: "Post: add role",
  GET_ROLES_LIST: "Get: get roles list",
  GET_ROLES_LIST_MAIN: "Get: get roles list main",
  UPDATE_ROLE: "Put: update role",
  REMOVE_CHAPTERS: "Put: remove chapters",
  CHANGE_ROLE_STATUS: "Put: change role status",
  DELETE_ROLE: "Put: delete role",
  ADD_PAGE_ACCESS: "Put: add page access",
  REMOVE_PAGE_ACCESS: "Put: remove page access",
  ADD_FEATURE_ACCESS: "Put: add feature access",
  REMOVE_FEATURE_ACCESS: "Put: remove feature access",

  GET_APP_META_DATA: "Get: get app meta data",

  GET_FEATURES_LIST: "Get: get features list",
  // Swagger Response
  GET_SWAGGER_DATA: "Get: Swapper API documentation!",
  //Profile
  GET_PROFILE: "Action for Profile Details",
  UPDATE_PROFILE: "Action for Update Details",
  UPLOAD_PROFILE_PIC: "Action to upload the profile picture",
  GET_PROFILE_PIC: "Action to get the profile picture",
  RESET_PROFILE: "Action for Reset Profile",
  //Countries and PhoneCode
  GET_COUNTRIES_PHONECODE: "Action for Get Countries and Phone Codes",

  //Zone Details
  ADD_ZONES: "Add Zones",
  DELETE_ZONES: "Delete Zones",

  //Architecture Types
  ADD_ARCHITECTURE_TYPES: "Add Architecture Types",
  DELETE_ARCHITECTURE_TYPES: "Delete Architecture Types",

  //Vehicle Class
  ADD_VEHICLE_CLASS: "Action for Add Vehicle Class",
  UPDATE_VEHICLE_CLASS: "Action for Update Vehicle Class",
  DELETE_VEHICLE_CLASS: "Action for Delete Vehicle Class",
  GET_VEHICLE_CLASS: "Action for Get Vehicle Class",

  //Vehicle Function
  ADD_VEHICLE_FUNCTION: "Action for Add Vehicle Function",
  UPDATE_VEHICLE_FUNCTION: "Action for Update Vehicle Function",
  DELETE_VEHICLE_FUNCTION: "Action for Delete Vehicle Function",
  GET_VEHICLE_FUNCTION: "Action for Get Vehicle Function",

  //Vehicle Status
  ADD_VEHICLE_STATUS: "Action for Add Vehicle Status",
  UPDATE_VEHICLE_STATUS: "Action for Update Vehicle Status",
  GET_VEHICLE_STATUS: "Action for Get Vehicle Status",
  DELETE_VEHICLE_STATUS: "Action for Delete Vehicle Status",

  //Vehicle Systems
  ADD_VEHICLE_SYSTEMS: "Action for Add Vehicle Systems",
  UPDATE_VEHICLE_SYSTEMS: "Action for Update Vehicle Systems",
  GET_VEHICLE_SYSTEMS: "Action for Get Vehicle Systems",
  DELETE_VEHICLE_SYSTEMS: "Action for Delete Vehicle Systems",

  //Powertrain Class
  ADD_POWETRAIN_CLASS: "Action for Add Powertrain Class",
  UPDATE_POWETRAIN_CLASS: "Action for Update Powertrain Class",
  DELETE_POWETRAIN_CLASS: "Action for Delete Powertrain Class",
  GET_POWETRAIN_CLASS: "Action for Get Powertrain Class",

  //Admin Settings
  GET_ADMIN_SETTINGS: "Action for Get Admin Settings",

  //File Handling
  GET_FILE: "Action for Getting Image and File",
  ADD_FILE: "Action for upload file and Image",
  SET_FILE: "Action for Set Image",

  //Project Config
  ADD_PROJECT: "Action for Add Project",
  GET_PROJECT: "Action for Get Project",
  UPDATE_PROJECT: "Action for Update Project",
  UPDATE_PROJECT_STATUS: "Action for Update Project Status",
  RESET_PROJECT_CONFIG: "Action for reset",

  //Variant
  ADD_VARIANTS: "Action for Add Variant",
  GET_VARIANTS: "Action for Get Variant",
  UPDATE_VS_VARIANT: "Action for Update Vehicle Status for Variant",
  UPDATE_VS_VEHICLE_FUNCTION: "Action for Update Vehicle Function for Variant",
  UPDATE_VS_VEHICLE_COMPONENTS:
    "Action for Update Vehicle Components for Variant",

  // Change logs
  GET_PROJECT_CHANGE_LOGS: "Action for Get Project Change Logs",
  GET_VARIANT_CHANGE_LOGS: "Action for Get Variant Change Logs",

  // Project Access
  GET_PROJECT_DETAILS_PA: "Action to Get Project Details for Project Access",
  GET_PROJECT_DETAILS_MIND_MAP_PA:
    "Action to Get Project Details Mind Map for Project Access",
  GET_EE_COMPONENTS_PA: "Action to Get EE Components for Project Access",
  GET_SW_MODULES_PA: "Action to Get SW Modules for Project Access",
  // LabCar
  GET_PROJECT_DETAILS: "Action to Get Project Details",
  GET_PROJECT_DETAILS_MIND_MAP: "Action to Get Project Details Mind Map",
  GET_EE_COMPONENTS: "Action to Get EE Components",
  Add_EE_COMPONENT: "Action to Add EE Component",
  UPDATE_EE_COMPONENT: "Action to Update EE Component",
  REPLACE_EE_COMPONENT: "Action to Replace EE Component",
  GET_SW_MODULES: "Action to Get SW Modules",
  GET_MAIN_SW_MODULES: "Action to Get Main SW Modules",
  GET_LINKED_SW_MODULES: "Action to Get Linked SW Modules",
  Add_SW_MODULE: "Action to Add SW Module",
  Update_SW_MODULE: "Action to Update SW Module",
  REPLACE_SW_MODULE: "Action to Replace SW Module",
  LINK_SW_MODULE: "Action to Link SW Module",
  UNLINK_SW_MODULE: "Action to UnLink SW Module",
  GET_EE_COMPONENTS_MAIN: "Action to Get EE Components Main",
  GET_EE_COMPONENTS_LINKED: "Action to Get EE Components Linked",
  GET_EE_COMPONENTS_MAIN_LINKED: "Action to Get EE Components Main Linked",
  RESET_LAB_CAR_DATA: "Action to Reset Lab Car Data",
  SET_SELECTED_PROJECT: "Select a project for entire app",
  SET_SELECTED_VARIANT: "Select a variant for entire app",
  //Type Approval
  GET_CREATE_INTERSECTIONS: "Action to View the intersection details",
  CREATE_INTERSECTIONS:
    "Action to Link the software modules with the legal requirements",
  GET_TICKETS: "Action to Get Tickets",
  UPDATE_PROJECT_STATUS_TICKETS: "Action to Update Project Status",
  RE_CREATE_TICKETS: "RE_CREATE_TICKETS",
  DELETE_DRAFT_TICKETS: "Action to delete draft tickets",

  // Legal Standard
  GET_LEGAL_STANDARD_DETAILS: "Action to Get Legal Standard Details",
  GET_LEGAL_STANDARD_MAIN_DETAILS: "Action to Get Legal Standard Main Details",
  ADD_LEGAL_STANDARD: "Action to Add Legal Standard",
  UPDATE_LEGAL_STANDARD: "Action to Update Legal Standard",
  // Legal Requirements
  GET_LEGAL_REQUIREMENTS_DETAILS: "Action to Get Legal Requirement Details",
  GET_LEGAL_REQUIREMENTS_MAIN_DETAILS:
    "Action to Get Legal Requirement Main Details",
  ADD_LEGAL_REQUIREMENTS: "Action to Add Legal Requirements",
  UPDATE_LEGAL_REQUIREMENTS: "Action to Update Legal Requirements",

  //S/W to S/W Intersections
  GET_SOFTWARE_INTERSECTIONS:
    "Action to Get Software To Software Intersections",
  GET_SOFTWARE_LR: "Action to Get Software LR",

  //MFT_DETAILS
  ADD_MFT_DETAILS: "Action to Add Mft Details",
  DELETE_MFT_DETAILS: "Action to Delete Mft Details",

  //Delivery Market Details
  ADD_DELIVERY_MARKET_DETAILS: "Action to Add Delivery Market Details",
  DELETE_DELIVERY_MARKET_DETAILS: "Action to Delete Deleiver Market",

  GET_USERS_DETAILS_LIST: "Action to Get Users Details",

  //Notifications details
  GET_RECENT_NOTIFICATIONS: "Action to get recent notifications",
  GET_ALL_NOTIFICATIFICATIONS: "Action to get all notifications",
  NOTIFICATIONS_MARK_READ: "Action to mark read notifications",

  //Action Type
  update_action_type: "Action to update action type",
  UPDATE_SWM_ACTION_TYPE: "Action to update swm action type",

  // Edit Requests
  GET_EDIT_REQUESTS: "Action to get edit requests",
  CREATE_EDIT_REQUESTS: "Action to create edit requests",
  APPROVE_EDIT_REQUESTS: "Action to approve edit request",
  REJECT_EDIT_REQUESTS: "Action to reject edit request",
};
export default actionType;
